import { createSlice } from "@reduxjs/toolkit";
export var INITIAL_STATE_PERSONAL_DETAILS = {
  firstName: "",
  lastName: "",
  dob: "",
  phone_number: "",
  email: "",
  line1: "",
  line2: "",
  city: "",
  postcode: "",
  state: "",
  country: ""
};
var initialState = {
  personalData: INITIAL_STATE_PERSONAL_DETAILS,
  dids: []
};
var PersonalDetailsReducer = createSlice({
  name: "PERSONAL_DETAILS",
  initialState,
  reducers: {
    setUserDetails: function setUserDetails(state, _ref) {
      var payload = _ref.payload;
      state.personalData = payload.personalData;
      state.dids = payload.dids;
    },
    resetUserDetails: function resetUserDetails(state) {
      state.personalData = INITIAL_STATE_PERSONAL_DETAILS;
    }
  }
});
var _PersonalDetailsReduc = PersonalDetailsReducer.actions, setUserDetails2 = _PersonalDetailsReduc.setUserDetails, resetUserDetails2 = _PersonalDetailsReduc.resetUserDetails;
export { setUserDetails2 as setUserDetails, resetUserDetails2 as resetUserDetails };
export default PersonalDetailsReducer.reducer;
