import { jsx as __cssprop } from "@emotion/react";
var WalletIconV2 = function WalletIconV22() {
  return __cssprop("svg", {
    width: "44",
    height: "43",
    viewBox: "0 0 44 43",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_5410_39058)"
  }, __cssprop("path", {
    d: "M30.6868 29.5478C28.3307 29.5478 26.4188 27.5966 26.4188 25.1919C26.4188 22.7873 28.3307 20.8361 30.6868 20.8361H41V14.5047C41 12.1163 39.104 10.1894 36.7716 10.1894H7.22839C4.8881 10.1894 3 12.1244 3 14.5047V37.6927C3 40.0812 4.89603 42.0081 7.22839 42.0081H36.7716C39.1119 42.0081 41 40.0731 41 37.6927V29.5559H30.6868V29.5478Z",
    fill: "#64A6E7"
  }), __cssprop("path", {
    d: "M14.7173 8.44056C14.7173 8.44056 24.2213 2.72452 25.4033 2.06062C26.5853 1.39672 30.0204 -0.416867 32.0354 3.08076C34.0505 6.57839 34.7248 8.43246 34.7248 8.43246H14.7173V8.44056Z",
    fill: "#10B981"
  }), __cssprop("path", {
    d: "M31.0993 27.6856C32.3524 27.6856 33.3682 26.6489 33.3682 25.3701C33.3682 24.0912 32.3524 23.0545 31.0993 23.0545C29.8463 23.0545 28.8304 24.0912 28.8304 25.3701C28.8304 26.6489 29.8463 27.6856 31.0993 27.6856Z",
    fill: "#10B981"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_5410_39058"
  }, __cssprop("rect", {
    width: "38",
    height: "41",
    fill: "white",
    transform: "translate(3 1)"
  }))));
};
export default WalletIconV2;
