import { jsx as __cssprop } from "@emotion/react";
var WalletBlueIcon = function WalletBlueIcon2() {
  return __cssprop("svg", {
    width: "51",
    height: "43",
    viewBox: "0 0 51 43",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_4947_22871)"
  }, __cssprop("g", {
    clipPath: "url(#clip1_4947_22871)"
  }, __cssprop("path", {
    d: "M34.6868 29.8522C32.3307 29.8522 30.4188 27.901 30.4188 25.4964C30.4188 23.0918 32.3307 21.1405 34.6868 21.1405H45V14.8092C45 12.4208 43.104 10.4938 40.7716 10.4938H11.2284C8.8881 10.4938 7 12.4289 7 14.8092V37.9972C7 40.3856 8.89603 42.3125 11.2284 42.3125H40.7716C43.1119 42.3125 45 40.3775 45 37.9972V29.8603H34.6868V29.8522Z",
    fill: "#64A6E7"
  }), __cssprop("path", {
    d: "M18.7168 8.745C18.7168 8.745 28.2208 3.02897 29.4028 2.36506C30.5849 1.70116 34.0199 -0.112424 36.035 3.38521C38.05 6.88284 38.7243 8.73691 38.7243 8.73691H18.7168V8.745Z",
    fill: "#10B981"
  }), __cssprop("path", {
    d: "M35.099 27.9901C36.352 27.9901 37.3679 26.9534 37.3679 25.6745C37.3679 24.3957 36.352 23.3589 35.099 23.3589C33.8459 23.3589 32.8301 24.3957 32.8301 25.6745C32.8301 26.9534 33.8459 27.9901 35.099 27.9901Z",
    fill: "#10B981"
  }))), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_4947_22871"
  }, __cssprop("rect", {
    width: "50.0285",
    height: "42.7859",
    fill: "white",
    transform: "translate(0 0.107025)"
  })), __cssprop("clipPath", {
    id: "clip1_4947_22871"
  }, __cssprop("rect", {
    width: "38",
    height: "41",
    fill: "white",
    transform: "translate(7 1.30444)"
  }))));
};
export default WalletBlueIcon;
