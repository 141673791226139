import { jsx as __cssprop } from "@emotion/react";
var WarningIcon = function WarningIcon2() {
  return __cssprop("svg", {
    width: "22",
    height: "24",
    viewBox: "0 0 22 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("path", {
    d: "M9.7749 14.7729H11.7749V16.7729H9.7749V14.7729ZM9.7749 6.77295H11.7749V12.7729H9.7749V6.77295ZM10.7749 1.77295C5.2449 1.77295 0.774902 6.27295 0.774902 11.7729C0.774902 14.4251 1.82847 16.9687 3.70383 18.844C4.63242 19.7726 5.73481 20.5092 6.94807 21.0117C8.16132 21.5143 9.46168 21.7729 10.7749 21.7729C13.4271 21.7729 15.9706 20.7194 17.846 18.844C19.7213 16.9687 20.7749 14.4251 20.7749 11.7729C20.7749 10.4597 20.5162 9.15937 20.0137 7.94611C19.5112 6.73286 18.7746 5.63047 17.846 4.70188C16.9174 3.7733 15.815 3.0367 14.6017 2.53415C13.3885 2.03161 12.0881 1.77295 10.7749 1.77295ZM10.7749 19.7729C8.65317 19.7729 6.61834 18.9301 5.11805 17.4298C3.61776 15.9295 2.7749 13.8947 2.7749 11.7729C2.7749 9.65122 3.61776 7.61639 5.11805 6.1161C6.61834 4.6158 8.65317 3.77295 10.7749 3.77295C12.8966 3.77295 14.9315 4.6158 16.4318 6.1161C17.932 7.61639 18.7749 9.65122 18.7749 11.7729C18.7749 13.8947 17.932 15.9295 16.4318 17.4298C14.9315 18.9301 12.8966 19.7729 10.7749 19.7729Z",
    fill: "#F59E0B"
  }));
};
export default WarningIcon;
