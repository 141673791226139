import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  vcs: []
};
var vcsReducer = createSlice({
  name: "VCS",
  initialState,
  reducers: {
    setVcs: function setVcs(state, _ref) {
      var payload = _ref.payload;
      state.vcs[payload.index] = payload.vcs;
    }
  }
});
var setVcs2 = vcsReducer.actions.setVcs;
export { setVcs2 as setVcs };
export default vcsReducer.reducer;
