import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { baseTheme } from "./base";
import { jsx as __cssprop } from "@emotion/react";
var StyledThemeProviderProxy = StyledThemeProvider;
var ThemeProvider = function ThemeProvider2(_ref) {
  var children = _ref.children;
  return __cssprop(StyledThemeProviderProxy, {
    theme: baseTheme
  }, children);
};
export default ThemeProvider;
