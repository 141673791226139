function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o)
    return;
  if (typeof o === "string")
    return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor)
    n = o.constructor.name;
  if (n === "Map" || n === "Set")
    return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length)
    len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i];
  return arr2;
}
function _iterableToArrayLimit(arr, i) {
  var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"];
  if (null != _i) {
    var _s, _e, _x, _r, _arr = [], _n = true, _d = false;
    try {
      if (_x = (_i = _i.call(arr)).next, 0 === i) {
        if (Object(_i) !== _i)
          return;
        _n = false;
      } else
        for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = true)
          ;
    } catch (err) {
      _d = true, _e = err;
    } finally {
      try {
        if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r))
          return;
      } finally {
        if (_d)
          throw _e;
      }
    }
    return _arr;
  }
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr))
    return arr;
}
import { IconButton, Modal, Typography } from "@mui/material";
import { CloseModalIcon, WalletIconV2 } from "assets/icons";
import WalletsListModal from "components/WalletListModal";
import { IFrameContext } from "context/IframeContext";
import { ConnectButton } from "flowbite-components/buttons";
import { useIsMobile, useResolution } from "hooks";
import React, { useContext, useState } from "react";
import { baseTheme } from "theme/base";
import { RESOLUTIONS } from "../../utils/constants";
import WalletButtonConnect from "../WalletButtonConnect";
import { ErrorMessage, ModalBodyContent, ModalBodyWrapper, ModalConnect, ModalHeaderLeft, ModalHeaderWrapper } from "./styles";
import { jsx as __cssprop } from "@emotion/react";
var colors = baseTheme.colors, fontSize = baseTheme.fontSize;
var ConnectButtonModal = function ConnectButtonModal2(_ref) {
  var label = _ref.label, _ref$isDisabled = _ref.isDisabled, isDisabled = _ref$isDisabled === void 0 ? false : _ref$isDisabled, testInstance = _ref.testInstance, fullWidth = _ref.fullWidth, width = _ref.width, background = _ref.background, endIcon = _ref.endIcon, sizeButton = _ref.sizeButton;
  var _useIsMobile = useIsMobile(), isMobile = _useIsMobile.isMobile;
  var _useResolution = useResolution(), maxWidth = _useResolution.maxWidth;
  var _useState = useState(false), _useState2 = _slicedToArray(_useState, 2), openModal = _useState2[0], setOpenModal = _useState2[1];
  var _useState3 = useState(""), _useState4 = _slicedToArray(_useState3, 2), errorMessage = _useState4[0], setErrorMessage = _useState4[1];
  var _useContext = useContext(IFrameContext), primaryColor = _useContext.primaryColor, buttonTextColor = _useContext.buttonTextColor;
  var _useState5 = useState(""), _useState6 = _slicedToArray(_useState5, 2), walletID = _useState6[0], setWalletID = _useState6[1];
  var _useContext2 = useContext(IFrameContext), isIframe = _useContext2.isIframe;
  var handleOpen = function handleOpen2() {
    return isDisabled ? null : setOpenModal(true);
  };
  var handleClose = function handleClose2() {
    setOpenModal(false);
    setErrorMessage("");
  };
  var getDesignNav = function getDesignNav2() {
    if (maxWidth === RESOLUTIONS.full)
      return {
        font: fontSize.p,
        line: "22px",
        padding: "10px 16px "
      };
    if (maxWidth === RESOLUTIONS.notebook)
      return {
        font: fontSize.label,
        line: "18px",
        padding: "10px 24px"
      };
    return {
      font: fontSize.h2,
      line: "18px",
      padding: "10px 24px"
    };
  };
  return __cssprop(React.Fragment, null, __cssprop(ConnectButton, {
    bgcolor: background ? background : testInstance || isIframe ? primaryColor : colors.success600,
    fontSize: sizeButton,
    isMobile,
    fullwidth: fullWidth,
    onClick: handleOpen,
    width,
    padding: getDesignNav().padding,
    textColor: isIframe ? buttonTextColor : colors.white,
    bgHover: colors.success500
  }, label, endIcon), __cssprop(Modal, {
    "aria-labelledby": "transition-modal-title",
    "aria-describedby": "transition-modal-description",
    open: openModal,
    sx: {
      zIndex: 99999
    },
    onClose: handleClose
  }, __cssprop(ModalConnect, null, __cssprop(ModalHeaderWrapper, null, __cssprop(ModalHeaderLeft, null, __cssprop(WalletIconV2, null), __cssprop(Typography, {
    fontSize: fontSize.h2,
    fontWeight: 600
  }, "Connect your wallet")), __cssprop(IconButton, {
    sx: {
      cursor: "pointer"
    },
    onClick: handleClose,
    children: __cssprop(CloseModalIcon, null)
  })), __cssprop(ModalBodyWrapper, null, __cssprop(ModalBodyContent, null, __cssprop(WalletsListModal, {
    setErrorMessage,
    errorMessage,
    setWalletID,
    walletID
  }))), errorMessage ? __cssprop(ErrorMessage, null, errorMessage) : null, __cssprop(WalletButtonConnect, {
    walletID,
    closeModal: handleClose,
    setErrorMessage
  }))));
};
export default ConnectButtonModal;
