import { jsx as __cssprop } from "@emotion/react";
var KycIcon = function KycIcon2(_ref) {
  var _ref$w = _ref.w, w = _ref$w === void 0 ? "41px" : _ref$w, _ref$h = _ref.h, h = _ref$h === void 0 ? "42px" : _ref$h, _ref$opacity = _ref.opacity, opacity = _ref$opacity === void 0 ? "1.0" : _ref$opacity;
  return __cssprop("svg", {
    width: w,
    height: h,
    opacity,
    viewBox: "0 0 41 42",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("ellipse", {
    cx: "20.0922",
    cy: "20.8526",
    rx: "20.0922",
    ry: "20.6687",
    fill: "#D9D9D9"
  }), __cssprop("g", {
    clipPath: "url(#clip0_2835_539)"
  }, __cssprop("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M20.0535 8.1099C24.2971 10.8 28.1318 12.0731 31.422 11.7717C31.681 17.0155 30.9494 21.2904 29.3705 24.6669C28.9653 24.3349 28.5163 24.0603 28.0361 23.851C29.3117 21.0235 29.8984 17.4682 29.6838 13.129C26.898 13.3835 23.6521 12.3062 20.059 10.0285C16.9505 12.4436 13.7419 13.1509 10.4716 12.9796C10.3266 18.5032 11.2974 22.6616 13.1721 25.6869C14.5583 24.5279 16.9191 24.7032 18.0368 23.1662C18.117 23.0471 18.1543 22.9834 18.1538 22.9306C18.1529 22.9037 16.9405 21.4169 16.832 21.2436C16.5461 20.7894 16.0111 20.1727 16.0111 19.6403C16.0111 19.34 16.2482 18.948 16.5874 18.8608C16.561 18.4106 16.5431 17.9538 16.5431 17.5016C16.5431 17.2341 16.5486 16.9637 16.558 16.6987C16.573 16.5308 16.6038 16.4566 16.6482 16.2947C16.8573 15.6147 17.3122 15.0368 17.9242 14.674C18.1409 14.537 18.3765 14.431 18.6176 14.3438C19.0554 14.1839 18.8432 13.4921 19.3238 13.4816C20.4465 13.4527 22.2938 14.4344 23.0136 15.2139C23.4723 15.7215 23.7338 16.2853 23.7497 16.9686L23.7039 18.9355C23.9031 18.9843 24.1267 19.1407 24.1756 19.34C24.3295 19.9611 23.685 20.7341 23.3856 21.2272C23.1097 21.6829 22.0543 22.9266 22.0533 22.9366C22.0483 22.9903 22.0757 23.0571 22.1479 23.1662C22.4198 23.5392 22.7645 23.8112 23.1505 24.0263C22.6393 24.2942 22.1717 24.638 21.7634 25.0459C20.7364 26.0729 20.1008 27.4929 20.1008 29.0609C20.1008 30.4002 20.567 31.6324 21.3455 32.6042C20.9401 32.7904 20.5242 32.9648 20.0973 33.1251C12.7512 30.4435 8.40605 23.8744 8.7283 11.5954C12.5909 11.7981 16.3812 10.9629 20.0535 8.1099Z",
    fill: "#059669"
  }), __cssprop("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M25.7787 24.5264C28.2825 24.5264 30.3127 26.5565 30.3127 29.0608C30.3127 31.5651 28.2825 33.5953 25.7787 33.5953C23.2744 33.5953 21.2443 31.5651 21.2443 29.0608C21.2443 26.5565 23.2744 24.5264 25.7787 24.5264V24.5264ZM24.668 29.2237C24.8059 29.3407 24.9361 29.4667 25.0575 29.6007C25.4385 28.9876 25.845 28.4243 26.2743 27.9063C27.4876 26.441 26.9377 26.7234 28.6207 26.7234L28.3866 26.9834C27.6684 27.7818 27.4259 28.1972 26.8366 29.0484C26.2532 29.8925 25.724 30.7729 25.2523 31.6842L25.1068 31.9656L24.9723 31.6787C24.7258 31.1483 24.4294 30.6616 24.0763 30.2263C23.7237 29.7915 23.4149 29.5076 22.9362 29.1789C23.1559 28.4587 24.1998 28.8272 24.668 29.2237V29.2237Z",
    fill: "#10A64A"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_2835_539"
  }, __cssprop("rect", {
    width: "22.762",
    height: "25.4854",
    fill: "white",
    transform: "translate(8.71118 8.10987)"
  }))));
};
export default KycIcon;
