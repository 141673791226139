import { BACKEND_URL } from "config";
import { io } from "socket.io-client";
var socketInstance = function socketInstance2(path, query) {
  var socket = io("".concat(BACKEND_URL, "/").concat(path), {
    transports: ["websocket"],
    path: "/socket",
    forceNew: true,
    query
  });
  return socket;
};
export var openOnFidoSocket = function openOnFidoSocket2(authorization, callback) {
  var socket = socketInstance("auth", {
    authorization
  });
  socket.on("verified", function(message) {
    callback(message, socket);
  });
  return socket;
};
export var openPolygonSocket = function openPolygonSocket2(userDid, callback) {
  var socket = socketInstance("polygon", {
    id: userDid
  });
  socket.on("callback", function(message) {
    callback(message);
  });
  return socket;
};
export var openVcClaimSocket = function openVcClaimSocket2(id, callback) {
  var socket = socketInstance("agent", {
    id
  });
  socket.on("callback", function(message) {
    callback(message);
  });
  return socket;
};
