import { jsx as __cssprop } from "@emotion/react";
var FacebookSquare = function FacebookSquare2() {
  return __cssprop("svg", {
    width: "61",
    height: "61",
    viewBox: "0 0 61 61",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("rect", {
    width: "61",
    height: "61",
    rx: "6",
    fill: "#4676ED"
  }), __cssprop("path", {
    d: "M38.3112 32.8201L39.2447 26.7436H33.4142V22.8075C33.4142 21.1411 34.2327 19.5322 36.832 19.5322H39.4744V14.3607C39.4744 14.3607 37.0762 13.9441 34.7785 13.9441C29.9819 13.9441 26.8513 16.8459 26.8513 22.118V26.7436H21.5234V32.8201H26.8513V47.0561H33.4142V32.8201H38.3112Z",
    fill: "white"
  }));
};
export default FacebookSquare;
