import { jsx as __cssprop } from "@emotion/react";
var TwitterSquare = function TwitterSquare2() {
  return __cssprop("svg", {
    width: "61",
    height: "61",
    viewBox: "0 0 61 61",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("rect", {
    width: "61",
    height: "61",
    rx: "6",
    fill: "#33CCFF"
  }), __cssprop("path", {
    d: "M47.3588 19.8564C46.2722 20.3466 45.0791 20.645 43.9286 20.8368C44.4826 20.7515 45.2922 19.7499 45.6118 19.3449C46.1018 18.7269 46.5279 18.0236 46.7622 17.2564C46.7836 17.1924 46.8049 17.1285 46.7622 17.0859C46.677 17.0646 46.6344 17.0859 46.5705 17.0859C45.2709 17.7892 43.9286 18.2793 42.5012 18.6417C42.3733 18.663 42.2881 18.6417 42.2242 18.5777C42.0964 18.4498 41.9898 18.322 41.862 18.2154C41.2655 17.7039 40.6263 17.299 39.8806 17.0006C38.9219 16.617 37.8779 16.4465 36.8553 16.5104C35.8539 16.5744 34.8739 16.8514 33.979 17.3203C33.0842 17.7892 32.2959 18.4285 31.6567 19.2171C30.9963 20.0269 30.5276 20.986 30.2719 21.9876C30.0375 22.968 30.0588 23.927 30.208 24.9074C30.2293 25.0779 30.208 25.0992 30.0588 25.0779C24.4555 24.2467 19.8323 22.2434 16.0612 17.9597C15.8908 17.7679 15.8056 17.7679 15.6777 17.9597C14.0372 20.4319 14.8255 24.3959 16.8921 26.3566C17.1691 26.6124 17.4461 26.8681 17.7443 27.1238C17.6378 27.1452 16.2743 26.996 15.0386 26.3566C14.8681 26.25 14.7829 26.314 14.7829 26.5058C14.7829 26.7828 14.7829 27.0173 14.8255 27.3156C15.1451 29.8092 16.8708 32.1322 19.257 33.0486C19.534 33.1765 19.8536 33.2617 20.1518 33.3256C19.6192 33.4535 19.044 33.5388 17.51 33.4109C17.3182 33.3683 17.233 33.4748 17.3182 33.6453C18.49 36.8208 21.0041 37.7586 22.8789 38.3127C23.1346 38.3553 23.3903 38.3553 23.6459 38.4192C23.6459 38.4405 23.6246 38.4405 23.6033 38.4618C22.9855 39.4209 20.8123 40.1242 19.811 40.4865C17.9787 41.1259 15.976 41.4242 14.0372 41.2324C13.7176 41.1898 13.6537 41.1898 13.5685 41.2324C13.4833 41.275 13.5685 41.3603 13.6537 41.4455C14.0372 41.7013 14.442 41.9357 14.8468 42.1488C16.0825 42.8095 17.3608 43.321 18.7031 43.6833C25.6486 45.6014 33.4464 44.1948 38.6662 39.016C42.7568 34.9454 44.1843 29.3403 44.1843 23.7352C44.1843 23.5221 44.44 23.3942 44.5891 23.2877C45.6544 22.4778 46.5066 21.5401 47.2949 20.4532C47.4653 20.2187 47.4653 20.0056 47.4653 19.9204C47.4653 19.7925 47.4653 19.8351 47.3162 19.8991L47.3588 19.8564Z",
    fill: "white"
  }));
};
export default TwitterSquare;
