import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  index: 0,
  testIndex: 0
};
var WorkflowScreenIndex = createSlice({
  name: "WORKFLOW_SCREEN_INDEX",
  initialState,
  reducers: {
    nextScreen: function nextScreen(state) {
      state.index = state.index + 1;
    },
    prevScreen: function prevScreen(state) {
      var index = state.index;
      state.index = index - 1 < 0 ? index : index - 1;
    },
    goToScreen: function goToScreen(state, _ref) {
      var payload = _ref.payload;
      state.index = payload;
    },
    setIndex: function setIndex(state, _ref2) {
      var payload = _ref2.payload;
      state.index = payload;
    },
    nextScreenTest: function nextScreenTest(state) {
      state.testIndex = state.testIndex >= 3 ? 0 : state.testIndex + 1;
    },
    prevScreenTest: function prevScreenTest(state) {
      var testIndex = state.testIndex;
      state.testIndex = testIndex - 1 < 0 ? testIndex : testIndex - 1;
    },
    goToScreenTest: function goToScreenTest(state, _ref3) {
      var payload = _ref3.payload;
      state.testIndex = payload;
    },
    setTestIndex: function setTestIndex(state, _ref4) {
      var payload = _ref4.payload;
      state.testIndex = payload;
    }
  }
});
var _WorkflowScreenIndex$ = WorkflowScreenIndex.actions, nextScreen2 = _WorkflowScreenIndex$.nextScreen, prevScreen2 = _WorkflowScreenIndex$.prevScreen, goToScreen2 = _WorkflowScreenIndex$.goToScreen, setIndex2 = _WorkflowScreenIndex$.setIndex, nextScreenTest2 = _WorkflowScreenIndex$.nextScreenTest, prevScreenTest2 = _WorkflowScreenIndex$.prevScreenTest, goToScreenTest2 = _WorkflowScreenIndex$.goToScreenTest, setTestIndex2 = _WorkflowScreenIndex$.setTestIndex;
export { nextScreen2 as nextScreen, prevScreen2 as prevScreen, goToScreen2 as goToScreen, setIndex2 as setIndex, nextScreenTest2 as nextScreenTest, prevScreenTest2 as prevScreenTest, goToScreenTest2 as goToScreenTest, setTestIndex2 as setTestIndex };
export default WorkflowScreenIndex.reducer;
