import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  isForcedTutorial: false
};
var tutorialsReducer = createSlice({
  name: "TUTORIALS",
  initialState,
  reducers: {
    setIsForcedTutorial: function setIsForcedTutorial(state, _ref) {
      var payload = _ref.payload;
      state.isForcedTutorial = payload;
    }
  }
});
var setIsForcedTutorial2 = tutorialsReducer.actions.setIsForcedTutorial;
export { setIsForcedTutorial2 as setIsForcedTutorial };
export default tutorialsReducer.reducer;
