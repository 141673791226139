import { useMemo } from "react";
import { jsx as __cssprop } from "@emotion/react";
var NetWorkIcons = function NetWorkIcons2(_ref) {
  var symbol = _ref.symbol, _ref$height = _ref.height, height = _ref$height === void 0 ? "20px" : _ref$height, _ref$width = _ref.width, width = _ref$width === void 0 ? "20px" : _ref$width;
  var srcs = useMemo(function() {
    return "/networks/".concat(symbol, ".png");
  }, [symbol]);
  if (!symbol)
    return null;
  return __cssprop("img", {
    src: srcs,
    alt: symbol,
    height,
    width,
    loading: "lazy"
  });
};
export default NetWorkIcons;
