import { jsx as __cssprop } from "@emotion/react";
var MediumRoundIcon = function MediumRoundIcon2() {
  return __cssprop("svg", {
    width: "32",
    height: "33",
    viewBox: "0 0 32 33",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_10198_38475)"
  }, __cssprop("circle", {
    cx: "16",
    cy: "16.7051",
    r: "16",
    fill: "white"
  }), __cssprop("ellipse", {
    cx: "9.90991",
    cy: "17.2336",
    rx: "7.53979",
    ry: "7.04807",
    fill: "black"
  }), __cssprop("ellipse", {
    cx: "22.6707",
    cy: "17.2336",
    rx: "4.05989",
    ry: "7.04807",
    fill: "black"
  }), __cssprop("ellipse", {
    cx: "29.0491",
    cy: "17.2352",
    rx: "1.15997",
    ry: "5.96375",
    fill: "black"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_10198_38475"
  }, __cssprop("rect", {
    width: "32",
    height: "32",
    fill: "white",
    transform: "translate(0 0.705078)"
  }))));
};
export default MediumRoundIcon;
