function _objectDestructuringEmpty(obj) {
  if (obj == null)
    throw new TypeError("Cannot destructure " + obj);
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
import { jsx as __cssprop } from "@emotion/react";
var RoleIcon = function RoleIcon2(_ref) {
  var props = _extends({}, (_objectDestructuringEmpty(_ref), _ref));
  return __cssprop("svg", _extends({
    width: "43",
    height: "48",
    viewBox: "0 0 43 48",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __cssprop("g", {
    clipPath: "url(#clip0_5595_2473)"
  }, __cssprop("path", {
    d: "M30.2301 1.77002H11.9201C9.81037 1.77002 8.1001 3.47805 8.1001 5.58502C8.1001 7.69199 9.81037 9.40002 11.9201 9.40002H30.2301C32.3398 9.40002 34.0501 7.69199 34.0501 5.58502C34.0501 3.47805 32.3398 1.77002 30.2301 1.77002Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M39.12 3.22021H38.12C38.12 3.33021 38.14 3.43021 38.14 3.54021C38.14 8.66021 33.99 12.8202 28.86 12.8202H13.39C8.27002 12.8202 4.11002 8.67021 4.11002 3.54021C4.11002 3.43021 4.12002 3.33021 4.13002 3.22021H2.99002C1.35002 3.22021 0.0200195 4.55021 0.0200195 6.19021V44.2502C0.0200195 45.8902 1.35002 47.2202 2.99002 47.2202H39.13C40.77 47.2202 42.1 45.8902 42.1 44.2502V6.20021C42.1 4.56021 40.77 3.23021 39.13 3.23021L39.12 3.22021ZM21.16 15.7702C23.66 15.7702 25.69 17.8002 25.69 20.3002C25.69 22.8002 23.66 24.8302 21.16 24.8302C18.66 24.8302 16.63 22.8002 16.63 20.3002C16.63 17.8002 18.66 15.7702 21.16 15.7702ZM17.05 25.0802C17.05 25.0802 18.46 26.5802 21.28 26.5802C24.1 26.5802 25.2 25.0802 25.2 25.0802C25.2 25.0802 27.62 27.3702 27.93 30.1002C28.24 32.8302 27.93 33.1002 27.93 33.1002C27.93 33.1002 26.74 34.5102 21.14 34.5102C15.54 34.5102 14.13 32.9202 14.13 32.9202C14.13 32.9202 13.43 28.0302 17.04 25.0802H17.05ZM32.15 41.9702H10C8.95002 41.9702 8.10002 41.1202 8.10002 40.0702C8.10002 39.0202 8.95002 38.1702 10 38.1702H32.15C33.2 38.1702 34.05 39.0202 34.05 40.0702C34.05 41.1202 33.2 41.9702 32.15 41.9702Z",
    fill: "#64A6E7"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_5595_2473"
  }, __cssprop("rect", {
    width: "42.09",
    height: "46.46",
    fill: "white",
    transform: "translate(0 0.77002)"
  }))));
};
export default RoleIcon;
