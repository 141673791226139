var _excluded = ["variant", "size", "weight", "loading", "padding", "margin", "textColor", "lineHeight", "changeBackgroundOnHover", "background", "children", "endIcon", "disabled"];
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
function _objectWithoutProperties(source, excluded) {
  if (source == null)
    return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0)
        continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key))
        continue;
      target[key] = source[key];
    }
  }
  return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null)
    return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
      continue;
    target[key] = source[key];
  }
  return target;
}
import CircularProgress from "@mui/material/CircularProgress";
import React, { useMemo } from "react";
import { colors } from "theme/base/colors";
import { shadeColor } from "utils/methods/colors/shadeColor";
import ButtonContainer, { ProgressBox } from "./style";
import { jsx as __cssprop } from "@emotion/react";
var Button = function Button2(_ref) {
  var variant = _ref.variant, size = _ref.size, weight = _ref.weight, loading = _ref.loading, padding = _ref.padding, margin = _ref.margin, textColor = _ref.textColor, lineHeight = _ref.lineHeight, changeBackgroundOnHover = _ref.changeBackgroundOnHover, background = _ref.background, children = _ref.children, endIcon = _ref.endIcon, disabled = _ref.disabled, props = _objectWithoutProperties(_ref, _excluded);
  var shadeLoader = useMemo(function() {
    return background && background !== colors.success600 ? shadeColor(background, -40) : shadeColor(colors.success500, -40);
  }, [background]);
  return __cssprop(ButtonContainer, _extends({
    variant,
    weight,
    lineHeight,
    size,
    changeBackgroundOnHover,
    background,
    margin,
    textColor,
    padding
  }, props), loading ? __cssprop(ProgressBox, null, children, endIcon, __cssprop(CircularProgress, {
    sx: {
      color: shadeLoader
    },
    size: 20
  })) : __cssprop(React.Fragment, null, children, endIcon));
};
export default Button;
