import { jsx as __cssprop } from "@emotion/react";
var WalletConnect = function WalletConnect2() {
  return __cssprop("svg", {
    width: "40",
    height: "40",
    viewBox: "0 0 40 40",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("path", {
    d: "M40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20Z",
    fill: "url(#paint0_radial_92_323)"
  }), __cssprop("path", {
    d: "M13.2195 14.4166C16.9643 10.7501 23.0358 10.7501 26.7805 14.4166L27.2312 14.8578C27.4185 15.0412 27.4185 15.3384 27.2312 15.5217L25.6895 17.0312C25.5959 17.1228 25.4441 17.1228 25.3505 17.0312L24.7303 16.4239C22.1179 13.8661 17.8822 13.8661 15.2698 16.4239L14.6056 17.0742C14.512 17.1659 14.3602 17.1659 14.2666 17.0742L12.7249 15.5648C12.5376 15.3814 12.5376 15.0842 12.7249 14.9009L13.2195 14.4166ZM29.969 17.5383L31.3411 18.8817C31.5283 19.0651 31.5283 19.3623 31.3411 19.5456L25.1541 25.6033C24.9668 25.7867 24.6633 25.7867 24.476 25.6033C24.476 25.6033 24.476 25.6033 24.476 25.6033L20.0848 21.304C20.038 21.2582 19.9621 21.2582 19.9153 21.304C19.9153 21.304 19.9153 21.304 19.9153 21.304L15.5242 25.6033C15.337 25.7867 15.0334 25.7867 14.8462 25.6033C14.8462 25.6033 14.8462 25.6033 14.8462 25.6033L8.65898 19.5455C8.47175 19.3622 8.47175 19.065 8.65898 18.8817L10.0311 17.5382C10.2184 17.3549 10.5219 17.3549 10.7092 17.5382L15.1004 21.8376C15.1472 21.8835 15.2231 21.8835 15.2699 21.8376C15.2699 21.8376 15.2699 21.8376 15.2699 21.8376L19.6609 17.5382C19.8482 17.3549 20.1517 17.3549 20.339 17.5382C20.339 17.5382 20.339 17.5382 20.339 17.5382L24.7302 21.8376C24.777 21.8835 24.8529 21.8835 24.8997 21.8376L29.2909 17.5383C29.4781 17.355 29.7817 17.355 29.969 17.5383Z",
    fill: "white"
  }), __cssprop("defs", null, __cssprop("radialGradient", {
    id: "paint0_radial_92_323",
    cx: "0",
    cy: "0",
    r: "1",
    gradientUnits: "userSpaceOnUse",
    gradientTransform: "translate(0 20) scale(40)"
  }, __cssprop("stop", {
    stopColor: "#5D9DF6"
  }), __cssprop("stop", {
    offset: "1",
    stopColor: "#006FFF"
  }))));
};
export default WalletConnect;
