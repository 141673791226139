import { jsx as __cssprop } from "@emotion/react";
var CloseModalIcon = function CloseModalIcon2() {
  return __cssprop("svg", {
    width: "20",
    height: "21",
    viewBox: "0 0 20 21",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("path", {
    d: "M6.66669 7.16666L13.3334 13.8333",
    stroke: "white",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M13.3334 7.16666L6.66669 13.8333",
    stroke: "white",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
};
export default CloseModalIcon;
