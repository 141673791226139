import { jsx as __cssprop } from "@emotion/react";
var Dots = function Dots2() {
  return __cssprop("svg", {
    width: "23",
    height: "24",
    viewBox: "0 0 23 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    id: "Icon linear"
  }, __cssprop("g", {
    id: "Group 1000000921"
  }, __cssprop("path", {
    id: "Vector",
    d: "M11.2046 5.96362C12.1309 5.96362 12.8819 5.24772 12.8819 4.36462C12.8819 3.48152 12.1309 2.76562 11.2046 2.76562C10.2783 2.76562 9.52734 3.48152 9.52734 4.36462C9.52734 5.24772 10.2783 5.96362 11.2046 5.96362Z",
    fill: "white"
  }), __cssprop("path", {
    id: "Vector_2",
    d: "M11.2046 12.3621C12.1309 12.3621 12.8819 11.6462 12.8819 10.7631C12.8819 9.87996 12.1309 9.16406 11.2046 9.16406C10.2783 9.16406 9.52734 9.87996 9.52734 10.7631C9.52734 11.6462 10.2783 12.3621 11.2046 12.3621Z",
    fill: "white"
  }), __cssprop("path", {
    id: "Vector_3",
    d: "M11.2046 18.7527C12.1309 18.7527 12.8819 18.0368 12.8819 17.1537C12.8819 16.2706 12.1309 15.5547 11.2046 15.5547C10.2783 15.5547 9.52734 16.2706 9.52734 17.1537C9.52734 18.0368 10.2783 18.7527 11.2046 18.7527Z",
    fill: "white"
  }))));
};
export default Dots;
