var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import { Box } from "@mui/material";
import styled from "styled-components";
import { colors } from "theme/base/colors";
export var ConnectButton = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  /* width: ", ";\n  width: ", "; */\n  background: ", ";\n  color: ", ";\n  font-size: ", ";\n  padding: ", ";\n"])), {
  "display": "flex",
  "height": "52px",
  "cursor": "pointer",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "8px",
  "borderRadius": "4px",
  "borderStyle": "none",
  "paddingLeft": "24px",
  "paddingRight": "24px",
  "paddingTop": "10px",
  "paddingBottom": "10px",
  "fontWeight": "500",
  "transitionProperty": "opacity",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "300ms",
  ":hover": {
    "opacity": "0.6"
  },
  "@media (min-width: 640px)": {
    "height": "52px",
    "paddingLeft": "24px",
    "paddingRight": "24px",
    "paddingTop": "10px",
    "paddingBottom": "10px"
  },
  "@media (min-width: 768px)": {
    "height": "48px",
    "paddingLeft": "16px",
    "paddingRight": "16px",
    "paddingTop": "10px",
    "paddingBottom": "10px"
  },
  "@media (min-width: 1280px)": {
    "paddingLeft": "16px",
    "paddingRight": "16px",
    "paddingTop": "10px",
    "paddingBottom": "10px"
  },
  "@media (min-width: 1536px)": {
    "paddingLeft": "24px",
    "paddingRight": "24px",
    "paddingTop": "10px",
    "paddingBottom": "10px"
  }
}, function(_ref) {
  var fullwidth = _ref.fullwidth;
  return fullwidth ? "100%" : "65%";
}, function(_ref2) {
  var width = _ref2.width;
  return width ? width : "";
}, function(_ref3) {
  var bgcolor = _ref3.bgcolor;
  return bgcolor !== null && bgcolor !== void 0 ? bgcolor : colors.success50;
}, function(_ref4) {
  var textColor = _ref4.textColor;
  return textColor;
}, function(_ref5) {
  var fontSize = _ref5.fontSize;
  return fontSize !== null && fontSize !== void 0 ? fontSize : "16px";
}, function(_ref6) {
  var isMobile = _ref6.isMobile, padding = _ref6.padding;
  return isMobile ? "10px 12px" : padding;
});
export var ModalConnect = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "600px",
  background: colors.white800,
  boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.1), 0px 3px 8px rgba(0, 0, 0, 0.05)",
  padding: "24px",
  color: colors.white,
  borderRadius: "4px"
});
export var ModalHeaderWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "marginBottom": "20px",
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "space-between"
});
export var ModalHeaderLeft = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex",
  "alignItems": "center",
  "gap": "20px"
});
export var ModalBodyWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex",
  "flexDirection": "column",
  "gap": "0px"
});
export var ModalBodyContent = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  background: ", ";\n\n  ", "\n"])), colors.whiteTransparent, {
  "display": "flex",
  "flexDirection": "column",
  "justifyContent": "center",
  "gap": "0px",
  "borderBottomRightRadius": "6px",
  "borderBottomLeftRadius": "6px",
  "padding": "15px"
});
export var ErrorMessage = styled.p(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: ", ";\n  ", "\n"])), colors.error600, {
  "marginTop": "15px",
  "paddingLeft": "10px"
});
