function _objectDestructuringEmpty(obj) {
  if (obj == null)
    throw new TypeError("Cannot destructure " + obj);
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
import { DegenScoreSVG } from "assets/svg";
import { jsx as __cssprop } from "@emotion/react";
var DegenIcon = function DegenIcon2(_ref) {
  var props = _extends({}, (_objectDestructuringEmpty(_ref), _ref));
  return __cssprop(DegenScoreSVG, props);
};
export default DegenIcon;
