import DiscordIcon from "./DiscordIcon";
import DiscordRoundIcon from "./DiscordRoundIcon";
import DiscordSquare from "./DiscordSquare";
import FacebookSquare from "./FacebookSquare";
import LinkedinIcon from "./LinkedinIcon";
import LinkedInRoundIcon from "./LinkedInRoundIcon";
import LinkedinSquare from "./LinkedinSquare";
import MediumRoundIcon from "./MediumRoundIcon";
import TwitterIcon from "./TwitterIcon";
import TwitterRoundIcon from "./TwitterRoundIcon";
import TwitterSquare from "./TwitterSquare";
export { DiscordIcon, DiscordRoundIcon, DiscordSquare, FacebookSquare, LinkedinIcon, LinkedInRoundIcon, LinkedinSquare, MediumRoundIcon, TwitterIcon, TwitterRoundIcon, TwitterSquare };
