import { Box, Typography } from "@mui/material";
import { AccessDeniedIcon } from "assets/icons";
import PoweredByBanner from "components/PoweredByBanner";
import { CommonContainer } from "screens/KycVerification/styles";
import { fontSize } from "theme/base/fontSize";
import { fontWeights } from "theme/base/fontWeight";
import { jsx as __cssprop } from "@emotion/react";
var NotAllowed = function NotAllowed2() {
  return __cssprop(Box, {
    sx: {
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(180deg, #1e293b 0%, #1c1f2b 100%)",
      gap: "10px"
    }
  }, __cssprop(CommonContainer, null, __cssprop(Box, {
    sx: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "24px",
      gap: "8px",
      height: "700px",
      width: "-webkit-fill-available"
    }
  }, __cssprop(AccessDeniedIcon, null), __cssprop(Typography, {
    variant: "h2",
    fontSize: fontSize.h2,
    fontWeight: fontWeights.semibold,
    lineHeight: "30px",
    sx: {
      textAlign: "center"
    }
  }, "Access Denied"), __cssprop(Typography, {
    fontSize: fontSize.p,
    fontWeight: fontWeights.semibold,
    lineHeight: "22px",
    sx: {
      textAlign: "center"
    }
  }, "Sorry, this domain is not authorized to access our services"))), __cssprop(PoweredByBanner, null));
};
export default NotAllowed;
