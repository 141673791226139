import { jsx as __cssprop } from "@emotion/react";
var DiscordIcon = function DiscordIcon2() {
  return __cssprop("svg", {
    width: "44",
    height: "44",
    viewBox: "0 0 44 44",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_3948_12775)"
  }, __cssprop("circle", {
    cx: "21.6973",
    cy: "21.6524",
    r: "20.2598",
    fill: "white"
  }), __cssprop("path", {
    d: "M26.4432 20.0059C25.0446 20.0059 23.9336 21.2737 23.9336 22.8292C23.9336 24.3846 25.0577 25.6394 26.4432 25.6394C27.8287 25.6394 28.9397 24.3715 28.9528 22.8292C28.9659 21.2737 27.8549 20.0189 26.4432 20.0189V20.0059Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M17.7889 20.0059C16.3903 20.0059 15.2793 21.2737 15.2793 22.8292C15.2793 24.3846 16.4034 25.6394 17.7889 25.6394C19.1744 25.6394 20.2854 24.3715 20.2985 22.8292C20.3116 21.2737 19.2006 20.0189 17.7889 20.0189V20.0059Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M21.7501 0.0859375C9.8033 0.0859375 0.130859 9.77145 0.130859 21.7052C0.130859 33.6519 9.81637 43.3244 21.7501 43.3244C33.6968 43.3244 43.3693 33.6389 43.3693 21.7052C43.3693 9.75838 33.6968 0.0859375 21.7501 0.0859375ZM35.6444 28.8288C33.5139 30.4103 31.1219 31.6259 28.5861 32.3971C28.011 31.6259 27.5143 30.8025 27.0699 29.9529C27.8934 29.6392 28.6907 29.2601 29.4488 28.8157C29.2527 28.6719 29.0567 28.5151 28.8606 28.3582C24.3904 30.4757 19.2143 30.4757 14.7441 28.3582C14.5611 28.5151 14.365 28.6588 14.169 28.8157C14.9271 29.2601 15.7244 29.6392 16.5479 29.9529C16.1165 30.8155 15.6068 31.6259 15.0316 32.4102C12.4959 31.639 10.1039 30.4365 7.97338 28.8418C7.47669 23.6396 8.47007 18.359 12.1038 12.9477C13.9337 12.0981 15.8682 11.4968 17.868 11.157C18.1425 11.6537 18.4039 12.1503 18.6261 12.6732C20.7436 12.3595 22.8872 12.3595 25.0047 12.6732C25.2008 12.1896 25.4883 11.6014 25.7367 11.157C27.7235 11.4968 29.6579 12.085 31.4879 12.9346C34.6379 17.5748 36.2064 22.8162 35.6313 28.8418L35.6444 28.8288Z",
    fill: "#059669"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_3948_12775"
  }, __cssprop("rect", {
    width: "43.2384",
    height: "43.2384",
    fill: "white",
    transform: "translate(0.129883 0.0859375)"
  }))));
};
export default DiscordIcon;
