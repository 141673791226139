var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import { SpinnerSVG } from "assets/svg";
import styled from "styled-components";
export var SpinnerWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: ", ";\n"])), function(_ref) {
  var margin = _ref.margin;
  return margin || "";
});
export var StyledSpinner = styled(SpinnerSVG)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: ", ";\n  height: ", ";\n  color: ", ";\n  fill: ", ";\n  ", "\n"])), function(_ref2) {
  var size = _ref2.size;
  return size;
}, function(_ref3) {
  var size = _ref3.size;
  return size;
}, function(_ref4) {
  var $primaryColor = _ref4.$primaryColor;
  return $primaryColor;
}, function(_ref5) {
  var $bgColor = _ref5.$bgColor;
  return $bgColor;
}, {
  "marginRight": "0.5rem",
  "display": "inline",
  "@keyframes spin": {
    "to": {
      "transform": "rotate(360deg)"
    }
  },
  "animation": "spin 1s linear infinite"
});
