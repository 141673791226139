import { jsx as __cssprop } from "@emotion/react";
var ArrowLeft = function ArrowLeft2(_ref) {
  var _ref$w = _ref.w, w = _ref$w === void 0 ? "33px" : _ref$w, _ref$h = _ref.h, h = _ref$h === void 0 ? "33px" : _ref$h, _ref$color = _ref.color, color = _ref$color === void 0 ? "#B5B5B5" : _ref$color;
  return __cssprop("svg", {
    width: w,
    height: h,
    viewBox: "0 0 33 33",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("path", {
    d: "M11.7148 16.4395C11.7148 16.6699 11.7969 16.8726 11.97 17.0477L19.1975 24.1906C19.3524 24.3565 19.5529 24.4395 19.7899 24.4395C20.2638 24.4395 20.6284 24.08 20.6284 23.6007C20.6284 23.3611 20.5281 23.1583 20.3823 23.0017L13.7473 16.4395L20.3823 9.87724C20.5281 9.72056 20.6284 9.50858 20.6284 9.27816C20.6284 8.7989 20.2638 8.43945 19.7899 8.43945C19.5529 8.43945 19.3524 8.5224 19.1975 8.67908L11.97 15.8312C11.7969 15.9971 11.7148 16.209 11.7148 16.4395Z",
    fill: color
  }));
};
export default ArrowLeft;
