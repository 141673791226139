var _templateObject;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import styled from "styled-components";
import { colors } from "theme/base/colors";
export var StyledCustomButton = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: ", ";\n  margin: ", ";\n  width: ", ";\n  height: ", ";\n  border-radius: ", ";\n  font-size: ", ";\n  background-color: ", ";\n  color: ", ";\n  font-weight: ", ";\n\n  &:hover {\n    background-color: ", ";\n    opacity: ", ";\n  }\n\n  ", "\n\n  ", ";\n\n  ", "\n"])), function(props) {
  return props.padding ? props.padding : "";
}, function(props) {
  return props.margin ? props.margin : "";
}, function(props) {
  return props.width ? props.width : "content-fit";
}, function(props) {
  return props.height ? props.height : "content-fit";
}, function(props) {
  return props.borderRadius ? props.borderRadius : "4px";
}, function(props) {
  return props.fontSize ? props.fontSize : "";
}, function(props) {
  return props.bgColor ? props.bgColor : colors.success600;
}, function(props) {
  return props.color ? props.color : "white";
}, function(props) {
  return props.fontWeight === "semi-bold" ? 600 : props.fontWeight === "bold" ? 700 : 400;
}, function(props) {
  return props.hoverBg ? props.hoverBg : "";
}, function(props) {
  return props.hoverOpacity ? 0.8 : 1;
}, {
  "display": "flex",
  "alignItems": "center",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "300ms"
}, function(_ref) {
  var textCenter = _ref.textCenter;
  return textCenter ? {
    "justifyContent": "center"
  } : "";
}, function(_ref2) {
  var disabled = _ref2.disabled;
  return disabled && {
    "cursor": "not-allowed",
    "opacity": "0.5",
    ":hover": {
      "opacity": "0.5"
    }
  };
});
