export var NETWORKS_RPC = {
  1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  10: "https://mainnet.optimism.io",
  25: "https://evm-cronos.crypto.org",
  56: "https://bsc-dataseed.binance.org/",
  100: "https://rpc.gnosischain.com",
  137: "https://polygon-rpc.com",
  250: "https://rpc.ankr.com/fantom/",
  288: "https://mainnet.boba.network",
  1284: "https://rpc.api.moonbeam.network",
  1285: "https://rpc.moonriver.moonbeam.network",
  42161: "https://arb1.arbitrum.io/rpc",
  43114: "https://api.avax.network/ext/bc/C/rpc",
  16666e5: "https://api.harmony.one",
  1313161554: "https://mainnet.aurora.dev"
};
export var networkColor = {
  1: "rgba(57, 57, 57, 0.25)",
  // ETH
  10: "rgba(255, 4, 32, 0.25)",
  // Optimism
  56: "rgba(243, 186, 47, 0.25)",
  // BSC
  100: "rgba(0, 166, 196, 0.25)",
  // Gnosis
  137: "rgba(130, 71, 230, 0.25)",
  // Polygon
  250: "rgba(19, 181, 236, 0.25)",
  // FTM
  1284: "rgba(83, 203, 200, 0.25)",
  // MoonBeam NEED A COLOR
  1285: "rgba(83, 203, 200, 0.25)",
  // Moonriver
  42161: "rgba(40, 160, 240, 0.25)",
  // ARB
  43114: "rgba(232, 65, 66, 0.25)",
  // AVL
  16666e5: "rgba(232, 65, 66, 0.25)"
  // Harmony NEED A COLOR
};
