import { jsx as __cssprop } from "@emotion/react";
var CloseIcon = function CloseIcon2() {
  return __cssprop("svg", {
    width: "14",
    height: "14",
    viewBox: "0 0 14 14",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("circle", {
    cx: "7.09735",
    cy: "6.78925",
    r: "6.06317",
    fill: "white"
  }), __cssprop("path", {
    d: "M4.96045 4.65234L9.23305 8.92494",
    stroke: "#E11D48",
    strokeWidth: "0.801112",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M9.23305 4.65234L4.96045 8.92494",
    stroke: "#E11D48",
    strokeWidth: "0.801112",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
};
export default CloseIcon;
