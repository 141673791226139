function _typeof(obj) {
  "@babel/helpers - typeof";
  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(obj2) {
    return typeof obj2;
  } : function(obj2) {
    return obj2 && "function" == typeof Symbol && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
  }, _typeof(obj);
}
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function(sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), true).forEach(function(key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function(key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, { value, enumerable: true, configurable: true, writable: true });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return _typeof(key) === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== "object" || input === null)
    return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== void 0) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
      return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
import { driver } from "driver.js";
import { useNavigate } from "react-router-dom";
import useTutorialHook from "store/tutorials/useTutorialHook";
import { onButtonClick, tutorialsCommonProps } from "./tutorialsCommonProps";
var useCreateRoleTutorial = function useCreateRoleTutorial2() {
  var navigate = useNavigate();
  var _useTutorialHook = useTutorialHook(), setForcedTutorial = _useTutorialHook.setForcedTutorial;
  var hasSeenCreateRoleTutorial = localStorage.getItem("hasSeenCreateRoleTutorial") === "true";
  var steps = [{
    element: "#create_role_container",
    popover: {
      title: "Let's start!",
      description: "Let's get started with creating a role!",
      side: "over",
      align: "center"
    }
  }, {
    element: "#create_role_button",
    popover: {
      title: "Create a role.",
      description: "There are two ways to create a new role.",
      onNextClick: function onNextClick() {
        onButtonClick("create_role_button");
        createRoleTutorial.moveNext();
      }
    }
  }, {
    element: "#create_role_dropdown",
    popover: {
      title: "Set a creation method.",
      description: "1) Create a custom role yourlself. \n\n           2) Start from a list of templates we have made for you!",
      onPrevClick: function onPrevClick() {
        onButtonClick("create_role_button");
        createRoleTutorial.movePrevious();
      },
      onNextClick: function onNextClick() {
        if (hasSeenCreateRoleTutorial) {
          setForcedTutorial(true);
        }
        navigate("/create-role");
      }
    }
  }, {
    element: "#create_role_name_container",
    popover: {
      title: "Role name.",
      description: "Choose a name for the role you are creating.",
      onNextClick: function onNextClick() {
        onButtonClick("create_role_name_next_button");
        createRoleTutorial.moveNext();
      },
      onPrevClick: function onPrevClick() {
        navigate("/dashboard");
        createRoleTutorial.movePrevious();
      }
    }
  }, {
    element: "#select_checks_container",
    popover: {
      title: "Select your checks.",
      description: "This panel is where you will configure this role's access controls.",
      onPrevClick: function onPrevClick() {
        onButtonClick("edit_role_name_button");
        createRoleTutorial.movePrevious();
      }
    }
  }, {
    element: "#add_check_button",
    popover: {
      title: "Add check.",
      description: 'Click this "Add" button to add a new access control to this role.',
      onNextClick: function onNextClick() {
        onButtonClick("add_check_button");
        createRoleTutorial.moveNext();
      }
    }
  }, {
    element: "#add_check_dropdown",
    popover: {
      title: "List of checks.",
      description: 'Once you click "Add" you can see a list of all the access controls.',
      onPrevClick: function onPrevClick() {
        onButtonClick("add_check_button");
        createRoleTutorial.movePrevious();
      }
    }
  }, {
    element: "#add_check_KYC",
    popover: {
      title: "Checks.",
      description: "Select a specific access control.",
      onPrevClick: function onPrevClick() {
        onButtonClick("add_check_dropdown");
        createRoleTutorial.movePrevious();
      }
    }
  }, {
    element: "#and_or_options",
    popover: {
      title: "New condition",
      description: "Or, you can select a new condition to combine multiple access controls to be verified for this role.",
      onNextClick: function onNextClick() {
        onButtonClick("add_check_button");
        createRoleTutorial.moveNext();
      }
    }
  }, {
    element: "#save_role_button",
    popover: {
      title: "Save your role.",
      description: "\u2705",
      onNextClick: function onNextClick() {
        localStorage.setItem("hasSeenCreateRoleTutorial", "true");
        onButtonClick("edit_role_name_button");
        createRoleTutorial.destroy();
      },
      onPrevClick: function onPrevClick() {
        onButtonClick("add_check_button");
        createRoleTutorial.movePrevious();
      }
    }
  }];
  var onDestroyed = function onDestroyed2() {
    navigate("/dashboard");
    setForcedTutorial(false);
  };
  var onCloseClick = function onCloseClick2() {
    localStorage.setItem("hasSeenCreateRoleTutorial", "true");
    onButtonClick("edit_role_name_button");
    createRoleTutorial.destroy();
  };
  var createRoleTutorial = driver(_objectSpread(_objectSpread({}, tutorialsCommonProps), {}, {
    steps,
    onDestroyed,
    onCloseClick
  }));
  return {
    createRoleTutorial,
    hasSeenCreateRoleTutorial
  };
};
export default useCreateRoleTutorial;
