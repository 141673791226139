var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import styled from "styled-components";
import { colors } from "theme/base/colors";
export var ProgressBox = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex",
  "alignItems": "center",
  "gap": "8px"
});
var ButtonContainer = styled.button(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: ", ";\n  align-items: center;\n  border-radius: 4px;\n  gap: 8px;\n  flex: none;\n  text-align: start;\n  margin: ", ";\n  line-height: ", ";\n  border-radius: ", ";\n  padding: ", ";\n  background: ", ";\n  color: ", ";\n\n  border: ", ";\n\n  width: ", ";\n  height: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n\n  &:hover {\n    opacity: 0.9;\n    background-color: ", ";\n  }\n  cursor: ", ";\n\n  opacity: ", ";\n  pointer-events: ", ";\n"])), function(_ref) {
  var justify = _ref.justify;
  return justify || "center";
}, function(_ref2) {
  var margin = _ref2.margin;
  return margin || "";
}, function(_ref3) {
  var lineHeight = _ref3.lineHeight;
  return lineHeight || "26px";
}, function(_ref4) {
  var borderRadius = _ref4.borderRadius;
  return borderRadius || "";
}, function(_ref5) {
  var padding = _ref5.padding;
  return padding || "12px 16px";
}, function(_ref6) {
  var variant = _ref6.variant, background = _ref6.background;
  if (background) {
    return background;
  }
  switch (variant) {
    case "success":
      return colors.success600;
    case "error":
      return colors.error600;
    case "successDisabled":
      return colors.success600;
    case "ghost":
      return colors.ghostBg;
    case "disabled":
      return colors.disabledBg;
    case "transparent":
      return "transparent";
    case "navbar":
      return colors.navbar;
    default:
      return colors.white700;
  }
}, function(_ref7) {
  var variant = _ref7.variant, textColor = _ref7.textColor;
  if (textColor && textColor !== colors.white200) {
    return textColor;
  }
  switch (variant) {
    case "success":
      return colors.white;
    case "disabled":
      return colors.disabledText;
    case "colorError":
      return colors.error600;
    default:
      return colors.white;
  }
}, function(_ref8) {
  var variant = _ref8.variant, background = _ref8.background;
  var solid = "1px solid";
  if (background && background !== colors.success600) {
    return solid + background;
  }
  switch (variant) {
    case "error":
      return "".concat(solid, " ").concat(colors.error600);
    case "success":
      return "".concat(solid, " ").concat(colors.success600);
    case "warning":
      return "".concat(solid, " ").concat(colors.warn700);
    case "transparent":
      return "1px solid transparent";
    default:
      return "".concat(solid, " transparent");
  }
}, function(_ref9) {
  var width = _ref9.width;
  return width || "auto";
}, function(_ref10) {
  var height = _ref10.height;
  return height || "40px";
}, function(_ref11) {
  var size = _ref11.size;
  return size || "16px";
}, function(_ref12) {
  var weight = _ref12.weight;
  return weight || "600";
}, function(_ref13) {
  var changeBackgroundOnHover = _ref13.changeBackgroundOnHover;
  return changeBackgroundOnHover ? colors.success600 : "";
}, function(_ref14) {
  var variant = _ref14.variant;
  switch (variant) {
    case "successDisabled":
      return "not-allowed";
    case "disabled":
      return "not-allowed";
    default:
      return "pointer";
  }
}, function(_ref15) {
  var variant = _ref15.variant;
  switch (variant) {
    case "successDisabled":
      return "0.5";
    default:
      return "1";
  }
}, function(_ref16) {
  var variant = _ref16.variant;
  switch (variant) {
    case "successDisabled":
      return "none";
    case "disabled":
      return "none";
    default:
      return "";
  }
});
export default ButtonContainer;
