import { jsx as __cssprop } from "@emotion/react";
var FollowingIcon = function FollowingIcon2() {
  return __cssprop("svg", {
    width: "45",
    height: "45",
    viewBox: "0 0 45 45",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("rect", {
    x: "0.0625",
    y: "0.806396",
    width: "44.0326",
    height: "44.0326",
    rx: "4.43203",
    fill: "#2A3446"
  }), __cssprop("g", {
    clipPath: "url(#clip0_4393_13075)"
  }, __cssprop("path", {
    d: "M29.2952 37.9236C28.6761 37.7351 28.0241 37.6162 27.443 37.3456C25.2917 36.3446 24.0359 33.9543 24.3837 31.6323C24.7517 29.1744 26.6209 27.2836 29.0321 26.9308C32.4006 26.4382 35.3904 29.0011 35.398 32.3873C35.4037 35.1166 33.365 37.4765 30.6718 37.8603C30.5902 37.8717 30.5118 37.9021 30.4315 37.9229H29.2952V37.9236ZM28.8949 29.469V31.4413H26.9422V33.351H28.9151V35.3094H30.8337V33.3416H32.7889V31.4211H30.8046V29.469H28.8949Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M23.9392 18.1076C27.4658 19.1484 29.6828 21.4154 30.6314 24.9762C28.1209 24.8073 26.0025 25.5681 24.3363 27.4177C22.6701 29.2673 22.1389 31.4559 22.56 33.9126H7.82625C7.82625 33.7994 7.82625 33.6887 7.82625 33.5774C7.82625 31.4002 7.7959 29.2224 7.83257 27.0459C7.89834 23.1417 9.7214 20.3366 13.2341 18.6394C13.6926 18.4174 14.194 18.284 14.7088 18.0981C12.7055 16.232 11.8689 13.9524 12.388 11.2491C12.7112 9.56705 13.5844 8.17524 14.9446 7.13313C17.8724 4.88955 21.8524 5.17727 24.3761 7.70983C26.8252 10.1665 27.4639 15.0046 23.9392 18.1076Z",
    fill: "#059669"
  }), __cssprop("ellipse", {
    cx: "30.6328",
    cy: "33.4821",
    rx: "6.4277",
    ry: "6.61211",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M27.8201 32.6556L30.0187 34.9173C30.1347 35.0366 30.3263 35.0366 30.4423 34.9173L36.6582 28.523",
    stroke: "white",
    strokeWidth: "1.40657",
    strokeLinecap: "round"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_4393_13075"
  }, __cssprop("rect", {
    width: "31.3555",
    height: "35.6804",
    fill: "white",
    transform: "translate(6.75 5.06628)"
  }))));
};
export default FollowingIcon;
