import { jsx as __cssprop } from "@emotion/react";
var getCountryISO2 = require("country-iso-3-to-2");
var FlagIcon = function FlagIcon2(_ref) {
  var country = _ref.country, styles = _ref.styles;
  return __cssprop("img", {
    src: "http://purecatamphetamine.github.io/country-flag-icons/3x2/".concat(getCountryISO2(country), ".svg"),
    alt: "",
    style: {
      maxWidth: "24px",
      borderRadius: "2px"
    }
  });
};
export default FlagIcon;
