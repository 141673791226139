var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import styled from "styled-components";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex",
  "height": "100%",
  "minHeight": "100%",
  "flex": "1 1 0%",
  "alignItems": "center",
  "justifyContent": "center"
});
export var Header = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  background: ", ";\n"])), {
  "marginBottom": "0.75rem",
  "display": "flex",
  "height": "fit-content",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "flex-start",
  "gap": "0.75rem",
  "borderRadius": "0.375rem",
  "padding": "0.5rem"
}, function(_ref) {
  var bg = _ref.bg;
  return bg;
});
export var Title = styled.h1(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n"])), {
  "width": "100%",
  "fontSize": "24px",
  "lineHeight": "32px",
  "fontWeight": "500"
}, function(_ref2) {
  var color = _ref2.color;
  return color;
});
export var MainSection = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n"])), {
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "flex": "1 1 0%",
  "flexDirection": "column",
  "alignItems": "center"
}, function(_ref3) {
  var isLoading = _ref3.isLoading;
  return isLoading ? {
    "justifyContent": "flex-start"
  } : {
    "justifyContent": "space-around"
  };
});
export var Description = styled.h3(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n"])), {
  "width": "100%",
  "textAlign": "justify",
  "fontSize": "18px",
  "lineHeight": "28px",
  "fontWeight": "700"
}, function(_ref4) {
  var color = _ref4.color;
  return color;
});
export var Error = styled.p(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "width": "100%",
  "textAlign": "center",
  "fontSize": "18px",
  "lineHeight": "28px",
  "fontWeight": "400",
  "--tw-text-opacity": "1",
  "color": "rgb(225 29 72 / var(--tw-text-opacity))"
});
