function _typeof(obj) {
  "@babel/helpers - typeof";
  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(obj2) {
    return typeof obj2;
  } : function(obj2) {
    return obj2 && "function" == typeof Symbol && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
  }, _typeof(obj);
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, { value, enumerable: true, configurable: true, writable: true });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return _typeof(key) === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== "object" || input === null)
    return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== void 0) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
      return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
import { AccordionSummary, Box, Grid, InputBase, styled, Switch, TableCell, tableCellClasses, TextField, Typography } from "@mui/material";
import Button from "components/Button";
import { colors } from "theme/base/colors";
import { fontSize } from "../../../theme/base/fontSize";
export var StyledInput = styled(InputBase)(function() {
  return {
    "& .MuiInputBase-input": {
      borderRadius: "7px",
      position: "relative",
      backgroundColor: colors.white700,
      border: "none",
      padding: "10px",
      lineHeight: "22px",
      fontSize: "18px",
      textAlign: "center",
      color: "white"
    }
  };
});
export var Title = styled(Typography)(function() {
  return {
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: "400",
    marginBottom: "18px",
    color: "white"
  };
});
export var GridItem = styled(Box)(function() {
  return {
    backgroundColor: colors.white700,
    textAlign: "center",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  };
});
export var RefreshButton = styled(Button)(function() {
  return {
    background: "transparent",
    color: colors.success600,
    fontSize: fontSize.h4,
    fontWeight: 600,
    "&:hover": {
      opacity: 1,
      color: colors.success500
    }
  };
});
export var refreshModalStlyes = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  padding: "30px",
  maxWidth: "440px",
  minHeight: "323px",
  background: colors.white800,
  color: "white",
  borderRadius: "12px",
  boxShadow: 24
};
export var StyledTableCell = styled(TableCell)(function() {
  var _ref;
  return _ref = {}, _defineProperty(_ref, "&.".concat(tableCellClasses.head), {
    backgroundColor: colors.white700
  }), _defineProperty(_ref, "&.".concat(tableCellClasses.body), {
    marginLeft: "10px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "white",
    border: 0
  }), _defineProperty(_ref, "color", colors.white), _defineProperty(_ref, "height", "40px"), _defineProperty(_ref, "border", 0), _defineProperty(_ref, "borderColor", "transparent"), _ref;
});
export var StyledAccordionInput = styled(TextField)(function(_ref2) {
  var w = _ref2.w, h = _ref2.h, bgcolor = _ref2.bgcolor;
  return {
    backgroundColor: bgcolor !== null && bgcolor !== void 0 ? bgcolor : colors.white500,
    textAlign: "center",
    borderRadius: "3px",
    width: w,
    height: h,
    "& label.Mui-focused": {
      color: colors.white300
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent"
      },
      "&:hover fieldset": {
        borderColor: "transparent"
      },
      "&.Mui-error fieldset": {
        borderColor: colors.error600
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent"
      }
    },
    "& .MuiInputBase-input": {
      padding: "10px 12px",
      color: colors.white
    },
    "& .MuiInputLabel-root": {
      color: colors.white
    }
  };
});
export var StyledSelect = styled(InputBase)(function(_ref3) {
  var bgcolor = _ref3.bgcolor;
  return {
    "#select-id": {
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      borderRadius: "4px",
      background: "".concat(bgcolor !== null && bgcolor !== void 0 ? bgcolor : colors.white500),
      minHeight: "37px"
    },
    p: {
      paddingLeft: "15px"
    },
    ".MuiSelect-icon": {
      color: "white"
    }
  };
});
export var StyledAccordionSummary = styled(AccordionSummary)(function(_ref4) {
  var isexpanded = _ref4.isexpanded, isremove = _ref4.isremove;
  return {
    backgroundColor: "".concat(isexpanded ? colors.white600 : colors.checks),
    display: "flex",
    width: "100%",
    height: "60px",
    borderRadius: "".concat(isexpanded ? "6px 6px 0px 0px" : "6px"),
    alignItems: "center",
    "> .MuiAccordionSummary-expandIconWrapper": isremove ? {
      transform: "rotate(0deg) !important"
    } : {}
  };
});
export var StyledCountryBadge = styled(Grid)(function() {
  return {
    background: "#50688A",
    borderRadius: "7px",
    marginRight: "5px",
    position: "relative",
    height: "40px",
    padding: "10px"
  };
});
export var MaterialUISwitch = styled(Switch)(function() {
  return {
    width: "65px",
    height: "40px",
    padding: "7px",
    "& .MuiSwitch-switchBase": {
      margin: 12,
      padding: 0,
      backgroundColor: "#CBD5E1",
      transform: "translateX(10px),translateY(10px) ",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(24px)",
        backgroundColor: "#CBD5E1",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#CBD5E1"
        }
      }
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "linear-gradient(180deg, #FFFFFF 0%, #E8EAEA 100%)",
      width: 18,
      height: 18,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#CBD5E1",
      borderRadius: 13,
      boxShadow: "inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1)"
    }
  };
});
