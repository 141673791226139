import { jsx as __cssprop } from "@emotion/react";
var EditIcon = function EditIcon2() {
  return __cssprop("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("rect", {
    width: "24",
    height: "24",
    rx: "4",
    fill: "#475569"
  }), __cssprop("g", {
    clipPath: "url(#clip0_10403_135558)"
  }, __cssprop("path", {
    d: "M7.62264 16.4984H15.2949C15.5807 16.498 15.8545 16.3938 16.0566 16.2086C16.2588 16.0233 16.3724 15.7723 16.3728 15.5103V11.789C16.3728 11.6645 16.3003 11.5493 16.1827 11.4872C16.0651 11.4248 15.9199 11.4248 15.8024 11.4872C15.6848 11.5493 15.6122 11.6645 15.6122 11.789V15.5103C15.6122 15.5874 15.5785 15.6613 15.5192 15.7159C15.4599 15.7703 15.379 15.801 15.2949 15.8012H7.62264C7.53854 15.8012 7.45791 15.7705 7.39858 15.7159C7.33903 15.6613 7.30556 15.5874 7.30556 15.5103V8.82385C7.30556 8.74675 7.33903 8.67284 7.39858 8.61846C7.45791 8.56387 7.53875 8.53319 7.62264 8.53319H11.8694C12.0052 8.53319 12.1309 8.46665 12.1989 8.35888C12.2669 8.2511 12.2667 8.11803 12.1989 8.01025C12.1311 7.90248 12.0055 7.83594 11.8694 7.83594H7.62264C7.33686 7.83634 7.06303 7.94053 6.86091 8.1256C6.6588 8.31087 6.54536 8.56188 6.54492 8.82385V15.5105C6.54536 15.7725 6.6588 16.0235 6.86091 16.2088C7.06303 16.394 7.33686 16.4982 7.62264 16.4984Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M9.04855 12.9017L8.79363 14.0689C8.76233 14.2109 8.80232 14.3584 8.90251 14.4705C9.0027 14.5827 9.15243 14.6478 9.31043 14.6478C9.34542 14.6478 9.37997 14.6446 9.41431 14.6385L10.6887 14.4048C10.9336 14.3604 11.1586 14.25 11.3353 14.0882L16.7549 9.11919C17.0835 8.79846 17.2055 8.34365 17.0766 7.91972C16.9477 7.49579 16.5861 7.16469 16.1238 7.04636C15.6614 6.92802 15.1652 7.03978 14.8153 7.34119L9.39388 12.3094C9.2172 12.471 9.09702 12.677 9.04855 12.9013V12.9017ZM9.79442 13.0382C9.81376 12.9489 9.86157 12.867 9.93177 12.8027L15.3525 7.83366C15.5075 7.69361 15.7316 7.63962 15.9417 7.69161C16.1516 7.74361 16.3157 7.89402 16.3724 8.08646C16.4292 8.2789 16.3703 8.48449 16.2175 8.62634L10.7967 13.595C10.7265 13.6593 10.6372 13.7033 10.5396 13.7213L9.6086 13.892L9.79442 13.038V13.0382Z",
    fill: "white"
  })), __cssprop("rect", {
    width: "24",
    height: "24",
    rx: "4",
    stroke: "#475569"
  }), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_10403_135558"
  }, __cssprop("rect", {
    width: "10.582",
    height: "9.5",
    fill: "white",
    transform: "translate(6.54492 7)"
  }))));
};
export default EditIcon;
