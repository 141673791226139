import { ReactComponent as AnalyticsDisabledSVG } from "./others/analyticsDisabled.svg";
import { ReactComponent as ArrowDownSVG } from "./others/arrowDown.svg";
import { ReactComponent as CloverWalletSVG } from "./others/cloverWallet.svg";
import { ReactComponent as ConfigPlaygroundSVG } from "./others/ConfigPlayground.svg";
import { ReactComponent as CopyIconSVG } from "./others/CopyIcon.svg";
import { ReactComponent as CreateRoleSVG } from "./others/createCustomRole.svg";
import { ReactComponent as CreditScoreSVG } from "./others/creditScore.svg";
import { ReactComponent as DegenScoreSVG } from "./others/degenScore.svg";
import { ReactComponent as DeleteIconSVG } from "./others/deleteIcon.svg";
import { ReactComponent as EditIconSVG } from "./others/editIcon.svg";
import GatekeeperDarkSVG from "./others/gatekeeper_dark.svg";
import GatekeeperLightSVG from "./others/gatekeeper_light.svg";
import { ReactComponent as QuestionCircleSVG } from "./others/questionCircle.svg";
import { ReactComponent as RefreshSVG } from "./others/refresh.svg";
import { ReactComponent as SelectTemplateSVG } from "./others/selectTemplate.svg";
import { ReactComponent as SpinnerSVG } from "./others/SpinnerSVG.svg";
import { ReactComponent as UserAvatarSVG } from "./others/userAvatar.svg";
import { ReactComponent as WarningRedSVG } from "./others/WarningRed.svg";
export { AnalyticsDisabledSVG, ArrowDownSVG, SpinnerSVG, CloverWalletSVG, ConfigPlaygroundSVG, CopyIconSVG, CreateRoleSVG, CreditScoreSVG, DegenScoreSVG, DeleteIconSVG, EditIconSVG, GatekeeperDarkSVG, GatekeeperLightSVG, QuestionCircleSVG, RefreshSVG, SelectTemplateSVG, UserAvatarSVG, WarningRedSVG };
