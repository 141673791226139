import { StyledConnectButton } from "./ConnectButton.style";
import { jsx as __cssprop } from "@emotion/react";
var ConnectButton = function ConnectButton2(_ref) {
  var fullwidth = _ref.fullwidth, width = _ref.width, isMobile = _ref.isMobile, padding = _ref.padding, bgcolor = _ref.bgcolor, textColor = _ref.textColor, fontSize = _ref.fontSize, children = _ref.children, onClick = _ref.onClick, bgHover = _ref.bgHover;
  return __cssprop(StyledConnectButton, {
    fullwidth,
    width,
    isMobile,
    padding,
    bgcolor,
    textColor,
    fontSize,
    onClick,
    bgHover
  }, children);
};
export default ConnectButton;
