import { jsx as __cssprop } from "@emotion/react";
var GeoFencingIcon = function GeoFencingIcon2(_ref) {
  var _ref$w = _ref.w, w = _ref$w === void 0 ? "41px" : _ref$w, _ref$h = _ref.h, h = _ref$h === void 0 ? "42px" : _ref$h, _ref$opacity = _ref.opacity, opacity = _ref$opacity === void 0 ? "1.0" : _ref$opacity;
  return __cssprop("svg", {
    width: w,
    height: h,
    opacity,
    viewBox: "0 0 41 42",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("ellipse", {
    cx: "20.1388",
    cy: "20.8526",
    rx: "20.1388",
    ry: "20.6687",
    fill: "#D9D9D9"
  }), __cssprop("path", {
    d: "M20.1386 7.23657C14.8692 7.23657 10.6074 11.4984 10.6074 16.7678C10.6074 23.9162 20.1386 34.4686 20.1386 34.4686C20.1386 34.4686 29.6699 23.9162 29.6699 16.7678C29.6699 11.4984 25.408 7.23657 20.1386 7.23657ZM20.1386 20.1718C19.2358 20.1718 18.37 19.8132 17.7316 19.1748C17.0933 18.5364 16.7346 17.6706 16.7346 16.7678C16.7346 15.865 17.0933 14.9992 17.7316 14.3608C18.37 13.7224 19.2358 13.3638 20.1386 13.3638C21.0414 13.3638 21.9073 13.7224 22.5456 14.3608C23.184 14.9992 23.5426 15.865 23.5426 16.7678C23.5426 17.6706 23.184 18.5364 22.5456 19.1748C21.9073 19.8132 21.0414 20.1718 20.1386 20.1718V20.1718Z",
    fill: "#059669"
  }));
};
export default GeoFencingIcon;
