import { BACKEND_URL } from "config";
var makeRequest = function makeRequest2(url, method, body, options) {
  var isGETmethod = method === "GET";
  var finalURL = isGETmethod ? "".concat(url, "?").concat(new URLSearchParams(body).toString()) : url;
  return fetch(BACKEND_URL + finalURL, {
    method,
    mode: "cors",
    credentials: options !== null && options !== void 0 && options.credentials ? "include" : "omit",
    headers: {
      "Content-Type": "application/json"
    },
    body: isGETmethod ? null : JSON.stringify(body)
  }).then(function(res) {
    return res.json();
  }).catch(function(err) {
    return console.error(err);
  });
};
export var doChecksCheck = function doChecksCheck2(address, ids) {
  return makeRequest("/check", "GET", {
    address,
    ids
  });
};
export var doRoleCheck = function doRoleCheck2(role, dryRun) {
  return makeRequest("/checkRole", "GET", {
    role,
    dryRun
  }, {
    credentials: true
  });
};
export var login = function login2(walletAddress, signature, forApp) {
  return makeRequest("/users/login", "POST", {
    walletAddress,
    signature,
    forApp
  }, {
    credentials: true
  });
};
export var register = function register2(address, signature, appId) {
  return makeRequest("/users/register", "POST", {
    address,
    signature,
    appId
  }, {
    credentials: true
  });
};
export var authenticateDomain = function authenticateDomain2(domain) {
  return makeRequest("/authenticate", "GET", {
    domain
  });
};
