var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
import styledComponent from "styled-components";
import { baseTheme } from "theme/base";
import { colors } from "theme/base/colors";
export var LoaderButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "12px 16px",
  border: "1px solid",
  backgroundColor: colors.success600,
  borderColor: colors.success600,
  "&:hover": {
    opacity: 0.9,
    background: colors.success600
  },
  "&:disabled": {
    backgroundColor: colors.success600
  }
});
export var ListContainer = styledComponent.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n", "\n"])), {
  "marginLeft": "10px",
  "marginRight": "10px",
  "display": "flex",
  "flexDirection": "column",
  "gap": "1rem"
});
export var WalletItemWrapper = styledComponent.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\nbackground-color: ", ";\n\n  ", "\n"])), function(props) {
  return props.isSelected ? baseTheme.colors.white600 : baseTheme.colors.white700;
}, {
  "display": "flex",
  "height": "64px",
  "cursor": "pointer",
  "alignItems": "center",
  "justifyContent": "space-between",
  "borderRadius": "6px",
  "padding": "12px",
  "paddingLeft": "14px",
  "paddingRight": "14px"
});
export var ItemInfo = styledComponent.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n", "\n"])), {
  "display": "flex",
  "alignItems": "center",
  "gap": "10px"
});
export var ItemName = styledComponent.p(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n", "\n"])), {
  "fontSize": "18px",
  "lineHeight": "22px",
  "fontWeight": "400",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
});
