import { selectGeneralAppState } from "store/general/selectors";
import { useAppSelector } from "store/hooks";
import { selectSessionData } from "store/sessionDetails/selectors";
import { selectWalletDetails } from "store/walletDetails/selectors";
var useWallet = function useWallet2() {
  var _useAppSelector = useAppSelector(selectGeneralAppState), isConnected = _useAppSelector.isConnected, isLoggedIn = _useAppSelector.isLoggedIn, isVerified = _useAppSelector.isVerified;
  var _useAppSelector2 = useAppSelector(selectWalletDetails), address = _useAppSelector2.address;
  var _useAppSelector3 = useAppSelector(selectSessionData), user = _useAppSelector3.user, token = _useAppSelector3.token;
  return {
    isConnected,
    isLoggedIn,
    isVerified,
    altVerified: user === null || user === void 0 ? void 0 : user.altVerified,
    address,
    user,
    token
  };
};
export default useWallet;
