import Spinner from "components/Spinner/Spinner";
import { StyledButton } from "./Button.style";
import { jsx as __cssprop } from "@emotion/react";
var Button = function Button2(_ref) {
  var label = _ref.label, onClick = _ref.onClick, disabled = _ref.disabled, variant = _ref.variant, loading = _ref.loading;
  return __cssprop(StyledButton, {
    onClick,
    disabled,
    variant
  }, loading && __cssprop(Spinner, {
    size: "18px"
  }), label);
};
export default Button;
