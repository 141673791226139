import { jsx as __cssprop } from "@emotion/react";
var DotItem = function DotItem2() {
  return __cssprop("svg", {
    width: "19",
    height: "18",
    viewBox: "0 0 19 18",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("path", {
    d: "M18.5 9C18.5 13.9706 14.4706 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9Z",
    fill: "url(#paint0_radial_7420_39643)"
  }), __cssprop("defs", null, __cssprop("radialGradient", {
    id: "paint0_radial_7420_39643",
    cx: "0",
    cy: "0",
    r: "1",
    gradientUnits: "userSpaceOnUse",
    gradientTransform: "translate(9.5 9) rotate(90) scale(9)"
  }, __cssprop("stop", {
    stopColor: "#64A6E7"
  }), __cssprop("stop", {
    offset: "1",
    stopColor: "#80AFD5",
    stopOpacity: "0"
  }))));
};
export default DotItem;
