var _templateObject;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import styled from "styled-components";
import { colors } from "theme/base/colors";
export var StyledButton = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  ", "\n\n  ", "\n"])), function(props) {
  return props.variant === "primary" ? colors.success600 : colors.white600;
}, function(props) {
  return props.variant === "primary" ? colors.success500 : colors.white500;
}, {
  "display": "flex",
  "height": "52px",
  "width": "fit-content",
  "minWidth": "64px",
  "alignItems": "center",
  "justifyContent": "center",
  "borderRadius": "0.375rem",
  "paddingTop": "0.375rem",
  "paddingBottom": "0.375rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "fontSize": "18px",
  "lineHeight": "1.75",
  "fontWeight": "400",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "300ms",
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-opacity": "0.5",
    "--tw-ring-color": "rgb(5 122 85 / var(--tw-ring-opacity))"
  },
  "@media (min-width: 640px)": {
    "height": "52px"
  },
  "@media (min-width: 768px)": {
    "height": "48px"
  }
}, function(_ref) {
  var disabled = _ref.disabled;
  return disabled && {
    "cursor": "not-allowed",
    "opacity": "0.5"
  };
});
