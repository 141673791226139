import { jsx as __cssprop } from "@emotion/react";
var Check = function Check2(_ref) {
  var _ref$size = _ref.size, size = _ref$size === void 0 ? "100px" : _ref$size, _ref$sx = _ref.sx, sx = _ref$sx === void 0 ? {} : _ref$sx;
  return __cssprop("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    height: size,
    viewBox: "0 0 24 24",
    width: size,
    fill: "#10b981",
    style: sx
  }, __cssprop("path", {
    d: "M0 0h24v24H0V0z",
    fill: "none"
  }), __cssprop("path", {
    d: "M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
  }));
};
export default Check;
