var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import { Link } from "@mui/material";
import styled from "styled-components";
export var Text = styled.p(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "fontSize": "12px",
  "lineHeight": "1rem",
  "fontWeight": "400",
  "--tw-text-opacity": "1",
  "color": "rgb(203 213 225 / var(--tw-text-opacity))",
  "@media (min-width: 768px)": {
    "fontSize": "14px",
    "lineHeight": "20px",
    "fontWeight": "500"
  }
});
export var FooterDiv = styled.footer(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "marginTop": "2.5rem",
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "alignItems": "center",
  "@media (min-width: 640px)": {
    "justifyContent": "space-between"
  },
  "@media (min-width: 768px)": {
    "flexDirection": "row",
    "alignItems": "flex-start"
  }
});
export var SocialMediaDiv = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "marginBottom": "1.25rem",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.75rem",
  "@media (min-width: 768px)": {
    "marginBottom": "0px"
  }
});
export var LinksSection = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.5rem",
  "@media (min-width: 768px)": {
    "alignItems": "flex-end"
  }
});
export var Links = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "1rem"
});
export var StyledLink = styled(Link)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "transitionProperty": "transform",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  ":hover": {
    "--tw-translate-y": "-0.25rem",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  }
});
