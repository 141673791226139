import { MetamaskIcon } from "assets/icons";
import { Button } from "flowbite-components/buttons";
import { useAuth } from "hooks";
import useWallet from "hooks/useWallet";
import { AddressString, AddressWrapper, Container } from "./style";
import { jsx as __cssprop } from "@emotion/react";
var WalletAddress = function WalletAddress2() {
  var _useWallet = useWallet(), user = _useWallet.user;
  var _useAuth = useAuth(), handleLogout = _useAuth.handleLogout;
  var address = user === null || user === void 0 ? void 0 : user.wallet;
  return __cssprop(Container, null, __cssprop(AddressWrapper, null, __cssprop(MetamaskIcon, null), __cssprop(AddressString, null, address ? address.slice(0, 4) + "..." + address.slice(-4) : "")), __cssprop(Button, {
    label: "Disconnect",
    variant: "secondary",
    onClick: handleLogout
  }));
};
export default WalletAddress;
