import { jsx as __cssprop } from "@emotion/react";
var ArrowRight = function ArrowRight2(_ref) {
  var _ref$w = _ref.w, w = _ref$w === void 0 ? "33px" : _ref$w, _ref$h = _ref.h, h = _ref$h === void 0 ? "33px" : _ref$h;
  return __cssprop("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: w,
    height: h,
    viewBox: "0 0 33 33",
    fill: "none"
  }, __cssprop("path", {
    d: "M20.6284 16.4395C20.6284 16.209 20.5372 15.9971 20.3641 15.8312L13.1458 8.67908C12.9817 8.5224 12.7812 8.43945 12.5442 8.43945C12.0794 8.43945 11.7148 8.7989 11.7148 9.27816C11.7148 9.50858 11.806 9.72056 11.9518 9.87724L18.5868 16.4395L11.9518 23.0017C11.806 23.1583 11.7148 23.3611 11.7148 23.6007C11.7148 24.08 12.0794 24.4395 12.5442 24.4395C12.7812 24.4395 12.9817 24.3565 13.1458 24.1906L20.3641 17.0477C20.5372 16.8726 20.6284 16.6699 20.6284 16.4395Z",
    fill: "#B5B5B5"
  }));
};
export default ArrowRight;
