export var fontSize = {
  title: "60px",
  h1: "40px",
  h2: "24px",
  h3: "20px",
  h4: "18px",
  p: "16px",
  label: "14px",
  labelBold: "14px",
  full: {
    title: "60px",
    h1: "40px",
    h2: "20px",
    h3: "20px",
    h4: "18px",
    p: "18px",
    label: "14px",
    labelBold: "14px"
  },
  notebook: {
    title: "50px",
    h1: "40px",
    h2: "20px",
    h3: "20px",
    h4: "18px",
    p: "16px",
    label: "14px",
    labelBold: "14px"
  }
};
