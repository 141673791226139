import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  token: "",
  provider: null,
  isLoggedIn: false,
  address: "",
  did: "",
  signer: null,
  showKycAndPolygon: false,
  kycStatus: {
    status: "",
    message: ""
  },
  polygonStatus: {
    status: "",
    message: ""
  }
};
var sdkManager = createSlice({
  name: "SDK_MANAGER",
  initialState,
  reducers: {
    setSdkManagerData: function setSdkManagerData(state, _ref) {
      var payload = _ref.payload;
      state.isLoggedIn = payload.isLoggedIn;
      state.provider = payload.provider;
      state.address = payload.address;
      state.did = payload.did;
      state.signer = payload.signer;
      state.token = payload.token;
    },
    setKycStatus: function setKycStatus(state, action) {
      state.kycStatus = action.payload;
    },
    setPolygonStatus: function setPolygonStatus(state, action) {
      state.polygonStatus = action.payload;
    },
    setShowScreenSteps: function setShowScreenSteps(state, action) {
      state.showKycAndPolygon = action.payload;
    }
  }
});
var _sdkManager$actions = sdkManager.actions, setSdkManagerData2 = _sdkManager$actions.setSdkManagerData, setKycStatus2 = _sdkManager$actions.setKycStatus, setPolygonStatus2 = _sdkManager$actions.setPolygonStatus, setShowScreenSteps2 = _sdkManager$actions.setShowScreenSteps;
export { setSdkManagerData2 as setSdkManagerData, setKycStatus2 as setKycStatus, setPolygonStatus2 as setPolygonStatus, setShowScreenSteps2 as setShowScreenSteps };
export default sdkManager.reducer;
