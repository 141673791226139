var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import styled from "styled-components";
export var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "gap": "0.75rem",
  "@media (min-width: 768px)": {
    "flexDirection": "row"
  }
});
export var AddressWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex",
  "height": "20px",
  "alignItems": "center",
  "gap": "0.75rem"
});
export var AddressString = styled.p(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "lineHeight": "22px",
  "--tw-text-opacity": "1",
  "color": "rgb(203 213 225 / var(--tw-text-opacity))"
});
export var ButtonText = styled.p(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "fontWeight": "600",
  "lineHeight": "24px"
});
