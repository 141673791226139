import { jsx as __cssprop } from "@emotion/react";
var AuthorizedUsersIcon = function AuthorizedUsersIcon2() {
  return __cssprop("svg", {
    width: "96",
    height: "98",
    viewBox: "0 0 96 98",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("ellipse", {
    cx: "48",
    cy: "48.6354",
    rx: "47.2542",
    ry: "48.61",
    fill: "#475569"
  }), __cssprop("circle", {
    cx: "76.283",
    cy: "87.304",
    r: "10.6711",
    fill: "#059669"
  }), __cssprop("g", {
    clipPath: "url(#clip0_5335_22018)"
  }, __cssprop("path", {
    d: "M32.8796 63.4154H63.6196V58.7654C63.6196 58.7654 55.8696 52.9254 52.1796 51.8554C52.1796 51.8554 57.1796 46.4954 57.1796 38.6254C57.1796 30.7554 51.4696 28.0254 48.2496 28.0254C44.4596 28.0254 39.1896 30.7654 39.1896 38.6354C39.1896 46.5054 44.4296 51.8654 44.4296 51.8654C41.3596 52.9354 32.8796 58.7754 32.8796 58.7754V63.4254V63.4154Z",
    fill: "#64A6E7"
  }), __cssprop("path", {
    opacity: "0.44",
    d: "M30.6398 61.4554H23.9998V58.7654C23.9998 58.7654 28.7298 54.2354 31.1798 53.9654V50.0454C31.1798 50.0454 28.5698 48.7154 28.5698 42.9254C28.5698 34.2754 37.4298 35.4054 37.4298 35.4054C37.4298 35.4054 34.4998 42.0255 39.8198 48.8754L38.0998 49.9954V53.0255L30.6398 57.9554V61.4454V61.4554Z",
    fill: "white"
  }), __cssprop("path", {
    opacity: "0.44",
    d: "M65.9498 61.4554H72.5898V58.7654C72.5898 58.7654 67.8598 54.2354 65.4098 53.9654V50.0454C65.4098 50.0454 68.0198 48.7154 68.0198 42.9254C68.0198 34.2754 59.1598 35.4054 59.1598 35.4054C59.1598 35.4054 63.0002 42.9254 56.7698 48.8754L58.4898 49.9954V53.0255L65.9498 57.9554V61.4454V61.4554Z",
    fill: "white"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_5335_22018"
  }, __cssprop("rect", {
    width: "48.59",
    height: "35.39",
    fill: "white",
    transform: "translate(23.9998 28.0254)"
  }))));
};
export default AuthorizedUsersIcon;
