var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import styled from "styled-components";
export var ButtonWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  ", "\n"])), function(_ref) {
  var transparent = _ref.transparent;
  return !transparent && {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(71 85 105 / var(--tw-bg-opacity))",
    ":hover": {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(100 116 139 / var(--tw-bg-opacity))"
    }
  };
}, {
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "borderRadius": "0.25rem",
  "padding": "0.5rem",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "100ms"
});
export var AnimationWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "center",
  "transitionProperty": "transform",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "300ms"
});
