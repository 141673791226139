function _objectDestructuringEmpty(obj) {
  if (obj == null)
    throw new TypeError("Cannot destructure " + obj);
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
import { jsx as __cssprop } from "@emotion/react";
var MetamaskIcon = function MetamaskIcon2(_ref) {
  var props = _extends({}, (_objectDestructuringEmpty(_ref), _ref));
  return __cssprop("svg", _extends({}, props, {
    width: "40",
    height: "40",
    viewBox: "0 0 40 40",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }), __cssprop("path", {
    d: "M34.4131 4.45699L21.9209 13.7351L24.231 8.26114L34.4131 4.45699Z",
    fill: "#E2761B",
    stroke: "#E2761B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M5.57434 4.45702L17.9661 13.823L15.7689 8.26116L5.57434 4.45702Z",
    fill: "#E4761B",
    stroke: "#E4761B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M29.9184 25.9636L26.5913 31.0609L33.71 33.0194L35.7564 26.0766L29.9184 25.9636Z",
    fill: "#E4761B",
    stroke: "#E4761B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M4.2561 26.0766L6.29 33.0194L13.4086 31.0609L10.0816 25.9636L4.2561 26.0766Z",
    fill: "#E4761B",
    stroke: "#E4761B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M13.0069 17.3509L11.0232 20.3515L18.0916 20.6654L17.8405 13.0697L13.0069 17.3509Z",
    fill: "#E4761B",
    stroke: "#E4761B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M26.9805 17.3509L22.0841 12.9818L21.9209 20.6654L28.9768 20.3515L26.9805 17.3509Z",
    fill: "#E4761B",
    stroke: "#E4761B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M13.4086 31.0609L17.6521 28.9894L13.9861 26.1268L13.4086 31.0609Z",
    fill: "#E4761B",
    stroke: "#E4761B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M22.3352 28.9894L26.5913 31.0609L26.0012 26.1268L22.3352 28.9894Z",
    fill: "#E4761B",
    stroke: "#E4761B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M26.5913 31.0609L22.3352 28.9893L22.6742 31.764L22.6365 32.9316L26.5913 31.0609Z",
    fill: "#D7C1B3",
    stroke: "#D7C1B3",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M13.4087 31.0609L17.3635 32.9316L17.3384 31.764L17.6523 28.9894L13.4087 31.0609Z",
    fill: "#D7C1B3",
    stroke: "#D7C1B3",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M17.4262 24.2937L13.8857 23.2517L16.3842 22.1092L17.4262 24.2937Z",
    fill: "#233447",
    stroke: "#233447",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M22.5612 24.2937L23.6032 22.1092L26.1142 23.2517L22.5612 24.2937Z",
    fill: "#233447",
    stroke: "#233447",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M13.4087 31.0609L14.0114 25.9636L10.0817 26.0766L13.4087 31.0609Z",
    fill: "#CD6116",
    stroke: "#CD6116",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M25.9888 25.9636L26.5914 31.0609L29.9185 26.0766L25.9888 25.9636Z",
    fill: "#CD6116",
    stroke: "#CD6116",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M28.9769 20.3516L21.921 20.6654L22.5739 24.2938L23.6159 22.1093L26.1269 23.2517L28.9769 20.3516Z",
    fill: "#CD6116",
    stroke: "#CD6116",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M13.8858 23.2517L16.3968 22.1093L17.4263 24.2938L18.0917 20.6654L11.0233 20.3516L13.8858 23.2517Z",
    fill: "#CD6116",
    stroke: "#CD6116",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M11.0232 20.3516L13.9862 26.1268L13.8857 23.2517L11.0232 20.3516Z",
    fill: "#E4751F",
    stroke: "#E4751F",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M26.1268 23.2517L26.0012 26.1268L28.9767 20.3516L26.1268 23.2517Z",
    fill: "#E4751F",
    stroke: "#E4751F",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M18.0916 20.6655L17.4261 24.2939L18.2548 28.5751L18.4431 22.9379L18.0916 20.6655Z",
    fill: "#E4751F",
    stroke: "#E4751F",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M21.9209 20.6655L21.5819 22.9254L21.7326 28.5751L22.5737 24.2939L21.9209 20.6655Z",
    fill: "#E4751F",
    stroke: "#E4751F",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M22.5737 24.2938L21.7325 28.575L22.3352 28.9893L26.0012 26.1268L26.1268 23.2517L22.5737 24.2938Z",
    fill: "#F6851B",
    stroke: "#F6851B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M13.8857 23.2517L13.9862 26.1268L17.6522 28.9893L18.2549 28.575L17.4262 24.2938L13.8857 23.2517Z",
    fill: "#F6851B",
    stroke: "#F6851B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M22.6366 32.9316L22.6742 31.764L22.3604 31.4878H17.6271L17.3384 31.764L17.3635 32.9316L13.4087 31.0609L14.7897 32.1908L17.5895 34.1368H22.398L25.2103 32.1908L26.5914 31.0609L22.6366 32.9316Z",
    fill: "#C0AD9E",
    stroke: "#C0AD9E",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M22.3352 28.9893L21.7326 28.575H18.2549L17.6523 28.9893L17.3384 31.764L17.6271 31.4878H22.3603L22.6742 31.764L22.3352 28.9893Z",
    fill: "#161616",
    stroke: "#161616",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M34.9404 14.3377L36.0075 9.21533L34.413 4.45702L22.3352 13.4212L26.9805 17.3509L33.5468 19.2718L35.0031 17.5769L34.3754 17.1249L35.3798 16.2084L34.6014 15.6058L35.6058 14.8399L34.9404 14.3377Z",
    fill: "#763D16",
    stroke: "#763D16",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M3.99243 9.21531L5.0596 14.3377L4.38163 14.8399L5.38603 15.6058L4.62018 16.2084L5.62457 17.1249L4.99683 17.5769L6.44064 19.2718L13.0069 17.3509L17.6522 13.4212L5.57435 4.45699L3.99243 9.21531Z",
    fill: "#763D16",
    stroke: "#763D16",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M33.5467 19.2718L26.9805 17.3509L28.9767 20.3515L26.0012 26.1268L29.9184 26.0765H35.7564L33.5467 19.2718Z",
    fill: "#F6851B",
    stroke: "#F6851B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M13.0069 17.3509L6.44066 19.2718L4.2561 26.0765H10.0816L13.9862 26.1268L11.0232 20.3515L13.0069 17.3509Z",
    fill: "#F6851B",
    stroke: "#F6851B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M21.9208 20.6654L22.3351 13.4212L24.2435 8.26116H15.7689L17.6522 13.4212L18.0916 20.6654L18.2422 22.9504L18.2548 28.575H21.7325L21.7576 22.9504L21.9208 20.6654Z",
    fill: "#F6851B",
    stroke: "#F6851B",
    strokeWidth: "0.125549",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
};
export default MetamaskIcon;
