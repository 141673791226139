import { jsx as __cssprop } from "@emotion/react";
var WalletIcon = function WalletIcon2() {
  return __cssprop("svg", {
    width: "44",
    height: "48",
    viewBox: "0 0 44 48",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("path", {
    d: "M26.9153 4.08233C26.9645 4.02044 27.0258 3.9692 27.0954 3.93173C27.165 3.89426 27.2415 3.87134 27.3203 3.86436C27.399 3.85738 27.4784 3.86648 27.5535 3.89113C27.6286 3.91577 27.6979 3.95543 27.7572 4.0077L29.9051 5.90139L23.1443 14.3554H27.6243L32.5358 8.2172L36.3885 11.6151C36.5024 11.7158 36.5725 11.857 36.5838 12.0086C36.5951 12.1602 36.5468 12.3102 36.4491 12.4267L34.8399 14.3554H39.3736C39.9352 13.5332 40.1769 12.5342 40.0532 11.5463C39.9295 10.5584 39.4489 9.64974 38.7019 8.99147L30.073 1.38172C29.6568 1.01512 29.1701 0.737215 28.6429 0.56499C28.1156 0.392765 27.5587 0.32983 27.0063 0.380029C26.4539 0.430229 25.9175 0.592509 25.4299 0.856958C24.9424 1.12141 24.5138 1.48248 24.1704 1.91811L14.3685 14.3554H18.8228L26.9153 4.08233ZM5.67894 13.1893C5.21505 13.1893 4.77016 13.3736 4.44214 13.7016C4.11412 14.0296 3.92984 14.4745 3.92984 14.9384C3.92984 15.4023 4.11412 15.8472 4.44214 16.1752C4.77016 16.5032 5.21505 16.6875 5.67894 16.6875H35.9967C38.0069 16.6875 39.9347 17.4861 41.3561 18.9075C42.7775 20.3289 43.5761 22.2567 43.5761 24.2669V39.4258C43.5761 41.436 42.7775 43.3638 41.3561 44.7853C39.9347 46.2067 38.0069 47.0052 35.9967 47.0052H8.01107C6.00088 47.0052 4.07302 46.2067 2.6516 44.7853C1.23019 43.3638 0.431641 41.436 0.431641 39.4258V14.9384C0.431641 13.5467 0.984479 12.2121 1.96854 11.228C2.9526 10.2439 4.28727 9.69111 5.67894 9.69111H15.7538L12.9739 13.1893H5.67894ZM29.5833 32.4294C29.5833 33.3949 30.3669 34.1785 31.3324 34.1785H35.9967C36.4606 34.1785 36.9054 33.9942 37.2335 33.6662C37.5615 33.3382 37.7458 32.8933 37.7458 32.4294C37.7458 31.9655 37.5615 31.5206 37.2335 31.1926C36.9054 30.8646 36.4606 30.6803 35.9967 30.6803H31.3324C30.8685 30.6803 30.4236 30.8646 30.0956 31.1926C29.7676 31.5206 29.5833 31.9655 29.5833 32.4294Z",
    fill: "#10B981"
  }));
};
export default WalletIcon;
