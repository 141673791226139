import { createSlice } from "@reduxjs/toolkit";
import { APP_VERSION } from "utils/constants";
var initialState = {
  isLoggedIn: false,
  isVerified: false,
  isConnected: false,
  appIndex: -1,
  appVersion: APP_VERSION
};
var generalReducer = createSlice({
  name: "GENERAL_DATA",
  initialState,
  reducers: {
    setGeneralState: function setGeneralState(state, _ref) {
      var _payload$isLoggedIn, _payload$isVerified, _payload$isConnected, _payload$appIndex;
      var payload = _ref.payload;
      state.isLoggedIn = (_payload$isLoggedIn = payload.isLoggedIn) !== null && _payload$isLoggedIn !== void 0 ? _payload$isLoggedIn : state.isLoggedIn;
      state.isVerified = (_payload$isVerified = payload.isVerified) !== null && _payload$isVerified !== void 0 ? _payload$isVerified : state.isVerified;
      state.isConnected = (_payload$isConnected = payload.isConnected) !== null && _payload$isConnected !== void 0 ? _payload$isConnected : state.isConnected;
      state.appIndex = (_payload$appIndex = payload.appIndex) !== null && _payload$appIndex !== void 0 ? _payload$appIndex : state.appIndex;
    },
    resetGeneralState: function resetGeneralState(state) {
      state.isLoggedIn = false;
      state.isVerified = false;
      state.isConnected = false;
      state.appIndex = -1;
      state.appVersion = APP_VERSION;
    }
  }
});
var _generalReducer$actio = generalReducer.actions, setGeneralState2 = _generalReducer$actio.setGeneralState, resetGeneralState2 = _generalReducer$actio.resetGeneralState;
export { setGeneralState2 as setGeneralState, resetGeneralState2 as resetGeneralState };
export default generalReducer.reducer;
