var _initialChecksState;
function _typeof(obj) {
  "@babel/helpers - typeof";
  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(obj2) {
    return typeof obj2;
  } : function(obj2) {
    return obj2 && "function" == typeof Symbol && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
  }, _typeof(obj);
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, { value, enumerable: true, configurable: true, writable: true });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return _typeof(key) === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== "object" || input === null)
    return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== void 0) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
      return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
import { createSlice } from "@reduxjs/toolkit";
import { TabsTypes } from "screens/Playground/types";
import { CHECK_TYPES, HttpMethods } from "utils/constants";
export var defaultApiCheckBody = '// Here you can write you query.\n// UserWalletAddress is a reserved word who contain the user address\n// Example:\n// {\n// submissions( where: {requests_: {requester: "UserWalletAddress"}}) { id }\n// }\n';
export var initialChecksState = (_initialChecksState = {}, _defineProperty(_initialChecksState, CHECK_TYPES.NFT, []), _defineProperty(_initialChecksState, CHECK_TYPES.geoId, []), _defineProperty(_initialChecksState, CHECK_TYPES.creditScore, []), _defineProperty(_initialChecksState, CHECK_TYPES.API, {
  method: HttpMethods.GET,
  url: "",
  evaluateExpression: '// "response" represents the data returned from the API.\n// Complete the expression below to return a boolean value.\n// Example: Object.keys(response).length && response[0].name === "John";',
  body: ""
}), _defineProperty(_initialChecksState, CHECK_TYPES.Polygon, {
  schemaUrl: "",
  schemaType: "",
  active: false,
  attributes: [],
  fields: []
}), _defineProperty(_initialChecksState, CHECK_TYPES.KYC, ["Fractal", "PureFi"]), _initialChecksState);
var initialState = {
  selectedTab: TabsTypes.AccessControl,
  polygonClaimSuccess: null,
  previewUrl: "",
  hideModal: false,
  checksData: initialChecksState,
  savedSteps: [],
  selectedChecks: {},
  openChecks: {},
  kycProcessComplete: false,
  showSelectChecks: false,
  checksToPreview: [],
  response: {
    polygonRequest: null,
    checksResult: null
  }
};
var playgroundReducer = createSlice({
  name: "PLAYGROUND",
  initialState,
  reducers: {
    setSelectedTab: function setSelectedTab(state, _ref) {
      var payload = _ref.payload;
      state.selectedTab = payload;
    },
    setShowSelectChecks: function setShowSelectChecks(state, _ref2) {
      var payload = _ref2.payload;
      state.showSelectChecks = payload;
    },
    setChecksToPreview: function setChecksToPreview(state, _ref3) {
      var payload = _ref3.payload;
      state.checksToPreview = payload;
    },
    setPlaygroundResponse: function setPlaygroundResponse(state, _ref4) {
      var payload = _ref4.payload;
      state.response = payload;
    },
    setPolygonClaimStatus: function setPolygonClaimStatus(state, _ref5) {
      var payload = _ref5.payload;
      state.polygonClaimSuccess = payload;
    },
    setSavedSteps: function setSavedSteps(state, _ref6) {
      var payload = _ref6.payload;
      state.savedSteps = payload;
    },
    setHideModal: function setHideModal(state, _ref7) {
      var payload = _ref7.payload;
      state.hideModal = payload;
    },
    setSelectedChecks: function setSelectedChecks(state, _ref8) {
      var payload = _ref8.payload;
      state.selectedChecks = payload;
    },
    setOpenChecks: function setOpenChecks(state, _ref9) {
      var payload = _ref9.payload;
      state.openChecks = payload;
    },
    resetSavedSteps: function resetSavedSteps(state) {
      state.savedSteps = [];
    },
    setKycProcessComplete: function setKycProcessComplete(state, _ref10) {
      var payload = _ref10.payload;
      state.kycProcessComplete = payload;
    },
    setChecksData: function setChecksData(state, _ref11) {
      var payload = _ref11.payload;
      var reset = payload.reset;
      if (reset) {
        state.checksData = initialChecksState;
        return;
      }
      state.checksData = payload;
    },
    setPreviewUrl: function setPreviewUrl(state, _ref12) {
      var payload = _ref12.payload;
      state.previewUrl = payload;
    },
    resetPlaygroundResponse: function resetPlaygroundResponse(state) {
      state.response = {
        polygonRequest: null,
        checksResult: null
      };
    },
    resetPlayground: function resetPlayground(state) {
      state.selectedTab = TabsTypes.AccessControl;
      state.polygonClaimSuccess = null;
      state.previewUrl = "";
      state.hideModal = false;
      state.checksData = initialChecksState;
      state.savedSteps = [];
      state.selectedChecks = {};
      state.openChecks = {};
      state.kycProcessComplete = false;
      state.showSelectChecks = false;
      state.checksToPreview = [];
      state.response = {
        polygonRequest: null,
        checksResult: null
      };
    }
  }
});
var _playgroundReducer$ac = playgroundReducer.actions, setSelectedTab2 = _playgroundReducer$ac.setSelectedTab, setShowSelectChecks2 = _playgroundReducer$ac.setShowSelectChecks, setChecksToPreview2 = _playgroundReducer$ac.setChecksToPreview, setPlaygroundResponse2 = _playgroundReducer$ac.setPlaygroundResponse, resetSavedSteps2 = _playgroundReducer$ac.resetSavedSteps, setKycProcessComplete2 = _playgroundReducer$ac.setKycProcessComplete, setPreviewUrl2 = _playgroundReducer$ac.setPreviewUrl, setHideModal2 = _playgroundReducer$ac.setHideModal, setOpenChecks2 = _playgroundReducer$ac.setOpenChecks, setSelectedChecks2 = _playgroundReducer$ac.setSelectedChecks, setSavedSteps2 = _playgroundReducer$ac.setSavedSteps, setChecksData2 = _playgroundReducer$ac.setChecksData, setPolygonClaimStatus2 = _playgroundReducer$ac.setPolygonClaimStatus, resetPlaygroundResponse2 = _playgroundReducer$ac.resetPlaygroundResponse, resetPlayground2 = _playgroundReducer$ac.resetPlayground;
export { setSelectedTab2 as setSelectedTab, setShowSelectChecks2 as setShowSelectChecks, setChecksToPreview2 as setChecksToPreview, setPlaygroundResponse2 as setPlaygroundResponse, resetSavedSteps2 as resetSavedSteps, setKycProcessComplete2 as setKycProcessComplete, setPreviewUrl2 as setPreviewUrl, setHideModal2 as setHideModal, setOpenChecks2 as setOpenChecks, setSelectedChecks2 as setSelectedChecks, setSavedSteps2 as setSavedSteps, setChecksData2 as setChecksData, setPolygonClaimStatus2 as setPolygonClaimStatus, resetPlaygroundResponse2 as resetPlaygroundResponse, resetPlayground2 as resetPlayground };
export default playgroundReducer.reducer;
