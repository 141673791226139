export var TabsTypes;
(function(TabsTypes2) {
  TabsTypes2[TabsTypes2["AccessControl"] = 0] = "AccessControl";
  TabsTypes2[TabsTypes2["Customization"] = 1] = "Customization";
})(TabsTypes || (TabsTypes = {}));
export var IframeChildType;
(function(IframeChildType2) {
  IframeChildType2["VERIFICATION_PROCESS"] = "VERIFICATION_PROCESS";
  IframeChildType2["ACCESS_CONTROL_PREVIEW"] = "ACCESS_CONTROL_PREVIEW";
})(IframeChildType || (IframeChildType = {}));
