function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o)
    return;
  if (typeof o === "string")
    return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor)
    n = o.constructor.name;
  if (n === "Map" || n === "Set")
    return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length)
    len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i];
  return arr2;
}
function _iterableToArrayLimit(arr, i) {
  var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"];
  if (null != _i) {
    var _s, _e, _x, _r, _arr = [], _n = true, _d = false;
    try {
      if (_x = (_i = _i.call(arr)).next, 0 === i) {
        if (Object(_i) !== _i)
          return;
        _n = false;
      } else
        for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = true)
          ;
    } catch (err) {
      _d = true, _e = err;
    } finally {
      try {
        if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r))
          return;
      } finally {
        if (_d)
          throw _e;
      }
    }
    return _arr;
  }
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr))
    return arr;
}
import { IFrameContext } from "context/IframeContext";
import { useContext, useState } from "react";
export var ColorContextTypes;
(function(ColorContextTypes2) {
  ColorContextTypes2["backgroundColor"] = "Background color";
  ColorContextTypes2["textColor"] = "Text color";
  ColorContextTypes2["primaryColor"] = "Primary color";
  ColorContextTypes2["buttonTextColor"] = "Button text color";
})(ColorContextTypes || (ColorContextTypes = {}));
var useContextColors = function useContextColors2() {
  var context = useContext(IFrameContext);
  var _useState = useState(context.bgColor), _useState2 = _slicedToArray(_useState, 2), backgroundColor = _useState2[0], setBackgroundColor = _useState2[1];
  var _useState3 = useState(context.textColor), _useState4 = _slicedToArray(_useState3, 2), textColor = _useState4[0], setTextColor = _useState4[1];
  var _useState5 = useState(context.primaryColor), _useState6 = _slicedToArray(_useState5, 2), primaryColor = _useState6[0], setPrimaryColor = _useState6[1];
  var _useState7 = useState(context.buttonTextColor), _useState8 = _slicedToArray(_useState7, 2), buttonTextColor = _useState8[0], setButtonTextColor = _useState8[1];
  var handleApplyColors = function handleApplyColors2(type, color) {
    if (type && color) {
      switch (type) {
        case ColorContextTypes.backgroundColor:
          return context.setBgColor(color);
        case ColorContextTypes.textColor:
          return context.setTextColor(color);
        case ColorContextTypes.primaryColor:
          return context.setPrimaryColor(color);
        case ColorContextTypes.buttonTextColor:
          return context.setButtonTextColor(color);
        default:
          return;
      }
    }
    context.setBgColor(backgroundColor);
    context.setButtonTextColor(buttonTextColor);
    context.setTextColor(textColor);
    context.setPrimaryColor(primaryColor);
  };
  var setters = {
    setBackgroundColor,
    setTextColor,
    setPrimaryColor,
    setButtonTextColor,
    handleApplyColors
  };
  var values = {
    bgColor: backgroundColor,
    textColor,
    primaryColor,
    buttonTextColor
  };
  return {
    setters,
    values
  };
};
export default useContextColors;
