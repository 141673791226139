export var tutorialsCommonProps = {
  animate: true,
  stagePadding: 0,
  nextBtnText: "Next",
  prevBtnText: "Previous",
  showButtons: ["next", "previous", "close"],
  popoverClass: "driverjs-theme"
};
export var onButtonClick = function onButtonClick2(buttonId) {
  var button = document.getElementById(buttonId);
  if (button)
    button.click();
};
