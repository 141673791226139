import { jsx as __cssprop } from "@emotion/react";
var ErrorIcon = function ErrorIcon2() {
  return __cssprop("svg", {
    width: "45",
    height: "44",
    viewBox: "0 0 45 44",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("rect", {
    x: "0.5",
    width: "44",
    height: "44",
    rx: "5",
    fill: "#2A3446"
  }), __cssprop("g", {
    clipPath: "url(#clip0_6403_28866)"
  }, __cssprop("path", {
    d: "M22.5 11C16.1502 11 11 16.1502 11 22.5C11 28.8498 16.1502 34 22.5 34C28.8498 34 34 28.8498 34 22.5C34 16.1502 28.8561 11 22.5 11ZM28.7744 25.9984C29.5407 26.7646 29.5407 28.0082 28.7744 28.7807C28.3913 29.1638 27.8889 29.3585 27.3864 29.3585C26.8839 29.3585 26.3815 29.1638 25.9984 28.7807L22.5063 25.2886L19.0142 28.7807C18.6311 29.1638 18.1286 29.3585 17.6262 29.3585C17.1237 29.3585 16.6212 29.1638 16.2381 28.7807C15.4719 28.0145 15.4719 26.7709 16.2381 25.9984L19.7302 22.5063L16.2381 19.0142C15.4719 18.248 15.4719 17.0044 16.2381 16.2318C17.0044 15.4656 18.248 15.4656 19.0205 16.2318L22.5126 19.7239L26.0046 16.2318C26.7709 15.4656 28.0145 15.4656 28.787 16.2318C29.5532 16.9981 29.5532 18.2417 28.787 19.0142L25.2949 22.5063L28.787 25.9984H28.7744Z",
    fill: "#E11D48"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_6403_28866"
  }, __cssprop("rect", {
    width: "28",
    height: "27",
    fill: "white",
    transform: "translate(8 8.5)"
  }))));
};
export default ErrorIcon;
