import { useMediaQuery } from "@mui/material";
import { TAILWIND_RESOLUTIONS } from "../utils/constants";
var useTailwindResolutionWithMui = function useTailwindResolutionWithMui2() {
  var mx = useMediaQuery("(min-width: 1920px)");
  var _2xl = useMediaQuery("(min-width: 1536px) and (max-width: 1919px)");
  var xl = useMediaQuery("(min-width: 1280px) and (max-width: 1535px)");
  var lg = useMediaQuery("(min-width: 1024px) and (max-width: 1279px)");
  var md = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  var sm = useMediaQuery("(min-width: 640px) and (max-width: 767px)");
  var xs = useMediaQuery("(max-width: 639px)");
  var maxWidth = TAILWIND_RESOLUTIONS.mx;
  switch (true) {
    case mx:
      maxWidth = TAILWIND_RESOLUTIONS.mx;
      break;
    case _2xl:
      maxWidth = TAILWIND_RESOLUTIONS._2xl;
      break;
    case xl:
      maxWidth = TAILWIND_RESOLUTIONS.xl;
      break;
    case lg:
      maxWidth = TAILWIND_RESOLUTIONS.lg;
      break;
    case md:
      maxWidth = TAILWIND_RESOLUTIONS.md;
      break;
    case sm:
      maxWidth = TAILWIND_RESOLUTIONS.sm;
      break;
    case xs:
      maxWidth = TAILWIND_RESOLUTIONS.xs;
      break;
    default:
      maxWidth = TAILWIND_RESOLUTIONS.mx;
      break;
  }
  return {
    maxWidth
  };
};
export default useTailwindResolutionWithMui;
