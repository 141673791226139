import { combineReducers } from "redux";
import tutorials from "store/tutorials";
import generalReducer from "../general";
import issueVcReducer from "../issueVc";
import personalDetails from "../personalDetails";
import playgroundReducer from "../playground";
import rolesReducer from "../roles";
import sdkManagerReducer from "../sdkManager";
import sessionReducer from "../sessionDetails";
import claimVcReducer from "../vcClaim";
import vcsReducer from "../vcs";
import verificationScreenIndex from "../verificationWorkflow";
import walletDetailsReducer from "../walletDetails";
var reducers = combineReducers({
  screenIndex: verificationScreenIndex,
  personalDetails,
  walletDetails: walletDetailsReducer,
  sessionData: sessionReducer,
  generalState: generalReducer,
  playground: playgroundReducer,
  roles: rolesReducer,
  issueVc: issueVcReducer,
  claimVC: claimVcReducer,
  sdkManager: sdkManagerReducer,
  vcs: vcsReducer,
  tutorials
});
export default reducers;
