function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o)
    return;
  if (typeof o === "string")
    return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor)
    n = o.constructor.name;
  if (n === "Map" || n === "Set")
    return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}
function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null)
    return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr))
    return _arrayLikeToArray(arr);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length)
    len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i];
  return arr2;
}
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  user: null,
  token: "",
  isExpired: false
};
var sessionReducer = createSlice({
  name: "SESSION_DETAILS",
  initialState,
  reducers: {
    setSessionData: function setSessionData(state, _ref) {
      var payload = _ref.payload;
      state.user = payload.user;
      state.token = payload.token;
      state.isExpired = false;
    },
    addNewApp: function addNewApp(state, _ref2) {
      var payload = _ref2.payload;
      if (payload.index !== void 0) {
        var _state$user$apps, _state$user;
        var appsClone = _toConsumableArray((_state$user$apps = (_state$user = state.user) === null || _state$user === void 0 ? void 0 : _state$user.apps) !== null && _state$user$apps !== void 0 ? _state$user$apps : []);
        appsClone[payload.index] = payload.app;
        state.user.apps = appsClone;
      } else {
        var _state$user2;
        (_state$user2 = state.user) === null || _state$user2 === void 0 ? void 0 : _state$user2.apps.push(payload.app);
      }
    },
    resetSessionData: function resetSessionData(state) {
      state.user = null;
      state.token = "";
    },
    setSessionExpired: function setSessionExpired(state, _ref3) {
      var payload = _ref3.payload;
      state.isExpired = Boolean(payload);
    }
  }
});
var _sessionReducer$actio = sessionReducer.actions, setSessionData2 = _sessionReducer$actio.setSessionData, addNewApp2 = _sessionReducer$actio.addNewApp, resetSessionData2 = _sessionReducer$actio.resetSessionData, setSessionExpired2 = _sessionReducer$actio.setSessionExpired;
export { setSessionData2 as setSessionData, addNewApp2 as addNewApp, resetSessionData2 as resetSessionData, setSessionExpired2 as setSessionExpired };
export default sessionReducer.reducer;
