import { jsx as __cssprop } from "@emotion/react";
var SkillsIcon = function SkillsIcon2() {
  return __cssprop("svg", {
    width: "51",
    height: "53",
    viewBox: "0 0 51 53",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_4947_22915)"
  }, __cssprop("ellipse", {
    cx: "25.7393",
    cy: "27.5598",
    rx: "6.90144",
    ry: "6.90407",
    fill: "white"
  }), __cssprop("path", {
    d: "M34.8442 30.0449C35.1741 28.9479 37.648 28.1799 37.648 26.9732C37.648 25.7664 35.1741 24.9437 34.8442 23.9015C34.4594 22.7496 35.9987 20.6652 35.284 19.7327C34.5693 18.8002 32.1503 19.5681 31.1607 18.8551C30.2261 18.1968 30.2261 15.6188 29.0716 15.2348C27.972 14.9057 26.4876 16.9352 25.2781 16.9352C24.0686 16.9352 22.5293 14.8508 21.4847 15.2348C20.3302 15.6188 20.3302 18.1968 19.3955 18.8551C18.4609 19.5681 15.9869 18.7453 15.2722 19.7327C14.6125 20.6652 16.0969 22.7496 15.7121 23.9015C15.3822 24.9985 12.9082 25.7664 12.9082 26.9732C12.9082 28.1799 15.3822 29.0027 15.7121 30.0449C16.0969 31.1968 14.5575 33.2812 15.2722 34.2137C15.9869 35.1462 18.4059 34.3783 19.3955 35.0913C20.3302 35.7496 20.3302 38.3276 21.4847 38.7116C22.5842 39.0407 24.1236 37.0112 25.2781 37.0112C26.4326 37.0112 28.027 39.0956 29.0716 38.7116C30.2261 38.3276 30.2261 35.7496 31.1607 35.0913C32.0953 34.3783 34.5693 35.201 35.284 34.2137C35.9437 33.2812 34.4594 31.1968 34.8442 30.0449ZM29.8962 25.547L25.1132 30.3192C24.8933 30.5934 24.5634 30.7032 24.2336 30.7032C23.9037 30.7032 23.5738 30.5934 23.3539 30.3192L20.4951 27.4669C20.0003 26.9732 20.0003 26.1504 20.4951 25.6567C20.9899 25.1631 21.8145 25.1631 22.3093 25.6567L24.2336 27.5766L28.1369 23.6821C28.6317 23.1884 29.4564 23.1884 29.9512 23.6821C30.391 24.2854 30.391 25.0534 29.8962 25.547Z",
    fill: "#64A6E7"
  }), __cssprop("path", {
    d: "M24.3434 7.72004V12.8213C24.3434 13.315 24.7832 13.7538 25.278 13.7538C25.7728 13.7538 26.2126 13.315 26.2126 12.8213V7.72004C27.6421 7.33607 28.7416 5.96477 28.7416 4.4289C28.7416 2.50907 27.2022 0.973206 25.278 0.973206C23.3538 0.973206 21.8145 2.50907 21.8145 4.4289C21.8145 5.96477 22.859 7.28122 24.3434 7.72004V7.72004Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M13.182 11.9437L16.2058 16.1125C16.3707 16.3868 16.7006 16.4965 16.9754 16.4965C17.1404 16.4965 17.3603 16.4416 17.5252 16.3319C17.965 16.0028 18.02 15.4543 17.7451 15.0154L14.7214 10.8467C15.656 9.63992 15.7659 7.9395 14.8313 6.67789C13.7318 5.14203 11.5327 4.81291 9.99331 5.90996C8.45395 7.00701 8.12408 9.2011 9.22363 10.737C10.1033 11.9986 11.7526 12.4374 13.182 11.9437V11.9437Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M2.6817 23.298C4.16609 23.7917 5.76044 23.1883 6.64008 21.9267L11.5331 23.5174C11.643 23.5723 11.753 23.5723 11.808 23.5723C12.1928 23.5723 12.5776 23.298 12.6876 22.914C12.8525 22.4204 12.5776 21.8719 12.0828 21.7073L7.18985 20.1166C7.24483 18.6356 6.31021 17.1546 4.82582 16.7157C3.01157 16.1124 1.03238 17.0997 0.482608 18.9647C-0.178103 20.72 0.866468 22.6946 2.68072 23.298H2.6817Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M26.2126 46.2263V41.125C26.2126 40.6314 25.7728 40.1925 25.278 40.1925C24.7832 40.1925 24.3434 40.6314 24.3434 41.125V46.2263C22.914 46.6103 21.8145 47.9816 21.8145 49.5174C21.8145 51.4373 23.3538 52.9731 25.278 52.9731C27.2022 52.9731 28.7416 51.4373 28.7416 49.5174C28.7416 47.9816 27.697 46.6651 26.2126 46.2263Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M37.3718 42.0028L34.348 37.834C34.0181 37.3952 33.4684 37.3403 33.0286 37.6146C32.5887 37.9437 32.5338 38.4922 32.8086 38.9311L35.8324 43.0998C34.8978 44.3066 34.7878 46.007 35.7224 47.2686C36.822 48.8045 39.0211 49.1336 40.5605 48.0365C42.0998 46.9395 42.4297 44.7454 41.3301 43.2095C40.4505 41.9479 38.8012 41.5091 37.3718 42.0028V42.0028Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M47.8725 30.6484C46.3881 30.1547 44.7938 30.7581 43.9141 32.0197L39.0211 30.4289C38.5263 30.2644 37.9766 30.5386 37.8116 31.0323C37.6467 31.526 37.9216 32.0745 38.4164 32.2391L43.3094 33.8298C43.2544 35.3108 44.189 36.7918 45.6734 37.2306C47.4876 37.834 49.4668 36.8467 50.0166 34.9817C50.7323 33.2264 49.6867 31.2517 47.8725 30.6484V30.6484Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M12.0829 32.2391C12.5777 32.0745 12.8526 31.526 12.6877 31.0323C12.5227 30.5386 11.9729 30.2644 11.4782 30.4289L6.58516 32.0197C5.7605 30.7581 4.16615 30.1547 2.62679 30.6484C0.812532 31.2517 -0.177062 33.1716 0.42769 34.9817C1.03244 36.7918 2.95665 37.7792 4.77091 37.2306C6.2553 36.737 7.18991 35.3108 7.13493 33.8298L12.0829 32.2391V32.2391Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M16.1511 37.8889L13.1823 42.0028C11.7529 41.4543 10.1036 41.8931 9.16894 43.2096C8.06939 44.7454 8.39926 46.9395 9.93863 48.0366C11.478 49.1336 13.6771 48.8045 14.7766 47.2686C15.7113 46.007 15.6013 44.3066 14.6667 43.0998L17.6904 38.9311C18.0203 38.4923 17.9104 37.9437 17.4705 37.6146C17.0847 37.3404 16.48 37.4501 16.1501 37.8889H16.1511Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M38.473 21.7073C37.9782 21.8719 37.7033 22.4204 37.8683 22.9141C37.9782 23.2981 38.3631 23.5723 38.7479 23.5723C38.8579 23.5723 38.9678 23.5723 39.0228 23.5175L43.9158 21.9267C44.7404 23.1883 46.3348 23.7917 47.8742 23.2981C49.6884 22.6947 50.678 20.7748 50.0732 18.9647C49.4685 17.1546 47.5443 16.1673 45.73 16.7158C44.2456 17.2094 43.311 18.6356 43.366 20.1166L38.473 21.7073V21.7073Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M33.635 16.4416C33.9099 16.4416 34.1848 16.3319 34.4047 16.0576L37.4285 11.8888C38.8579 12.4373 40.5072 11.9985 41.4418 10.6821C42.5414 9.1462 42.2115 6.95211 40.6721 5.85506C39.1328 4.75801 36.9337 5.08713 35.8341 6.62299C34.8995 7.8846 35.0095 9.58502 35.9441 10.7918L32.9203 14.9605C32.5904 15.3994 32.7004 15.9479 33.1402 16.277C33.2502 16.3867 33.4151 16.4416 33.635 16.4416V16.4416Z",
    fill: "#059669"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_4947_22915"
  }, __cssprop("rect", {
    width: "50",
    height: "52",
    fill: "white",
    transform: "translate(0.25 0.973022)"
  }))));
};
export default SkillsIcon;
