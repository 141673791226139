import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
var useIsMobile = function useIsMobile2() {
  var size = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "sm";
  var theme = useTheme();
  var isMobile = useMediaQuery(theme.breakpoints.down(size));
  return {
    isMobile
  };
};
export default useIsMobile;
