var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import styled from "styled-components";
export var ButtonWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var StatusWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex"
});
export var ConnectedText = styled.p(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "marginLeft": "15px",
  "--tw-text-opacity": "1",
  "color": "rgb(16 185 129 / var(--tw-text-opacity))"
});
