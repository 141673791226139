function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o)
    return;
  if (typeof o === "string")
    return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor)
    n = o.constructor.name;
  if (n === "Map" || n === "Set")
    return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length)
    len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i];
  return arr2;
}
function _iterableToArrayLimit(arr, i) {
  var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"];
  if (null != _i) {
    var _s, _e, _x, _r, _arr = [], _n = true, _d = false;
    try {
      if (_x = (_i = _i.call(arr)).next, 0 === i) {
        if (Object(_i) !== _i)
          return;
        _n = false;
      } else
        for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = true)
          ;
    } catch (err) {
      _d = true, _e = err;
    } finally {
      try {
        if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r))
          return;
      } finally {
        if (_d)
          throw _e;
      }
    }
    return _arr;
  }
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr))
    return arr;
}
import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack } from "@mui/material";
import { GatekeeperLogo } from "assets/icons";
import { IFrameContext } from "context/IframeContext";
import { useIsMobile } from "hooks";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import SocialMedias from "screens/Landing/components/SocialMedias";
import { colors } from "theme/base/colors";
import { PATH } from "utils/constants";
import NavbarDisplay from "./components/NavbarDisplay";
import NavbarItems from "./components/NavbarItems";
import { jsx as __cssprop } from "@emotion/react";
var Navbar = function Navbar2() {
  var navigate = useNavigate();
  var _useIsMobile = useIsMobile(), isMobile = _useIsMobile.isMobile;
  var _useState = useState(false), _useState2 = _slicedToArray(_useState, 2), isOpen = _useState2[0], setIsOpen = _useState2[1];
  var _useContext = useContext(IFrameContext), isIframe = _useContext.isIframe;
  var toggleDrawer = function toggleDrawer2(status) {
    setIsOpen(status);
  };
  if (isIframe)
    return null;
  return __cssprop("nav", {
    className: "xs:[33px] sm:[40px] md:[30px] lg:[35px] xl:[44px] 2xl:[95px] mb-[50px] flex w-full items-center justify-between"
  }, __cssprop(GatekeeperLogo, {
    onClick: function onClick() {
      navigate(isIframe ? PATH.kyc : PATH.home);
    }
  }), __cssprop("div", {
    className: "block md:hidden"
  }, __cssprop(NavbarDisplay, {
    onToggle: toggleDrawer,
    isOpen
  })), __cssprop("div", {
    className: "hidden md:block"
  }, __cssprop(NavbarItems, null)), __cssprop(Drawer, {
    anchor: "top",
    open: isOpen,
    onClose: function onClose() {
      return toggleDrawer(false);
    }
  }, __cssprop(Stack, {
    bgcolor: "black",
    width: "100%",
    direction: "column",
    alignItems: "center",
    gap: 5,
    padding: "20px",
    paddingBottom: "50px"
  }, __cssprop(Stack, {
    width: "100%",
    direction: "row",
    justifyContent: "flex-end"
  }, __cssprop(IconButton, {
    size: "small",
    "aria-label": "close",
    sx: {
      "&:hover": {
        opacity: 0.6
      }
    },
    onClick: function onClick() {
      return toggleDrawer(!isOpen);
    }
  }, __cssprop(CloseIcon, {
    sx: {
      color: colors.white
    }
  }))), __cssprop(NavbarItems, {
    isDrawer: true
  }), __cssprop(SocialMedias, {
    isMobile,
    isDrawer: true
  }))));
};
export default Navbar;
