import { Box } from "@mui/material";
import { OrbisWP } from "assets/webp";
import { jsx as __cssprop } from "@emotion/react";
var OrbisIcon = function OrbisIcon2(_ref) {
  var _ref$size = _ref.size, size = _ref$size === void 0 ? "67px" : _ref$size, _ref$borderRadius = _ref.borderRadius, borderRadius = _ref$borderRadius === void 0 ? "0px" : _ref$borderRadius;
  return __cssprop(Box, {
    width: size,
    height: size
  }, __cssprop("img", {
    src: OrbisWP,
    width: size,
    style: {
      borderRadius
    },
    height: size,
    alt: "icon"
  }));
};
export default OrbisIcon;
