export var sendSuccessMessageToIframe = function sendSuccessMessageToIframe2() {
  window.parent.postMessage(JSON.stringify({
    isVerified: true
  }), "*");
};
export var awaitXSeconds = function awaitXSeconds2(seconds) {
  return new Promise(function(resolve) {
    return setTimeout(resolve, seconds * 1e3);
  });
};
