import { jsx as __cssprop } from "@emotion/react";
var ShieldProtectedCheckmark = function ShieldProtectedCheckmark2() {
  return __cssprop("svg", {
    width: "21",
    height: "20",
    viewBox: "0 0 21 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_814_5350)"
  }, __cssprop("path", {
    d: "M13.2658 8.61847L9.80915 12.0751L7.73499 10.001",
    stroke: "#10B981",
    strokeWidth: "1.25",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M16.4166 4.93406C14.3675 4.77239 12.5025 3.95656 11.0308 2.69406C10.7291 2.43572 10.27 2.43572 9.96915 2.69406C8.49748 3.95572 6.63248 4.77239 4.58331 4.93406C4.15665 4.96739 3.83331 5.33239 3.83331 5.75989V9.36822C3.83331 13.0074 6.46331 16.4199 10.065 17.4391C10.3475 17.5191 10.6533 17.5191 10.9358 17.4391C14.5366 16.4191 17.1666 13.0082 17.1666 9.36906V5.76072C17.1666 5.33239 16.8433 4.96739 16.4166 4.93406Z",
    stroke: "#10B981",
    strokeWidth: "1.25",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_814_5350"
  }, __cssprop("rect", {
    width: "20",
    height: "20",
    fill: "white",
    transform: "translate(0.5)"
  }))));
};
export default ShieldProtectedCheckmark;
