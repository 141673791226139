import { jsx as __cssprop } from "@emotion/react";
var GatekeeperLogoWhite = function GatekeeperLogoWhite2() {
  return __cssprop("svg", {
    width: "96",
    height: "21",
    viewBox: "0 0 96 21",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_7390_32720)"
  }, __cssprop("path", {
    d: "M9.60656 0.223999H9.42102C4.50404 0.223999 0.543945 4.03309 0.543945 8.6166L0.623465 14.7304C0.623465 18.1183 3.571 20.8652 7.20639 20.8652H11.849C15.4844 20.8652 18.4319 19.7808 18.4319 14.7304V8.59561C18.4319 4.0121 14.8058 0.223999 9.60656 0.223999ZM9.58668 4.0405C4.99043 4.0405 4.80224 8.68577 4.80224 8.68577V13.8733C4.80224 17.0685 7.20506 16.9672 7.20506 16.9672H12.6217C14.3831 16.9672 14.2797 15.637 14.2797 15.637V12.5863H10.7835V13.6818C10.7835 13.6818 10.7954 14.7959 9.50319 14.7959C8.45352 14.7959 8.30641 13.8177 8.30641 13.8177V11.529C8.30641 10.3952 9.37728 10.2927 9.37728 10.2927H16.819V15.2973C16.819 19.6795 12.6217 19.3362 12.6217 19.3362H7.4582C2.18603 19.3362 2.34242 14.0857 2.34242 14.0857V8.72159C2.34242 1.94204 9.61849 1.80495 9.61849 1.80495C16.3724 1.80495 16.7249 7.95211 16.7249 7.95211H14.2784C14.2784 7.95211 13.6873 4.0405 9.58536 4.0405H9.58668ZM12.5872 8.6166H6.58613C6.58613 7.71744 7.29121 5.68321 9.46078 5.68321C12.5064 5.68321 12.5872 8.6166 12.5872 8.6166Z",
    fill: "white"
  })), __cssprop("path", {
    d: "M22.5596 10.544C22.5596 11.5008 22.9046 12.313 23.6043 12.9805C24.2992 13.6526 25.1447 13.984 26.1408 13.984C26.4907 13.984 26.8357 13.9373 27.171 13.8393C27.5062 13.7413 27.7784 13.6293 27.9922 13.5032C28.206 13.3819 28.4052 13.2372 28.5899 13.0785C28.7745 12.9198 28.8911 12.8124 28.9494 12.7518C29.0077 12.6911 29.0466 12.6397 29.0758 12.6024V10.4506H26.7385V11.1181H28.3323V12.411L28.2497 12.5044C28.1963 12.5697 28.1088 12.6491 27.9873 12.7331C27.861 12.8171 27.7201 12.9058 27.5597 12.9945C27.3945 13.0832 27.1904 13.1578 26.9377 13.2232C26.685 13.2885 26.4178 13.3165 26.1408 13.3165C25.3439 13.3165 24.6685 13.0505 24.1242 12.5184C23.5751 11.9863 23.303 11.3281 23.303 10.544C23.303 9.75984 23.5751 9.10172 24.1242 8.56961C24.6685 8.03751 25.3439 7.77146 26.1408 7.77146C26.4372 7.77146 26.7239 7.81347 26.996 7.89748C27.2681 7.9815 27.4868 8.07485 27.652 8.17754C27.8172 8.28022 27.9727 8.39225 28.1185 8.52294C28.2594 8.65363 28.3517 8.74698 28.3906 8.79832C28.4295 8.84967 28.4587 8.89168 28.4781 8.91968L28.9786 8.49026L28.8668 8.34557C28.7939 8.25222 28.6773 8.13553 28.517 7.9955C28.3566 7.86014 28.172 7.72478 27.9582 7.58942C27.7443 7.45873 27.4771 7.34204 27.1515 7.24869C26.8308 7.15534 26.4907 7.104 26.1359 7.104C25.1447 7.104 24.2992 7.44006 23.5994 8.10752C22.9046 8.77965 22.5596 9.59181 22.5596 10.544Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M35.693 11.3562H33.0059L34.3519 8.20088L35.693 11.3562ZM35.9943 12.0283L36.7912 13.8906H37.5833L34.6969 7.19735H34.002L31.1157 13.8906H31.9126L32.7095 12.0283H35.9943Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M38.4278 7.19735V7.86948H40.6193V13.8906H41.3628V7.86948H43.5543V7.19735H38.4278Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M46.5369 13.2232V10.7354H49.7682V10.0679H46.5369V7.86948H50.5651V7.19735H45.7885V13.8906H50.6672V13.2232H46.5369Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M53.8976 10.9734V7.19735H53.1493V13.8906H53.8976V11.9303L55.2873 10.4973L57.7752 13.8906H58.6742L55.7878 9.96988L58.4215 7.19735H57.4788L53.8976 10.9734Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M61.5596 13.2232V10.7354H64.791V10.0679H61.5596V7.86948H65.5879V7.19735H60.8113V13.8906H65.6899V13.2232H61.5596Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M68.9203 13.2232V10.7354H72.1517V10.0679H68.9203V7.86948H72.9486V7.19735H68.172V13.8906H73.0507V13.2232H68.9203Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M75.5327 7.19735V13.8906H76.2811V11.4075H78.1713C78.8127 11.4075 79.3424 11.2068 79.77 10.8054C80.1976 10.404 80.4114 9.89987 80.4114 9.30242C80.4114 8.70497 80.1976 8.20554 79.7748 7.80413C79.3472 7.40272 78.8127 7.20202 78.1713 7.20202L75.5327 7.19735ZM78.1713 7.87415C78.6183 7.87415 78.9828 8.00484 79.2549 8.26622C79.527 8.52761 79.6631 8.87301 79.6631 9.30709C79.6631 9.73651 79.527 10.0866 79.2549 10.348C78.9828 10.6093 78.6183 10.74 78.1713 10.74H76.2811V7.87415H78.1713Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M83.7487 13.2232V10.7354H86.9801V10.0679H83.7487V7.86948H87.777V7.19735H83.0004V13.8906H87.879V13.2232H83.7487Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M90.3611 7.19735V13.8906H91.1094V11.2161H93.0968L94.6421 13.8906H95.4876L93.8452 11.1181L93.9958 11.0714C94.093 11.0434 94.2096 10.9781 94.3554 10.8847C94.4963 10.7914 94.6372 10.6794 94.7781 10.544C94.9142 10.4133 95.0357 10.2266 95.1377 9.98856C95.2349 9.75518 95.2883 9.49379 95.2883 9.2044C95.2883 8.63496 95.0843 8.1542 94.6761 7.77146C94.2679 7.38872 93.7577 7.19735 93.1454 7.19735H90.3611ZM93.1454 7.86948C93.5682 7.86948 93.9083 7.99083 94.161 8.23355C94.4137 8.47626 94.54 8.79832 94.54 9.20907C94.54 9.61982 94.4137 9.94188 94.161 10.1846C93.9083 10.4273 93.5682 10.5487 93.1454 10.5487H91.1094V7.87415L93.1454 7.86948Z",
    fill: "white"
  }), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_7390_32720"
  }, __cssprop("rect", {
    width: "17.888",
    height: "20.64",
    fill: "white",
    transform: "translate(0.543945 0.223999)"
  }))));
};
export default GatekeeperLogoWhite;
