import { jsx as __cssprop } from "@emotion/react";
var CheckIcon = function CheckIcon2(_ref) {
  var _ref$width = _ref.width, width = _ref$width === void 0 ? "18px" : _ref$width, _ref$height = _ref.height, height = _ref$height === void 0 ? "18px" : _ref$height, _ref$color = _ref.color, color = _ref$color === void 0 ? "#94A3B8" : _ref$color, _ref$center = _ref.center, center = _ref$center === void 0 ? false : _ref$center;
  return __cssprop("svg", {
    width,
    height,
    viewBox: "0 0 18 18",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    style: {
      display: "block",
      margin: center ? "auto" : "0"
    }
  }, __cssprop("g", {
    clipPath: "url(#clip0_291_1649)"
  }, __cssprop("path", {
    d: "M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z",
    stroke: color,
    strokeWidth: "1.125",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M6.33295 9.25415L7.9582 10.8794L7.9477 10.8689L11.6144 7.20215",
    stroke: color,
    strokeWidth: "1.125",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_291_1649"
  }, __cssprop("rect", {
    width: "18",
    height: "18",
    fill: "white"
  }))));
};
export default CheckIcon;
