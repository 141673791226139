import { jsx as __cssprop } from "@emotion/react";
var NotFollowIcon = function NotFollowIcon2() {
  return __cssprop("svg", {
    width: "46",
    height: "46",
    viewBox: "0 0 46 46",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("rect", {
    x: "0.706116",
    y: "0.993408",
    width: "44.0326",
    height: "44.0326",
    rx: "4.43203",
    fill: "#575F67"
  }), __cssprop("g", {
    clipPath: "url(#clip0_6293_26020)"
  }, __cssprop("path", {
    d: "M31.4951 37.95C30.9226 37.7757 30.3196 37.6658 29.7821 37.4155C27.7924 36.4897 26.6309 34.2789 26.9526 32.1314C27.293 29.8581 29.0218 28.1094 31.2518 27.783C34.3673 27.3274 37.1325 29.6978 37.1395 32.8297C37.1447 35.3539 35.2592 37.5365 32.7683 37.8915C32.6929 37.9021 32.6204 37.9301 32.5461 37.9494H31.4951V37.95ZM31.1249 30.1306V31.9547H29.3189V33.721H31.1436V35.5323H32.9181V33.7122H34.7264V31.936H32.8911V30.1306H31.1249Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M26.5426 19.6226C29.8043 20.5853 31.8548 22.6819 32.7321 25.9752C30.4102 25.8191 28.451 26.5226 26.9099 28.2333C25.3688 29.944 24.8776 31.9682 25.2671 34.2403H11.6401C11.6401 34.1356 11.6401 34.0333 11.6401 33.9303C11.6401 31.9167 11.612 29.9025 11.646 27.8894C11.7068 24.2786 13.3929 21.6842 16.6417 20.1145C17.0658 19.9092 17.5295 19.7858 18.0056 19.6138C16.1528 17.888 15.3791 15.7796 15.8592 13.2793C16.1581 11.7237 16.9657 10.4364 18.2238 9.47257C20.9316 7.39753 24.6126 7.66364 26.9468 10.006C29.2119 12.2781 29.8026 16.7528 26.5426 19.6226V19.6226Z",
    fill: "white"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_6293_26020"
  }, __cssprop("rect", {
    width: "25.5093",
    height: "29.8805",
    fill: "white",
    transform: "translate(11.6298 8.06958)"
  }))));
};
export default NotFollowIcon;
