import { Box, Typography } from "@mui/material";
import { ShieldProtectedCheckmark } from "assets/icons";
import { colors } from "theme/base/colors";
import { fontSize } from "theme/base/fontSize";
import { fontWeights } from "theme/base/fontWeight";
import { jsx as __cssprop } from "@emotion/react";
var PoweredByBanner = function PoweredByBanner2() {
  return __cssprop(Box, {
    sx: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "5px"
    }
  }, __cssprop(Typography, {
    fontSize: fontSize.p,
    fontWeight: fontWeights.semibold,
    lineHeight: "22px",
    color: colors.white400,
    sx: {
      textAlign: "center"
    }
  }, "Powered by"), __cssprop(ShieldProtectedCheckmark, null), __cssprop(Typography, {
    fontSize: fontSize.p,
    fontWeight: fontWeights.semibold,
    lineHeight: "22px",
    color: colors.white,
    sx: {
      textAlign: "center"
    }
  }, "GateKeeper"));
};
export default PoweredByBanner;
