import { useMediaQuery } from "@mui/material";
import { RESOLUTIONS } from "../utils/constants";
var useResolution = function useResolution2() {
  var full = useMediaQuery("(max-width:1920px)");
  var notebook = useMediaQuery("(max-width:1280px)");
  var mobile = useMediaQuery("(max-width:600px)");
  if (notebook)
    return {
      maxWidth: RESOLUTIONS.notebook,
      paddingBottom: "40px",
      media: "notebook"
    };
  if (full)
    return {
      maxWidth: RESOLUTIONS.full,
      paddingBottom: "60px"
    };
  if (mobile)
    return {
      maxWidth: RESOLUTIONS.mobile,
      paddingBottom: "40px"
    };
  return {
    maxWidth: RESOLUTIONS.full,
    paddingBottom: "60px"
  };
};
export default useResolution;
