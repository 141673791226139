import { EarlyAdopterLottie } from "assets/lotties";
import Lottie from "lottie-react";
import { jsx as __cssprop } from "@emotion/react";
var EarlyAdopterIcon = function EarlyAdopterIcon2() {
  return __cssprop(Lottie, {
    style: {
      position: "relative"
    },
    animationData: EarlyAdopterLottie,
    loop: true
  });
};
export default EarlyAdopterIcon;
