import { createSlice } from "@reduxjs/toolkit";
import { ISSUE_VC_STEPS } from "components/IssueVcModal/types";
var initialState = {
  didMethod: "",
  modalIndex: ISSUE_VC_STEPS.SELECT_DID_METHOD,
  vcSchema: null
};
var issueVcReducer = createSlice({
  name: "ISSUE_VC",
  initialState,
  reducers: {
    setDidMethod: function setDidMethod(state, action) {
      state.didMethod = action.payload;
    },
    setModalIndex: function setModalIndex(state, action) {
      state.modalIndex = action.payload;
    },
    setVcSchema: function setVcSchema(state, action) {
      state.vcSchema = action.payload;
    },
    resetVcSchema: function resetVcSchema(state) {
      state.vcSchema = null;
    }
  }
});
var _issueVcReducer$actio = issueVcReducer.actions, setDidMethod2 = _issueVcReducer$actio.setDidMethod, setModalIndex2 = _issueVcReducer$actio.setModalIndex, setVcSchema2 = _issueVcReducer$actio.setVcSchema, resetVcSchema2 = _issueVcReducer$actio.resetVcSchema;
export { setDidMethod2 as setDidMethod, setModalIndex2 as setModalIndex, setVcSchema2 as setVcSchema, resetVcSchema2 as resetVcSchema };
export default issueVcReducer.reducer;
