import { OfacIconWP } from "assets/webp";
import { jsx as __cssprop } from "@emotion/react";
var OfacIcon = function OfacIcon2(_ref) {
  var _ref$w = _ref.w, w = _ref$w === void 0 ? "41px" : _ref$w, _ref$h = _ref.h, h = _ref$h === void 0 ? "42px" : _ref$h;
  return __cssprop("img", {
    src: OfacIconWP,
    width: w,
    height: h,
    alt: "OfacIcon"
  });
};
export default OfacIcon;
