import DefaultAvatarWP from "./defaultAvatar.webp";
import GetAndroidWP from "./getAndroid.webp";
import GetIosWP from "./getIos.webp";
import GkVideoThumbnailWP from "./gk_video_thumbnail.webp";
import GridMiddleWP from "./gridmidlde.webp";
import GridTopWP from "./gridtop.webp";
import LensWP from "./lens.webp";
import NoMobileWP from "./nomobile.webp";
import OfacIconWP from "./ofacIcon.webp";
import OrbisWP from "./orbis.webp";
import PartnersWP from "./partners.webp";
import StarWP from "./Star.webp";
import SuccessVerifiedWP from "./SuccessVerified.webp";
import SwapCardWP from "./SwapCard.webp";
import UserIconDefaultWP from "./UserIconDefault.webp";
import WalletIconWP from "./walletIcon.webp";
export { DefaultAvatarWP, GetAndroidWP, GetIosWP, GkVideoThumbnailWP, GridMiddleWP, GridTopWP, LensWP, NoMobileWP, OfacIconWP, OrbisWP, PartnersWP, StarWP, SuccessVerifiedWP, SwapCardWP, UserIconDefaultWP, WalletIconWP };
