import { useAppDispatch, useAppSelector } from "store/hooks";
import { setIsForcedTutorial } from ".";
var useTutorialHook = function useTutorialHook2() {
  var dispatch = useAppDispatch();
  var tutorialsStore = useAppSelector(function(state) {
    return state.tutorials;
  });
  var setForcedTutorial = function setForcedTutorial2(value) {
    dispatch(setIsForcedTutorial(value));
  };
  return {
    isForcedTutorial: tutorialsStore.isForcedTutorial,
    setForcedTutorial
  };
};
export default useTutorialHook;
