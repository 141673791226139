import { jsx as __cssprop } from "@emotion/react";
var DiscordSquare = function DiscordSquare2() {
  return __cssprop("svg", {
    width: "61",
    height: "61",
    viewBox: "0 0 61 61",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("rect", {
    width: "61",
    height: "61",
    rx: "6",
    fill: "#7289DA"
  }), __cssprop("path", {
    d: "M42.8104 19.0721C40.5147 17.998 38.0599 17.2174 35.4936 16.7729C35.1784 17.3427 34.8102 18.1092 34.5563 18.7189C31.8282 18.3086 29.1252 18.3086 26.4472 18.7189C26.1934 18.1092 25.8168 17.3427 25.4988 16.7729C22.9297 17.2174 20.4721 18.0009 18.1764 19.0778C13.5458 26.0752 12.2905 32.8988 12.9182 39.6255C15.9894 41.919 18.9658 43.3123 21.892 44.224C22.6145 43.2296 23.2588 42.1726 23.8139 41.0586C22.7567 40.6569 21.7441 40.1612 20.7874 39.5857C21.0412 39.3976 21.2895 39.201 21.5294 38.9987C27.365 41.7282 33.7055 41.7282 39.4714 38.9987C39.7141 39.201 39.9623 39.3976 40.2134 39.5857C39.2538 40.164 38.2384 40.6597 37.1812 41.0615C37.7363 42.1726 38.3779 43.2325 39.1032 44.2268C42.0322 43.3151 45.0113 41.9219 48.0826 39.6255C48.819 31.8276 46.8245 25.0666 42.8104 19.0721ZM24.609 35.4887C22.8572 35.4887 21.4206 33.8533 21.4206 31.8617C21.4206 29.8702 22.8265 28.232 24.609 28.232C26.3915 28.232 27.828 29.8674 27.7974 31.8617C27.8001 33.8533 26.3915 35.4887 24.609 35.4887ZM36.3918 35.4887C34.64 35.4887 33.2034 33.8533 33.2034 31.8617C33.2034 29.8702 34.6093 28.232 36.3918 28.232C38.1743 28.232 39.6109 29.8674 39.5802 31.8617C39.5802 33.8533 38.1743 35.4887 36.3918 35.4887Z",
    fill: "white"
  }));
};
export default DiscordSquare;
