import { jsx as __cssprop } from "@emotion/react";
var DotItemCircle = function DotItemCircle2() {
  return __cssprop("svg", {
    width: "33",
    height: "30",
    viewBox: "0 0 33 30",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("path", {
    d: "M25.5 15C25.5 19.9706 21.4706 24 16.5 24C11.5294 24 7.5 19.9706 7.5 15C7.5 10.0294 11.5294 6 16.5 6C21.4706 6 25.5 10.0294 25.5 15Z",
    fill: "url(#paint0_radial_7319_37450)"
  }), __cssprop("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.5 27C23.1274 27 28.5 21.6274 28.5 15C28.5 8.37258 23.1274 3 16.5 3C9.87258 3 4.5 8.37258 4.5 15C4.5 21.6274 9.87258 27 16.5 27ZM16.5 29C24.232 29 30.5 22.732 30.5 15C30.5 7.26801 24.232 1 16.5 1C8.76801 1 2.5 7.26801 2.5 15C2.5 22.732 8.76801 29 16.5 29Z",
    fill: "#10B981"
  }), __cssprop("defs", null, __cssprop("radialGradient", {
    id: "paint0_radial_7319_37450",
    cx: "0",
    cy: "0",
    r: "1",
    gradientUnits: "userSpaceOnUse",
    gradientTransform: "translate(16.5 15) rotate(90) scale(9)"
  }, __cssprop("stop", {
    stopColor: "#64A6E7"
  }), __cssprop("stop", {
    offset: "1",
    stopColor: "#80AFD5",
    stopOpacity: "0"
  }))));
};
export default DotItemCircle;
