var _templateObject;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import styled from "styled-components";
import { colors } from "theme/base/colors";
export var StyledConnectButton = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n\n  width: ", ";\n  background: ", ";\n  color: ", ";\n  font-size: ", ";\n  padding: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), {
  "display": "flex",
  "height": "52px",
  "cursor": "pointer",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.5rem",
  "borderRadius": "0.25rem",
  "borderStyle": "none",
  "paddingLeft": "1.5rem",
  "paddingRight": "1.5rem",
  "paddingTop": "10px",
  "paddingBottom": "10px",
  "transitionDuration": "300ms",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "@media (min-width: 640px)": {
    "height": "52px",
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "10px",
    "paddingBottom": "10px"
  },
  "@media (min-width: 768px)": {
    "height": "48px",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "10px",
    "paddingBottom": "10px"
  },
  "@media (min-width: 1280px)": {
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.625rem",
    "paddingBottom": "0.625rem"
  },
  "@media (min-width: 1536px)": {
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "0.625rem",
    "paddingBottom": "0.625rem"
  }
}, function(_ref) {
  var width = _ref.width;
  return width ? width : "";
}, function(_ref2) {
  var bgcolor = _ref2.bgcolor;
  return bgcolor !== null && bgcolor !== void 0 ? bgcolor : colors.success50;
}, function(_ref3) {
  var textColor = _ref3.textColor;
  return textColor;
}, function(_ref4) {
  var fontSize = _ref4.fontSize;
  return fontSize !== null && fontSize !== void 0 ? fontSize : "16px";
}, function(_ref5) {
  var isMobile = _ref5.isMobile, padding = _ref5.padding;
  return isMobile ? "10px 12px" : padding;
}, function(props) {
  return props.bgHover ? props.bgHover : "";
});
