import { Box, Fade, Modal, Typography } from "@mui/material";
import { FailedIcon } from "assets/icons";
import ConnectButtonModal from "components/ConnectModal";
import { IFrameContext } from "context/IframeContext";
import { Button } from "flowbite-components/buttons";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { refreshModalStlyes } from "screens/Dashboards/BusinessDashboard/styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setSessionExpired } from "store/sessionDetails";
import { selectSessionData } from "store/sessionDetails/selectors";
import { PATH } from "utils/constants";
import { jsx as __cssprop } from "@emotion/react";
var ExpiredModal = function ExpiredModal2() {
  var _useAppSelector = useAppSelector(selectSessionData), isExpired = _useAppSelector.isExpired;
  var _useContext = useContext(IFrameContext), isIframe = _useContext.isIframe;
  var navigate = useNavigate();
  var dispatch = useAppDispatch();
  var handleClose = function handleClose2() {
    dispatch(setSessionExpired(false));
    navigate(PATH.home);
  };
  if (isIframe)
    return null;
  return __cssprop(React.Fragment, null, __cssprop(Modal, {
    open: isExpired,
    onClose: function onClose() {
      return handleClose();
    },
    closeAfterTransition: true
  }, __cssprop(Fade, {
    in: true
  }, __cssprop(Box, {
    sx: refreshModalStlyes
  }, __cssprop(Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px"
  }, __cssprop(FailedIcon, null), __cssprop(Typography, {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "20px"
  }, "Your session has expired"), __cssprop(Box, {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    gap: "15px",
    mt: "20px"
  }, __cssprop(ConnectButtonModal, {
    label: "Re-login",
    isDisabled: false,
    width: "100px"
  }), __cssprop(Button, {
    variant: "primary",
    onClick: handleClose,
    label: "Home"
  })))))));
};
export default ExpiredModal;
