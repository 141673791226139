export var CreditOptions;
(function(CreditOptions2) {
  CreditOptions2[CreditOptions2["platform"] = 0] = "platform";
  CreditOptions2[CreditOptions2["attributeName"] = 1] = "attributeName";
  CreditOptions2[CreditOptions2["operator"] = 2] = "operator";
  CreditOptions2[CreditOptions2["value"] = 3] = "value";
})(CreditOptions || (CreditOptions = {}));
export var SpectralRiskLevels;
(function(SpectralRiskLevels2) {
  SpectralRiskLevels2[SpectralRiskLevels2["VERY_LOW_RISK"] = 0] = "VERY_LOW_RISK";
  SpectralRiskLevels2[SpectralRiskLevels2["LOW_RISK"] = 1] = "LOW_RISK";
  SpectralRiskLevels2[SpectralRiskLevels2["MEDIUM_RISK"] = 2] = "MEDIUM_RISK";
  SpectralRiskLevels2[SpectralRiskLevels2["HIGH_RISK"] = 3] = "HIGH_RISK";
  SpectralRiskLevels2[SpectralRiskLevels2["VERY_HIGH_RISK"] = 4] = "VERY_HIGH_RISK";
})(SpectralRiskLevels || (SpectralRiskLevels = {}));
export var CredProtocolRiskLevels;
(function(CredProtocolRiskLevels2) {
  CredProtocolRiskLevels2[CredProtocolRiskLevels2["EXCELLENT"] = 0] = "EXCELLENT";
  CredProtocolRiskLevels2[CredProtocolRiskLevels2["VERY_GOOD"] = 1] = "VERY_GOOD";
  CredProtocolRiskLevels2[CredProtocolRiskLevels2["FAIR"] = 2] = "FAIR";
  CredProtocolRiskLevels2[CredProtocolRiskLevels2["GOOD"] = 3] = "GOOD";
  CredProtocolRiskLevels2[CredProtocolRiskLevels2["LOW"] = 4] = "LOW";
})(CredProtocolRiskLevels || (CredProtocolRiskLevels = {}));
export var Platforms;
(function(Platforms2) {
  Platforms2["SPECTRAL"] = "Spectral";
  Platforms2["CRED_PROTOCOL"] = "Cred Protocol";
})(Platforms || (Platforms = {}));
export var CreditScoreAttributes;
(function(CreditScoreAttributes2) {
  CreditScoreAttributes2["CREDIT_SCORE"] = "Credit score";
  CreditScoreAttributes2["RISK_LEVEL"] = "Risk level";
})(CreditScoreAttributes || (CreditScoreAttributes = {}));
