import { jsx as __cssprop } from "@emotion/react";
var PlusIcon = function PlusIcon2() {
  return __cssprop("svg", {
    width: "20",
    height: "20",
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("path", {
    d: "M9.48087 9.19047H9.98087V8.69047V3.83333H10.9289V8.69047V9.19047H11.4289H16.7731V9.97619H11.4289H10.9289V10.4762V15.3333H9.98087V10.4762V9.97619H9.48087H4.13672V9.19047H9.48087Z",
    fill: "white",
    stroke: "white"
  }));
};
export default PlusIcon;
