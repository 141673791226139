import { breakpoints } from "./breakpoints";
import { colors } from "./colors";
import { fontFamily } from "./fontFamily";
import { fontSize } from "./fontSize";
import { fontWeights } from "./fontWeight";
export var baseTheme = {
  fontFamily,
  breakpoints,
  colors,
  fontSize,
  fontWeight: fontWeights
};
