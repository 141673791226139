import { colors } from "theme/base/colors";
import { SpinnerWrapper, StyledSpinner } from "./styles";
import { jsx as __cssprop } from "@emotion/react";
var Spinner = function Spinner2(_ref) {
  var _ref$primaryColor = _ref.primaryColor, primaryColor = _ref$primaryColor === void 0 ? colors.success600 : _ref$primaryColor, _ref$bgColor = _ref.bgColor, bgColor = _ref$bgColor === void 0 ? colors.white300 : _ref$bgColor, _ref$size = _ref.size, size = _ref$size === void 0 ? "5rem" : _ref$size, margin = _ref.margin;
  return __cssprop(SpinnerWrapper, {
    role: "status",
    margin
  }, __cssprop(StyledSpinner, {
    size,
    $primaryColor: primaryColor,
    $bgColor: bgColor
  }));
};
export default Spinner;
