import { DiscordRoundIcon, LinkedInRoundIcon, MediumRoundIcon, TwitterRoundIcon } from "assets/icons/socials";
import { IFrameContext } from "context/IframeContext";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { LINK_DISCORD, LINK_LINKEDIN, LINK_MEDIUM, LINK_TWITTER, PATH } from "utils/constants";
import { FooterDiv, Links, LinksSection, SocialMediaDiv, StyledLink, Text } from "./style";
import { jsx as __cssprop } from "@emotion/react";
var Footer = function Footer2() {
  var _useContext = useContext(IFrameContext), isIframe = _useContext.isIframe;
  if (isIframe)
    return null;
  return __cssprop(FooterDiv, null, __cssprop(SocialMediaDiv, null, __cssprop(StyledLink, {
    href: LINK_LINKEDIN,
    target: "_blank"
  }, __cssprop(LinkedInRoundIcon, null)), __cssprop(StyledLink, {
    href: LINK_MEDIUM,
    target: "_blank"
  }, __cssprop(MediumRoundIcon, null)), __cssprop(StyledLink, {
    href: LINK_DISCORD,
    target: "_blank"
  }, __cssprop(DiscordRoundIcon, null)), __cssprop(StyledLink, {
    href: LINK_TWITTER,
    target: "_blank"
  }, __cssprop(TwitterRoundIcon, null))), __cssprop(LinksSection, null, __cssprop(Links, null, __cssprop(NavLink, {
    to: PATH.privacyPolicy
  }, __cssprop(Text, null, "Privacy policy")), __cssprop(NavLink, {
    to: PATH.termsOfService
  }, __cssprop(Text, null, "Terms of service"))), __cssprop(Text, null, "\xA9 GATEKEEPER")));
};
export default Footer;
