import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { DRMContextProvider } from "./context/DRMContext";
import { IFrameContextProvider } from "./context/IframeContext";
import "./index.css";
import store, { persistor } from "./store";
import ThemeProvider from "./theme";
import { jsx as __cssprop } from "@emotion/react";
var container = document.getElementById("root");
var root = createRoot(container);
root.render(__cssprop(Provider, {
  store
}, __cssprop(IFrameContextProvider, null, __cssprop(DRMContextProvider, null, __cssprop(ThemeProvider, null, __cssprop(BrowserRouter, null, __cssprop(PersistGate, {
  loading: null,
  persistor
}, __cssprop(App, null))))))));
