import { jsx as __cssprop } from "@emotion/react";
var ApprovedUsersIcon = function ApprovedUsersIcon2() {
  return __cssprop("svg", {
    width: "119",
    height: "88",
    viewBox: "0 0 119 88",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_5335_23792)"
  }, __cssprop("path", {
    opacity: "0.3",
    d: "M99.6233 82.2152H115.753V75.6752C115.753 75.6752 104.263 64.6652 98.3133 64.0152V54.4852C98.3133 54.4852 104.653 51.2552 104.653 37.1852C104.653 16.1652 83.1333 18.9152 83.1333 18.9152C83.1333 18.9152 92.4633 37.1852 77.3333 51.6452L81.5633 54.5952C81.5633 54.5952 85.8933 52.8352 91.7933 56.1652C100.383 61.0252 97.5633 73.7152 97.5633 73.7152L97.6333 82.1952L99.6333 82.2152H99.6233Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M21.8933 86.7949H96.5433V74.4349C96.5433 74.4349 90.3633 84.8549 78.8333 80.2949C75.8333 78.7949 67.3333 72.2949 72.9633 60.3749C71.0533 59.4149 70.2633 59.1349 68.7633 58.7049C68.7633 58.7049 80.9033 45.6749 80.9033 26.5549C80.9033 7.43492 67.0433 0.794922 59.2233 0.794922C50.0233 0.794922 37.2233 7.45492 37.2233 26.5749C37.2233 45.6949 49.9533 58.7249 49.9533 58.7249C42.4933 61.3249 21.9033 75.5149 21.9033 75.5149V86.8149V86.7949H21.8933Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M84.6818 56.5537C78.6033 56.5537 73.6672 61.4987 73.6672 67.5862C73.6672 73.6736 78.6033 78.6186 84.6818 78.6186C90.7604 78.6186 95.6964 73.6736 95.6964 67.5862C95.6964 61.4987 90.7604 56.5537 84.6818 56.5537ZM90.7604 64.8459L82.8788 72.7453L78.6033 68.4609C78.1481 68.0057 78.1481 67.3184 78.6033 66.8632C79.0585 66.4079 79.7458 66.4079 80.201 66.8632L82.8788 69.5499L89.1626 63.2482C89.6179 62.7929 90.3052 62.7929 90.7604 63.2482C91.2156 63.7034 91.2156 64.3907 90.7604 64.8459Z",
    fill: "#059669"
  }), __cssprop("path", {
    opacity: "0.3",
    d: "M17.4633 82.2152H1.33325V75.6752C1.33325 75.6752 12.8233 64.6652 18.7733 64.0152V54.4852C18.7733 54.4852 12.4333 51.2552 12.4333 37.1852C12.4333 16.1652 33.9533 18.9152 33.9533 18.9152C33.9533 18.9152 26.8333 35.0052 39.7533 51.6452L35.5733 54.3652V61.7252L17.4533 73.7052V82.2152H17.4633Z",
    fill: "white"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_5335_23792"
  }, __cssprop("rect", {
    width: "118",
    height: "86.41",
    fill: "white",
    transform: "translate(0.333252 0.794922)"
  }))));
};
export default ApprovedUsersIcon;
