import { jsx as __cssprop } from "@emotion/react";
var FollowIcon = function FollowIcon2() {
  return __cssprop("svg", {
    width: "58",
    height: "43",
    viewBox: "0 0 58 43",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_6293_26374)"
  }, __cssprop("path", {
    d: "M10.5997 42.5H47.2929V36.9815C47.2929 36.9815 38.042 30.0507 33.6374 28.7809C33.6374 28.7809 39.6057 22.4198 39.6057 13.0798C39.6057 3.7399 32.7899 0.5 28.9463 0.5C24.4223 0.5 18.1317 3.75177 18.1317 13.0917C18.1317 22.4316 24.3865 28.7927 24.3865 28.7927C20.722 30.0626 10.5997 36.9934 10.5997 36.9934V42.5119V42.5Z",
    fill: "#64A6E7"
  }), __cssprop("path", {
    d: "M7.92591 40.174H0V36.9816C0 36.9816 5.64602 31.6055 8.57049 31.285V26.6329C8.57049 26.6329 5.45503 25.0545 5.45503 18.183C5.45503 7.91742 16.0309 9.25847 16.0309 9.25847C16.0309 9.25847 12.5334 17.115 18.8837 25.2443L16.8306 26.5735V30.1695L7.92591 36.0203V40.1621V40.174Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M50.0739 40.174H57.9998V36.9816C57.9998 36.9816 52.3538 31.6055 49.4293 31.285V26.6329C49.4293 26.6329 52.5448 25.0545 52.5448 18.183C52.5448 7.91742 41.9689 9.25847 41.9689 9.25847C41.9689 9.25847 46.5532 18.183 39.1161 25.2443L41.1692 26.5735V30.1695L50.0739 36.0203V40.1621V40.174Z",
    fill: "#059669"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_6293_26374"
  }, __cssprop("rect", {
    width: "58",
    height: "42",
    fill: "white",
    transform: "translate(0 0.5)"
  }))));
};
export default FollowIcon;
