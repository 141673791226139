function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
import ConnectButtonModal from "components/ConnectModal";
import WalletAddress from "components/WalletAddress";
import { IFrameContext } from "context/IframeContext";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { selectGeneralAppState } from "store/general/selectors";
import { useAppSelector } from "store/hooks";
import { PATH } from "utils/constants";
import { NavbarLink, NavbarStack, Title } from "../style";
import { jsx as __cssprop } from "@emotion/react";
var NavbarItems = function NavbarItems2(_ref) {
  var isDrawer = _ref.isDrawer;
  var _useContext = useContext(IFrameContext), isIframe = _useContext.isIframe;
  var _useLocation = useLocation(), pathname = _useLocation.pathname;
  var _useAppSelector = useAppSelector(selectGeneralAppState), isLoggedIn = _useAppSelector.isLoggedIn;
  var navItems = [
    {
      title: "Home",
      path: isIframe ? PATH.kyc : PATH.home,
      condition: isDrawer,
      isActive: pathname === PATH.home
    },
    {
      title: "Playground",
      path: PATH.playground,
      isActive: pathname === PATH.playground
    },
    // {
    //   title: "Demo",
    //   path: PATH.demo,
    //   isActive: pathname === PATH.demo,
    // },
    {
      title: "KYC Ecosystem",
      path: PATH.kycEcosystem,
      isActive: pathname === PATH.kycEcosystem
    },
    {
      title: "Docs",
      path: "https://docs.gatekeeper.software/getting-started/introduction",
      options: {
        target: "_blank",
        rel: "noreferrer"
      }
    },
    {
      title: "Dashboard",
      path: PATH.dashBoard,
      condition: isLoggedIn,
      isActive: pathname === PATH.dashBoard
    }
  ];
  return __cssprop(NavbarStack, null, navItems.map(function(item) {
    if (item.condition === void 0 || item.condition) {
      return __cssprop(NavbarLink, _extends({
        key: item.title
      }, item.options, {
        to: item.path
      }), __cssprop(Title, null, item.title));
    }
    return null;
  }), pathname !== PATH.demo && (isLoggedIn ? __cssprop(WalletAddress, null) : __cssprop(ConnectButtonModal, {
    fullWidth: isDrawer !== null && isDrawer !== void 0 ? isDrawer : false,
    label: "Connect wallet"
  })));
};
export default NavbarItems;
