function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o)
    return;
  if (typeof o === "string")
    return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor)
    n = o.constructor.name;
  if (n === "Map" || n === "Set")
    return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length)
    len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i];
  return arr2;
}
function _iterableToArrayLimit(arr, i) {
  var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"];
  if (null != _i) {
    var _s, _e, _x, _r, _arr = [], _n = true, _d = false;
    try {
      if (_x = (_i = _i.call(arr)).next, 0 === i) {
        if (Object(_i) !== _i)
          return;
        _n = false;
      } else
        for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = true)
          ;
    } catch (err) {
      _d = true, _e = err;
    } finally {
      try {
        if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r))
          return;
      } finally {
        if (_d)
          throw _e;
      }
    }
    return _arr;
  }
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr))
    return arr;
}
import { Popover } from "@mui/material";
import { QuestionCircleSVG } from "assets/svg";
import React, { useState } from "react";
import { colors } from "theme/base/colors";
import { IconBox, Message } from "./styles";
import { jsx as __cssprop } from "@emotion/react";
var PopoverHelper = function PopoverHelper2(_ref) {
  var message = _ref.message, _ref$icon = _ref.icon, icon = _ref$icon === void 0 ? QuestionCircleSVG : _ref$icon, _ref$backgroundColor = _ref.backgroundColor, backgroundColor = _ref$backgroundColor === void 0 ? colors.white600 : _ref$backgroundColor;
  var _useState = useState(null), _useState2 = _slicedToArray(_useState, 2), anchorEl = _useState2[0], setAnchorEl = _useState2[1];
  var handlePopoverOpen = function handlePopoverOpen2(event) {
    setAnchorEl(event.currentTarget);
  };
  var handlePopoverClose = function handlePopoverClose2() {
    return setAnchorEl(null);
  };
  var open = Boolean(anchorEl);
  var Icon = icon;
  return __cssprop(React.Fragment, null, __cssprop(IconBox, {
    onMouseEnter: handlePopoverOpen,
    onMouseLeave: handlePopoverClose
  }, __cssprop(Icon, null)), __cssprop(Popover, {
    sx: {
      pointerEvents: "none",
      width: "100%",
      zIndex: 99999
    },
    open,
    anchorEl,
    anchorOrigin: {
      vertical: "top",
      horizontal: "right"
    },
    transformOrigin: {
      vertical: "center",
      horizontal: "left"
    },
    onClose: handlePopoverClose,
    disableRestoreFocus: true
  }, Array.isArray(message) ? __cssprop(React.Fragment, null, message.map(function(item) {
    return __cssprop(Message, {
      key: item
    }, item);
  })) : __cssprop(Message, {
    bgColor: backgroundColor
  }, message)));
};
export default PopoverHelper;
