function _typeof(obj) {
  "@babel/helpers - typeof";
  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(obj2) {
    return typeof obj2;
  } : function(obj2) {
    return obj2 && "function" == typeof Symbol && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
  }, _typeof(obj);
}
import { configureStore } from "@reduxjs/toolkit";
import * as localforage from "localforage";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import reducers from "./reducers";
var getCircularReplacer = function getCircularReplacer2() {
  var seen = /* @__PURE__ */ new WeakSet();
  return function(key, value) {
    if (_typeof(value) === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
export var JSONTransform = createTransform(function(inboundState, key) {
  return JSON.stringify(inboundState, getCircularReplacer());
}, function(outboundState, key) {
  return JSON.parse(outboundState, getCircularReplacer());
});
var persistConfig = {
  key: "root",
  storage: localforage,
  blacklist: ["playground", "claimVC", "sdkManager", "issueVc", "tutorials"],
  transforms: [JSONTransform]
};
var persistedReducer = persistReducer(persistConfig, reducers);
var store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk]
});
export var persistor = persistStore(store);
export default store;
