import { jsx as __cssprop } from "@emotion/react";
var LearningIcon = function LearningIcon2() {
  return __cssprop("svg", {
    width: "64",
    height: "67",
    viewBox: "0 0 64 67",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_4947_22944)"
  }, __cssprop("path", {
    d: "M32.0665 11.2372C20.0807 11.2372 11.5464 23.9524 21.8759 37.8297C25.8917 43.2273 25.4745 44.6684 25.4745 44.6684H38.4295C38.4295 44.6684 38.8834 41.8488 40.9362 39.4165C52.783 25.3893 44.0523 11.2372 32.0624 11.2372H32.0665ZM30.8806 17.4178C30.7498 17.4303 27.5069 17.7427 24.9675 20.8705C22.5139 23.8941 23.9411 28.4588 23.9574 28.5046C24.166 29.1585 23.8184 29.8665 23.1763 30.0831C23.0496 30.1247 22.9187 30.1456 22.792 30.1456C22.2808 30.1456 21.8023 29.8165 21.6306 29.2918C21.5529 29.0502 19.7495 23.3819 23.0782 19.2795C26.3088 15.2979 30.5248 14.9397 30.7007 14.9231C31.3754 14.8689 31.9643 15.3895 32.0133 16.0767C32.0624 16.7639 31.5553 17.3637 30.8806 17.4136V17.4178Z",
    fill: "#64A6E7"
  }), __cssprop("path", {
    d: "M38.4295 46.0136H25.4746V48.5208H38.4295V46.0136Z",
    fill: "#64A6E7"
  }), __cssprop("path", {
    d: "M38.4295 50.6907H25.4991C25.4991 50.6907 25.4746 51.5278 25.4746 52.5566C25.4746 53.5853 26.2966 54.4224 27.3066 54.4224H29.6293V55.8926C29.6293 57.0587 30.5576 58 31.6985 58H32.2547C33.3997 58 34.3239 57.0546 34.3239 55.8926V54.4224H36.5934C37.6076 54.4224 38.4255 53.5853 38.4255 52.5566V50.6907H38.4295Z",
    fill: "#64A6E7"
  }), __cssprop("path", {
    d: "M31.9084 8.83406C31.2296 8.83406 30.6816 8.27598 30.6816 7.58461V2.24945C30.6816 1.55809 31.2296 1 31.9084 1C32.5873 1 33.1352 1.55809 33.1352 2.24945V7.58461C33.1352 8.27598 32.5873 8.83406 31.9084 8.83406Z",
    fill: "#10B981"
  }), __cssprop("path", {
    d: "M13.4652 27.48H8.22679C7.54797 27.48 7 26.922 7 26.2306C7 25.5392 7.54797 24.9811 8.22679 24.9811H13.4652C14.144 24.9811 14.692 25.5392 14.692 26.2306C14.692 26.922 14.144 27.48 13.4652 27.48Z",
    fill: "#10B981"
  }), __cssprop("path", {
    d: "M19.0838 14.2484C18.7649 14.2484 18.45 14.1234 18.2087 13.8735L14.5324 10.071C14.0581 9.57958 14.0621 8.78826 14.5447 8.30514C15.0272 7.82202 15.8042 7.82618 16.2786 8.31763L19.9548 12.1201C20.4292 12.6116 20.4251 13.4029 19.9426 13.886C19.7054 14.1276 19.3946 14.2442 19.0838 14.2442V14.2484Z",
    fill: "#10B981"
  }), __cssprop("path", {
    d: "M45.0222 14.2483C44.7114 14.2483 44.4006 14.1276 44.1634 13.8902C43.6809 13.4071 43.6727 12.6157 44.1512 12.1243L47.8274 8.32178C48.3018 7.83033 49.0788 7.82617 49.5613 8.30929C50.0439 8.79241 50.052 9.58373 49.5736 10.0752L45.8973 13.8777C45.656 14.1276 45.3411 14.2525 45.0222 14.2525V14.2483Z",
    fill: "#10B981"
  }), __cssprop("path", {
    d: "M55.7738 27.48H50.5354C49.8566 27.48 49.3086 26.922 49.3086 26.2306C49.3086 25.5392 49.8566 24.9811 50.5354 24.9811H55.7738C56.4526 24.9811 57.0006 25.5392 57.0006 26.2306C57.0006 26.922 56.4526 27.48 55.7738 27.48Z",
    fill: "#10B981"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_4947_22944"
  }, __cssprop("rect", {
    width: "50",
    height: "57",
    fill: "white",
    transform: "translate(7 1)"
  }))));
};
export default LearningIcon;
