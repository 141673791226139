import { createSlice } from "@reduxjs/toolkit";
import { CLAIM_SCREENS } from "utils/constants";
var initialState = {
  vcFlowIndex: CLAIM_SCREENS.START_SCREEN,
  successClaimData: {
    vcName: "",
    claimVcLink: ""
  }
};
var claimVcReducer = createSlice({
  name: "CLAIM_VC_PROCESS",
  initialState,
  reducers: {
    goToVcScreen: function goToVcScreen(state, action) {
      state.vcFlowIndex = action.payload;
    },
    setSuccessClaim: function setSuccessClaim(state, action) {
      state.successClaimData = action.payload;
    }
  }
});
var _claimVcReducer$actio = claimVcReducer.actions, goToVcScreen2 = _claimVcReducer$actio.goToVcScreen, setSuccessClaim2 = _claimVcReducer$actio.setSuccessClaim;
export { goToVcScreen2 as goToVcScreen, setSuccessClaim2 as setSuccessClaim };
export default claimVcReducer.reducer;
