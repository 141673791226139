import { jsx as __cssprop } from "@emotion/react";
var SuccessIcon = function SuccessIcon2() {
  return __cssprop("svg", {
    width: "45",
    height: "44",
    viewBox: "0 0 45 44",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("rect", {
    x: "0.5",
    width: "44",
    height: "44",
    rx: "5",
    fill: "#2A3446"
  }), __cssprop("g", {
    clipPath: "url(#clip0_6413_28937)"
  }, __cssprop("path", {
    d: "M24.9077 28.9377C24.4992 29.5407 23.8624 29.9175 23.1595 29.9866C23.0874 29.9929 23.0213 29.9992 22.9492 29.9992C22.3184 29.9992 21.7116 29.7417 21.2611 29.2769L14.3763 22.1734C13.4331 21.1999 13.4211 19.6109 14.3523 18.6185C15.2835 17.6324 16.8034 17.6199 17.7526 18.5934L22.6308 23.6242L29.2632 13.8829C27.3228 12.0928 24.7875 11 22 11C15.9263 11 11 16.1502 11 22.5C11 28.8498 15.9263 34 22 34C28.0737 34 33 28.8498 33 22.5C33 20.9863 32.7116 19.5418 32.201 18.2165L24.9017 28.9377H24.9077Z",
    fill: "#10B981"
  }), __cssprop("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M33.6621 11.7761C34.3099 12.2869 34.4612 13.2825 34 14L24.2129 28.3302C23.9683 28.7108 23.5852 28.9534 23.1649 28.994C22.7446 29.0345 22.3294 28.8689 22.029 28.5408L15 21.5C14.4338 20.8817 14.4274 19.872 14.9857 19.2449C15.544 18.6179 16.4556 18.6108 17.0219 19.2291L22.8473 24.9556L31.6541 12.1504C32.1153 11.433 33.0143 11.2654 33.6621 11.7761Z",
    fill: "#64A6E7"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_6413_28937"
  }, __cssprop("rect", {
    width: "28",
    height: "27",
    fill: "white",
    transform: "translate(8 8.5)"
  }))));
};
export default SuccessIcon;
