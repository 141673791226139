var _excluded = ["label", "padding", "margin", "height", "width", "onClick", "bgColor", "color", "endIcon", "disabled", "loading", "textCenter", "borderRadius", "hoverBg", "children", "fontSize", "hoverOpacity", "fontWeight"];
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
function _objectWithoutProperties(source, excluded) {
  if (source == null)
    return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0)
        continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key))
        continue;
      target[key] = source[key];
    }
  }
  return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null)
    return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
      continue;
    target[key] = source[key];
  }
  return target;
}
import Spinner from "components/Spinner/Spinner";
import React from "react";
import { StyledCustomButton } from "./CustomButton.style";
import { jsx as __cssprop } from "@emotion/react";
function CustomButton(_ref) {
  var label = _ref.label, padding = _ref.padding, margin = _ref.margin, height = _ref.height, width = _ref.width, onClick = _ref.onClick, bgColor = _ref.bgColor, color = _ref.color, endIcon = _ref.endIcon, disabled = _ref.disabled, loading = _ref.loading, textCenter = _ref.textCenter, borderRadius = _ref.borderRadius, hoverBg = _ref.hoverBg, children = _ref.children, fontSize = _ref.fontSize, _ref$hoverOpacity = _ref.hoverOpacity, hoverOpacity = _ref$hoverOpacity === void 0 ? false : _ref$hoverOpacity, _ref$fontWeight = _ref.fontWeight, fontWeight = _ref$fontWeight === void 0 ? "normal" : _ref$fontWeight, props = _objectWithoutProperties(_ref, _excluded);
  return __cssprop(StyledCustomButton, _extends({
    color,
    bgColor,
    padding,
    height,
    width,
    onClick,
    disabled,
    textCenter,
    borderRadius,
    hoverBg,
    fontSize,
    fontWeight,
    margin,
    hoverOpacity
  }, props), children ? children : __cssprop(React.Fragment, null, loading && __cssprop(Spinner, {
    size: "18px"
  }), label, endIcon && endIcon));
}
export default CustomButton;
