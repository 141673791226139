import { jsx as __cssprop } from "@emotion/react";
var ArrowRightTail = function ArrowRightTail2(_ref) {
  var _ref$w = _ref.w, w = _ref$w === void 0 ? "18px" : _ref$w, _ref$h = _ref.h, h = _ref$h === void 0 ? "18px" : _ref$h;
  return __cssprop("svg", {
    width: w,
    height: h,
    viewBox: "0 0 18 18",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    id: "Icons"
  }, __cssprop("path", {
    id: "Vector",
    d: "M13.7316 8.24952L9.34289 4.22652L10.4998 3.16602L16.8636 8.99952L10.4998 14.833L9.34289 13.7725L13.7316 9.74952H3.77271V8.24952H13.7316Z",
    fill: "white"
  })));
};
export default ArrowRightTail;
