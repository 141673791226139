var _templateObject;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import styled from "styled-components";
export var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "marginTop": "20px",
  "display": "flex",
  "width": "100%",
  "justifyContent": "flex-end"
});
