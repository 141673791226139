import { colors } from "theme/base/colors";
import { jsx as __cssprop } from "@emotion/react";
var HorizontalGreenLine = function HorizontalGreenLine2(_ref) {
  var _ref$color = _ref.color, color = _ref$color === void 0 ? colors.success600 : _ref$color;
  return __cssprop("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "75",
    height: "5",
    viewBox: "0 0 75 5",
    fill: "none"
  }, __cssprop("path", {
    d: "M0.455078 2.45508H74.1371",
    stroke: color,
    strokeWidth: "3.53605"
  }));
};
export default HorizontalGreenLine;
