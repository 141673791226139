var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { baseTheme } from "theme/base";
export var Title = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "inline-block",
  "minWidth": "min-content",
  "whiteSpace": "nowrap",
  "textAlign": "center",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))",
  ":hover": {
    "cursor": "pointer",
    "opacity": "0.6",
    "transitionProperty": "opacity",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "150ms"
  },
  "@media (min-width: 1280px)": {
    "fontSize": "16px",
    "lineHeight": "24px",
    "fontWeight": "400"
  }
});
export var NavbarStack = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "gap": "24px",
  "@media (min-width: 768px)": {
    "flexDirection": "row"
  }
});
export var NavbarLink = styled(NavLink)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  &.active {\n    border-bottom: 2px solid ", ";\n  }\n"])), baseTheme.colors.success500);
