import { jsx as __cssprop } from "@emotion/react";
var RejectedUsersIcon = function RejectedUsersIcon2() {
  return __cssprop("svg", {
    width: "119",
    height: "88",
    viewBox: "0 0 119 88",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_5335_23804)"
  }, __cssprop("path", {
    opacity: "0.3",
    d: "M99.6233 82.2152H115.753V75.6752C115.753 75.6752 104.263 64.6652 98.3133 64.0152V54.4852C98.3133 54.4852 104.653 51.2552 104.653 37.1852C104.653 16.1652 83.1333 18.9152 83.1333 18.9152C83.1333 18.9152 92.4633 37.1852 77.3333 51.6452L81.5633 54.5952C81.5633 54.5952 85.8933 52.8352 91.7933 56.1652C100.383 61.0252 97.5633 73.7152 97.5633 73.7152L97.6333 82.1952L99.6333 82.2152H99.6233Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M21.8933 86.7949H96.5433V74.4349C96.5433 74.4349 90.3633 84.8549 78.8333 80.2949C75.8333 78.7949 67.3333 72.2949 72.9633 60.3749C71.0533 59.4149 70.2633 59.1349 68.7633 58.7049C68.7633 58.7049 80.9033 45.6749 80.9033 26.5549C80.9033 7.43492 67.0433 0.794922 59.2233 0.794922C50.0233 0.794922 37.2233 7.45492 37.2233 26.5749C37.2233 45.6949 49.9533 58.7249 49.9533 58.7249C42.4933 61.3249 21.9033 75.5149 21.9033 75.5149V86.8149V86.7949H21.8933Z",
    fill: "#E11D48"
  }), __cssprop("path", {
    d: "M84.6968 56.1895C78.342 56.1895 73.1968 61.3347 73.1968 67.6895C73.1968 74.0442 78.342 79.1895 84.6968 79.1895C91.0516 79.1895 96.1968 74.0442 96.1968 67.6895C96.1968 61.3347 91.0516 56.1895 84.6968 56.1895ZM89.7955 71.6903C90.1583 72.0531 90.1583 72.6393 89.7955 73.0022C89.6094 73.1882 89.3768 73.272 89.1349 73.272C88.893 73.272 88.6604 73.1789 88.4743 73.0022L84.6968 69.2246L80.9193 73.0022C80.7332 73.1882 80.5006 73.272 80.2587 73.272C80.0168 73.272 79.7842 73.1789 79.5981 73.0022C79.2352 72.6393 79.2352 72.0531 79.5981 71.6903L83.3756 67.9128L79.5981 64.1352C79.2352 63.7724 79.2352 63.1862 79.5981 62.8234C79.9609 62.4605 80.5471 62.4605 80.91 62.8234L84.6875 66.6009L88.465 62.8234C88.8278 62.4605 89.414 62.4605 89.7769 62.8234C90.1397 63.1862 90.1397 63.7724 89.7769 64.1352L85.9994 67.9128L89.7769 71.6903H89.7955Z",
    fill: "#E11D48"
  }), __cssprop("path", {
    opacity: "0.3",
    d: "M17.4633 82.2152H1.33325V75.6752C1.33325 75.6752 12.8233 64.6652 18.7733 64.0152V54.4852C18.7733 54.4852 12.4333 51.2552 12.4333 37.1852C12.4333 16.1652 33.9533 18.9152 33.9533 18.9152C33.9533 18.9152 26.8333 35.0052 39.7533 51.6452L35.5733 54.3652V61.7252L17.4533 73.7052V82.2152H17.4633Z",
    fill: "white"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_5335_23804"
  }, __cssprop("rect", {
    width: "118",
    height: "86.41",
    fill: "white",
    transform: "translate(0.333252 0.794922)"
  }))));
};
export default RejectedUsersIcon;
