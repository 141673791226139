import { jsx as __cssprop } from "@emotion/react";
var NftIcon = function NftIcon2(_ref) {
  var _ref$w = _ref.w, w = _ref$w === void 0 ? "40px" : _ref$w, _ref$h = _ref.h, h = _ref$h === void 0 ? "40px" : _ref$h, _ref$opacity = _ref.opacity, opacity = _ref$opacity === void 0 ? "1.0" : _ref$opacity;
  return __cssprop("svg", {
    width: w,
    height: h,
    opacity,
    viewBox: "0 0 41 43",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("ellipse", {
    cx: "20.1388",
    cy: "21.4993",
    rx: "20.1388",
    ry: "20.6687",
    fill: "#D9D9D9"
  }), __cssprop("g", {
    clipPath: "url(#clip0_2493_9470)"
  }, __cssprop("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19.9502 10.9549C14.0215 10.9549 9.21565 15.7607 9.21565 21.6894C9.21565 27.6181 14.0215 32.4239 19.9502 32.4239C25.8788 32.4239 30.6847 27.6181 30.6847 21.6894C30.6847 19.4247 29.9843 17.3263 28.7881 15.5948C28.5745 15.2857 28.652 14.862 28.9611 14.6484C29.2702 14.4348 29.694 14.5123 29.9076 14.8214C31.2558 16.7728 32.0453 19.1396 32.0453 21.6894C32.0453 28.3696 26.6303 33.7846 19.9502 33.7846C13.27 33.7846 7.85498 28.3696 7.85498 21.6894C7.85498 15.0093 13.27 9.59424 19.9502 9.59424C21.1507 9.59424 22.3101 9.76956 23.4061 10.0953C23.7663 10.2023 23.9715 10.581 23.8645 10.9412C23.7575 11.3014 23.3787 11.5066 23.0186 11.3996C22.0464 11.1107 21.0174 10.9549 19.9502 10.9549Z",
    fill: "#059669"
  }), __cssprop("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M26.8611 9.59424C27.1792 9.59424 27.4371 9.82083 27.4371 10.1004V14.8477C27.4371 15.1273 27.1792 15.3538 26.8611 15.3538C26.543 15.3538 26.2852 15.1273 26.2852 14.8477V10.1004C26.2852 9.82083 26.543 9.59424 26.8611 9.59424Z",
    fill: "#059669"
  }), __cssprop("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M23.9819 12.4739C23.9819 12.1558 24.214 11.8979 24.5002 11.8979H29.2233C29.5095 11.8979 29.7415 12.1558 29.7415 12.4739C29.7415 12.792 29.5095 13.0499 29.2233 13.0499H24.5002C24.214 13.0499 23.9819 12.792 23.9819 12.4739Z",
    fill: "#059669"
  }), __cssprop("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.2804 22.2355C13.0134 21.9684 13.0134 21.5355 13.2804 21.2684L19.4432 15.1056C19.7103 14.8386 20.1433 14.8386 20.4103 15.1056L26.5731 21.2684C26.8402 21.5355 26.8402 21.9684 26.5731 22.2355L20.4103 28.3983C20.1433 28.6653 19.7103 28.6653 19.4432 28.3983L13.2804 22.2355ZM14.7311 21.752L19.9268 26.9477L25.1225 21.752L19.9268 16.5563L14.7311 21.752Z",
    fill: "#059669"
  })), __cssprop("rect", {
    x: "20.1387",
    y: "15.3003",
    width: "8.7666",
    height: "9.21582",
    transform: "rotate(45 20.1387 15.3003)",
    fill: "#059669"
  }), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_2493_9470"
  }, __cssprop("rect", {
    width: "25.3423",
    height: "25.3423",
    fill: "white",
    transform: "translate(7.85498 9.59424)"
  }))));
};
export default NftIcon;
