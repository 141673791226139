import { jsx as __cssprop } from "@emotion/react";
var LinkedinIcon = function LinkedinIcon2() {
  return __cssprop("svg", {
    width: "44",
    height: "44",
    viewBox: "0 0 44 44",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_3948_12783)"
  }, __cssprop("path", {
    d: "M22.3033 43.2198C34.2143 43.2198 43.8702 33.5639 43.8702 21.6529C43.8702 9.74178 34.2143 0.0859375 22.3033 0.0859375C10.3922 0.0859375 0.736328 9.74178 0.736328 21.6529C0.736328 33.5639 10.3922 43.2198 22.3033 43.2198Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M23.9713 18.7102C24.3259 18.3108 24.6167 17.9082 24.9809 17.5664C26.0992 16.512 27.422 15.9816 28.9684 15.9944C29.8183 16.0008 30.6554 16.0615 31.4734 16.2979C33.3457 16.8379 34.432 18.1287 34.9528 19.9563C35.3426 21.327 35.4129 22.736 35.4161 24.1483C35.4225 27.1261 35.4065 30.1071 35.4161 33.085C35.4161 33.3629 35.3395 33.4364 35.0647 33.4364C33.531 33.4236 31.9942 33.4236 30.4605 33.4364C30.1889 33.4364 30.1314 33.3565 30.1314 33.1009C30.141 30.2669 30.141 27.4328 30.1314 24.5956C30.1314 23.8863 30.0835 23.1738 29.8854 22.4836C29.518 21.212 28.6074 20.5634 27.275 20.6337C25.4538 20.7295 24.5081 21.6305 24.278 23.4805C24.2237 23.9214 24.1949 24.3655 24.1981 24.8096C24.1981 27.567 24.1981 30.3244 24.2045 33.0818C24.2045 33.3565 24.1374 33.4364 23.8563 33.4364C22.3098 33.4236 20.7634 33.4236 19.217 33.4364C18.9678 33.4364 18.8975 33.3725 18.8975 33.1201C18.9039 27.6629 18.9039 22.2024 18.8975 16.7452C18.8975 16.4736 18.9869 16.4129 19.2425 16.4129C20.7091 16.4225 22.1788 16.4257 23.6454 16.4129C23.917 16.4129 23.9841 16.4992 23.9809 16.7548C23.9649 17.4066 23.9745 18.0584 23.9745 18.7134L23.9713 18.7102Z",
    fill: "#FEFEFE"
  }), __cssprop("path", {
    d: "M15.5624 24.9475C15.5624 27.6473 15.5592 30.3472 15.5688 33.047C15.5688 33.3474 15.4953 33.4368 15.1854 33.4337C13.6517 33.4177 12.1181 33.4209 10.5812 33.4337C10.3352 33.4337 10.2617 33.3729 10.2617 33.1205C10.2681 27.6537 10.2681 22.1869 10.2617 16.7169C10.2617 16.49 10.316 16.4102 10.5557 16.4102C12.1117 16.4197 13.6677 16.4229 15.2237 16.4102C15.524 16.4102 15.5624 16.522 15.5624 16.7808C15.556 19.503 15.5592 22.2252 15.5592 24.9475H15.5624Z",
    fill: "#FEFEFE"
  }), __cssprop("path", {
    d: "M15.9869 10.9927C15.9869 12.6893 14.613 14.0696 12.9196 14.0696C11.2486 14.0696 9.86188 12.6893 9.85549 11.0183C9.8491 9.33128 11.2358 7.94141 12.926 7.94141C14.6034 7.94141 15.9837 9.31849 15.9869 10.9895V10.9927Z",
    fill: "#FEFEFE"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_3948_12783"
  }, __cssprop("rect", {
    width: "43.1339",
    height: "43.1339",
    fill: "white",
    transform: "translate(0.736328 0.0859375)"
  }))));
};
export default LinkedinIcon;
