import { jsx as __cssprop } from "@emotion/react";
var LinkedinSquare = function LinkedinSquare2() {
  return __cssprop("svg", {
    width: "61",
    height: "61",
    viewBox: "0 0 61 61",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("rect", {
    width: "61",
    height: "61",
    rx: "6",
    fill: "#D9D9D9"
  }), __cssprop("rect", {
    width: "61",
    height: "61",
    rx: "6",
    fill: "#007AB5"
  }), __cssprop("path", {
    d: "M17.9426 24.4903H23.8623V43.5314H17.9426V24.4903ZM20.9129 15.0325C22.8164 15.0325 24.3434 16.5599 24.3434 18.4641C24.3434 20.3682 22.8164 21.8956 20.9129 21.8956C19.0094 21.8956 17.4824 20.3472 17.4824 18.4641C17.4824 16.5809 19.0094 15.0325 20.9129 15.0325Z",
    fill: "white"
  }), __cssprop("path", {
    d: "M27.5873 24.4901H33.256V27.0847H33.3397C34.1346 25.5781 36.059 24.0088 38.9456 24.0088C44.9281 24.0088 46.0368 27.9426 46.0368 33.069V43.5102H40.117V34.2617C40.117 32.0437 40.0752 29.2189 37.0421 29.2189C34.0091 29.2189 33.4861 31.6252 33.4861 34.1152V43.5312H27.5664V24.4901H27.5873Z",
    fill: "white"
  }));
};
export default LinkedinSquare;
