import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  roles: [],
  allChecks: [],
  rolesForCodeExample: [],
  templateRoles: null
};
var rolesReducer = createSlice({
  name: "ROLES",
  initialState,
  reducers: {
    setRoles: function setRoles(state, _ref) {
      var payload = _ref.payload;
      state.roles[payload.index] = payload.roles;
    },
    setAllChecks: function setAllChecks(state, _ref2) {
      var payload = _ref2.payload;
      state.allChecks[payload.index] = payload.checks;
    },
    setRolesForCodeExample: function setRolesForCodeExample(state, _ref3) {
      var payload = _ref3.payload;
      state.rolesForCodeExample = payload;
    },
    setTemplateRoles: function setTemplateRoles(state, _ref4) {
      var payload = _ref4.payload;
      state.templateRoles = payload;
    }
  }
});
var _rolesReducer$actions = rolesReducer.actions, setRoles2 = _rolesReducer$actions.setRoles, setTemplateRoles2 = _rolesReducer$actions.setTemplateRoles, setRolesForCodeExample2 = _rolesReducer$actions.setRolesForCodeExample, setAllChecks2 = _rolesReducer$actions.setAllChecks;
export { setRoles2 as setRoles, setTemplateRoles2 as setTemplateRoles, setRolesForCodeExample2 as setRolesForCodeExample, setAllChecks2 as setAllChecks };
export default rolesReducer.reducer;
