function _typeof(obj) {
  "@babel/helpers - typeof";
  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(obj2) {
    return typeof obj2;
  } : function(obj2) {
    return obj2 && "function" == typeof Symbol && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
  }, _typeof(obj);
}
function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o)
    return;
  if (typeof o === "string")
    return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor)
    n = o.constructor.name;
  if (n === "Map" || n === "Set")
    return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length)
    len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i];
  return arr2;
}
function _iterableToArrayLimit(arr, i) {
  var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"];
  if (null != _i) {
    var _s, _e, _x, _r, _arr = [], _n = true, _d = false;
    try {
      if (_x = (_i = _i.call(arr)).next, 0 === i) {
        if (Object(_i) !== _i)
          return;
        _n = false;
      } else
        for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = true)
          ;
    } catch (err) {
      _d = true, _e = err;
    } finally {
      try {
        if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r))
          return;
      } finally {
        if (_d)
          throw _e;
      }
    }
    return _arr;
  }
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr))
    return arr;
}
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function(sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), true).forEach(function(key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function(key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, { value, enumerable: true, configurable: true, writable: true });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return _typeof(key) === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== "object" || input === null)
    return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== void 0) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
      return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
import { createContext, useMemo, useState } from "react";
import { colors } from "theme/base/colors";
import { jsx as __cssprop } from "@emotion/react";
var defaultColors = {
  bgColor: colors.dashboardBg,
  primaryColor: "#1E293B",
  textColor: colors.white200,
  buttonTextColor: colors.white200
};
var IFrameContext = /* @__PURE__ */ createContext(_objectSpread(_objectSpread({}, defaultColors), {}, {
  iframeCustomization: defaultColors,
  isIframe: false,
  iframeAddress: "",
  checksIds: [],
  roles: [],
  signature: "",
  polygonId: "",
  nftClaimLinks: {},
  checkCallback: function checkCallback2() {
  },
  setBgColor: function setBgColor2() {
  },
  setButtonTextColor: function setButtonTextColor2() {
  },
  setTextColor: function setTextColor2() {
  },
  setPrimaryColor: function setPrimaryColor2() {
  }
}));
var IFrameContextProvider = function IFrameContextProvider(_ref) {
  var _query$get, _query$get2, _query$get3, _query$get4, _query$get5, _query$get6, _query$get7, _query$get$split, _query$get8, _query$get$split2, _query$get9, _query$get10, _query$get11;
  var children = _ref.children;
  var search = document.location.search;
  var query = useMemo(function() {
    return new URLSearchParams(search);
  }, [search]);
  var _useState = useState((_query$get = query.get("bgColor")) !== null && _query$get !== void 0 ? _query$get : colors.dashboardBg), _useState2 = _slicedToArray(_useState, 2), bgColor = _useState2[0], setBgColor = _useState2[1];
  var _useState3 = useState((_query$get2 = query.get("primaryColor")) !== null && _query$get2 !== void 0 ? _query$get2 : colors.success600), _useState4 = _slicedToArray(_useState3, 2), primaryColor = _useState4[0], setPrimaryColor = _useState4[1];
  var _useState5 = useState((_query$get3 = query.get("textColor")) !== null && _query$get3 !== void 0 ? _query$get3 : colors.white200), _useState6 = _slicedToArray(_useState5, 2), textColor = _useState6[0], setTextColor = _useState6[1];
  var _useState7 = useState((_query$get4 = query.get("buttonTextColor")) !== null && _query$get4 !== void 0 ? _query$get4 : colors.white200), _useState8 = _slicedToArray(_useState7, 2), buttonTextColor = _useState8[0], setButtonTextColor = _useState8[1];
  var isIframe = query.get("isIframe") === "true";
  var polygonId = (_query$get5 = query.get("polygonId")) !== null && _query$get5 !== void 0 ? _query$get5 : "";
  var iframeAddress = (_query$get6 = query.get("address")) !== null && _query$get6 !== void 0 ? _query$get6 : "";
  var signature = (_query$get7 = query.get("signature")) !== null && _query$get7 !== void 0 ? _query$get7 : "";
  var checksIds = (_query$get$split = (_query$get8 = query.get("checksIds")) === null || _query$get8 === void 0 ? void 0 : _query$get8.split(",")) !== null && _query$get$split !== void 0 ? _query$get$split : [];
  var roles = (_query$get$split2 = (_query$get9 = query.get("roles")) === null || _query$get9 === void 0 ? void 0 : _query$get9.split(",")) !== null && _query$get$split2 !== void 0 ? _query$get$split2 : [];
  var nftClaimLinks = JSON.parse((_query$get10 = query.get("nftClaimLinks")) !== null && _query$get10 !== void 0 ? _query$get10 : "{}");
  var checkCallback = eval((_query$get11 = query.get("checkCallback")) !== null && _query$get11 !== void 0 ? _query$get11 : "");
  var values = {
    checksIds,
    roles,
    checkCallback,
    nftClaimLinks,
    polygonId,
    bgColor,
    primaryColor,
    signature,
    textColor,
    iframeAddress,
    buttonTextColor,
    iframeCustomization: {
      bgColor,
      primaryColor,
      textColor,
      buttonTextColor
    },
    isIframe
  };
  var setters = {
    setBgColor,
    setButtonTextColor,
    setPrimaryColor,
    setTextColor
  };
  return __cssprop(IFrameContext.Provider, {
    value: _objectSpread(_objectSpread({}, setters), values)
  }, children);
};
export { IFrameContext, IFrameContextProvider };
