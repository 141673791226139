var _excluded = ["background", "w", "h"];
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
function _objectWithoutProperties(source, excluded) {
  if (source == null)
    return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0)
        continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key))
        continue;
      target[key] = source[key];
    }
  }
  return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null)
    return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
      continue;
    target[key] = source[key];
  }
  return target;
}
import { jsx as __cssprop } from "@emotion/react";
var ShieldUser = function ShieldUser2(_ref) {
  var _ref$background = _ref.background, background = _ref$background === void 0 ? "#10B981" : _ref$background, _ref$w = _ref.w, w = _ref$w === void 0 ? "32" : _ref$w, _ref$h = _ref.h, h = _ref$h === void 0 ? "32" : _ref$h, props = _objectWithoutProperties(_ref, _excluded);
  return __cssprop("svg", _extends({}, props, {
    width: w,
    height: h,
    viewBox: "0 0 32 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }), __cssprop("path", {
    d: "M16 17.3332C17.8409 17.3332 19.3333 15.8408 19.3333 13.9998C19.3333 12.1589 17.8409 10.6665 16 10.6665C14.159 10.6665 12.6667 12.1589 12.6667 13.9998C12.6667 15.8408 14.159 17.3332 16 17.3332Z",
    stroke: background,
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M26.6667 16.6944C26.6667 21.7273 20.4735 25.4661 17.5202 26.9672C17.0504 27.2073 16.5304 27.3328 16.0028 27.3332C15.4752 27.3337 14.9551 27.2091 14.4849 26.9698C11.5335 25.4701 5.33334 21.7222 5.33334 16.6944V9.42566C5.33808 9.0965 5.46296 8.78041 5.68449 8.53689C5.90601 8.29337 6.20889 8.13921 6.53614 8.10342C9.36536 7.88843 12.0778 6.88532 14.3661 5.20774C14.8384 4.85631 15.4113 4.6665 16 4.6665C16.5886 4.6665 17.1616 4.85631 17.6338 5.20774C19.9222 6.88532 22.6346 7.88843 25.4638 8.10342C25.7911 8.1392 26.094 8.29336 26.3155 8.53688C26.537 8.7804 26.6619 9.09649 26.6667 9.42566V16.6944Z",
    stroke: background,
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M22.5343 23.7351L21.9213 22.5092C21.6445 21.9555 21.219 21.4898 20.6924 21.1643C20.1658 20.8389 19.559 20.6665 18.9399 20.6665H13.0601C12.4411 20.6665 11.8343 20.8389 11.3077 21.1643C10.7811 21.4898 10.3555 21.9555 10.0787 22.5092L9.46884 23.7289",
    stroke: background,
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
};
export default ShieldUser;
