function _typeof(obj) {
  "@babel/helpers - typeof";
  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(obj2) {
    return typeof obj2;
  } : function(obj2) {
    return obj2 && "function" == typeof Symbol && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
  }, _typeof(obj);
}
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function(sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), true).forEach(function(key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function(key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, { value, enumerable: true, configurable: true, writable: true });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return _typeof(key) === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== "object" || input === null)
    return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== void 0) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
      return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
import { driver } from "driver.js";
import { tutorialsCommonProps } from "./tutorialsCommonProps";
var useCustomizationTutorial = function useCustomizationTutorial2() {
  var steps = [{
    element: "#playground_box_id",
    popover: {
      title: "Let's start!",
      description: "Get started with the customization tour!",
      side: "over",
      align: "center"
    }
  }, {
    element: "#customization_id",
    popover: {
      title: "Customization.",
      description: "Here you can customize the access control."
    }
  }, {
    element: "#website_input_customization",
    popover: {
      title: "Set your website URL.",
      description: "Preview how this would look on your own website."
    }
  }, {
    element: "#primary_color_box",
    popover: {
      title: "Set your website colors.",
      description: "Change color scheme to match with your theme."
    }
  }, {
    element: "#customization_result",
    popover: {
      title: "Preview.",
      description: "View a preview before apply your theme."
    }
  }, {
    element: "#customization_apply_button",
    popover: {
      title: "Apply you theme.",
      description: "Click Apply for customization to take effect and go back to access control screen.",
      onNextClick: function onNextClick() {
        localStorage.setItem("hasSeenCustomizationTutorial", "true");
        customizationTutorial.destroy();
      }
    }
  }];
  var hasSeenCustomizationTutorial = localStorage.getItem("hasSeenCustomizationTutorial") === "true";
  var onCloseClick = function onCloseClick2() {
    localStorage.setItem("hasSeenCustomizationTutorial", "true");
    customizationTutorial.destroy();
  };
  var customizationTutorial = driver(_objectSpread(_objectSpread({}, tutorialsCommonProps), {}, {
    steps,
    onCloseClick
  }));
  return {
    customizationTutorial,
    hasSeenCustomizationTutorial
  };
};
export default useCustomizationTutorial;
