import { jsx as __cssprop } from "@emotion/react";
var SafariDots = function SafariDots2() {
  return __cssprop("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "53",
    height: "13",
    viewBox: "0 0 53 13",
    fill: "none"
  }, __cssprop("circle", {
    cx: "6.31494",
    cy: "6.43945",
    r: "6",
    fill: "#ED6B5D"
  }), __cssprop("circle", {
    cx: "26.3149",
    cy: "6.43945",
    r: "6",
    fill: "#F4BE50"
  }), __cssprop("circle", {
    cx: "46.3149",
    cy: "6.43945",
    r: "6",
    fill: "#61C554"
  }));
};
export default SafariDots;
