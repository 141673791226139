import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  address: "",
  provider: void 0,
  signer: void 0
};
var walletDetailsReducer = createSlice({
  name: "WALLET_DETAILS",
  initialState,
  reducers: {
    setWalletDetails: function setWalletDetails(state, _ref) {
      var payload = _ref.payload;
      state.address = payload.address;
      state.provider = payload.provider;
      state.signer = payload.signer;
    },
    removeWalletDetails: function removeWalletDetails(state) {
      state.address = "";
      state.provider = void 0;
      state.signer = void 0;
    }
  }
});
var _walletDetailsReducer = walletDetailsReducer.actions, setWalletDetails2 = _walletDetailsReducer.setWalletDetails, removeWalletDetails2 = _walletDetailsReducer.removeWalletDetails;
export { setWalletDetails2 as setWalletDetails, removeWalletDetails2 as removeWalletDetails };
export default walletDetailsReducer.reducer;
