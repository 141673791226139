import { jsx as __cssprop } from "@emotion/react";
var Clock = function Clock2(_ref) {
  var _ref$color = _ref.color, color = _ref$color === void 0 ? "#10B981" : _ref$color, _ref$h = _ref.h, h = _ref$h === void 0 ? "20" : _ref$h, _ref$w = _ref.w, w = _ref$w === void 0 ? "20" : _ref$w;
  return __cssprop("svg", {
    width: w,
    height: h,
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("path", {
    d: "M13.8675 13.3333L9.76584 10.8866V5.61328",
    stroke: color,
    strokeWidth: "1.25",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M15.3033 4.6967C18.2323 7.62563 18.2323 12.3744 15.3033 15.3033C12.3744 18.2322 7.62568 18.2322 4.69676 15.3033C1.76783 12.3743 1.76783 7.62562 4.69676 4.6967C7.62569 1.76777 12.3744 1.76777 15.3033 4.6967",
    stroke: color,
    strokeWidth: "1.25",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
};
export default Clock;
