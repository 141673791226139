export var colorValues = {
  white: {
    base: "#ffffff",
    50: "#f8fafc",
    100: "#f1f5f9",
    200: "#e2e8f0",
    300: "#cbd5e1",
    400: "#94a3b8",
    500: "#64748b",
    600: "#475569",
    700: "#334155",
    800: "#1e293b",
    900: "#141724",
    transparent: "rgba(51, 65, 85, 0.3)",
    transparent30: "rgba(51, 65, 85, 0.3)",
    transparent60: "rgba(51, 65, 85, 0.6)"
  },
  black: {
    base: "#000000",
    gray: "#AEB0B1",
    fields: "#1F2E44",
    checks: "#2D3A4C"
  },
  grey: {
    greyBase: "#AEB0B1"
  },
  success: {
    50: "#10B98180",
    100: "#d1fae5",
    200: "#a7f3d0",
    300: "#7ce9bb",
    400: "#51e3a6",
    500: "#10b981",
    600: "#059669",
    700: "#047857",
    800: "rgba(6, 78, 59, 0.3)",
    900: "rgba(6, 78, 59, 0.6)"
  },
  error: {
    50: "#fff1f2",
    100: "#ffe4e6",
    200: "#E16961",
    600: "#e11d48",
    800: "#9f1239"
  },
  warn: {
    50: "#fffbeb",
    100: "#fef3c7",
    500: "#f59e0b",
    700: "#b45309"
  },
  blue: {
    100: "#64A6E7"
  }
};
var white = colorValues.white, success = colorValues.success, error = colorValues.error, warn = colorValues.warn, black = colorValues.black, grey = colorValues.grey, blue = colorValues.blue;
export var colors = {
  white: white.base,
  black: black.base,
  checks: black.checks,
  grey: grey.greyBase,
  white50: white[50],
  white100: white[100],
  white200: white[200],
  white300: white[300],
  white400: white[400],
  white500: white[500],
  white600: white[600],
  white700: white[700],
  white800: white[800],
  white900: white[900],
  whiteTransparent: white.transparent,
  whiteTransparent30: white.transparent30,
  whiteTransparent60: white.transparent60,
  success50: success[50],
  success100: success[100],
  success200: success[200],
  success300: success[300],
  success400: success[400],
  success500: success[500],
  success600: success[600],
  success700: success[700],
  success800: success[800],
  success900: success[900],
  successDisable: "#1D9384",
  fields: black.fields,
  error50: error[50],
  error100: error[100],
  error200: error[200],
  error600: error[600],
  error800: error[800],
  warn50: warn[50],
  warn100: warn[100],
  warn500: warn[500],
  warn700: warn[700],
  dashboardBg: "#141724",
  navbar: "#191f28",
  blue100: blue[100],
  disabledText: "#FFFFFF80",
  ghostBg: "#475569",
  disabledBg: "#2D3A4C80"
};
