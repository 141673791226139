import { jsx as __cssprop } from "@emotion/react";
var WarningIconRed = function WarningIconRed2() {
  return __cssprop("svg", {
    width: "18",
    height: "18",
    viewBox: "0 0 22 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    id: "Icons"
  }, __cssprop("path", {
    id: "Vector",
    d: "M9.7749 14.773H11.7749V16.773H9.7749V14.773ZM9.7749 6.77301H11.7749V12.773H9.7749V6.77301ZM10.7749 1.77301C5.2449 1.77301 0.774902 6.27301 0.774902 11.773C0.774902 14.4252 1.82847 16.9687 3.70383 18.8441C4.63242 19.7727 5.73481 20.5093 6.94807 21.0118C8.16132 21.5144 9.46168 21.773 10.7749 21.773C13.4271 21.773 15.9706 20.7194 17.846 18.8441C19.7213 16.9687 20.7749 14.4252 20.7749 11.773C20.7749 10.4598 20.5162 9.15943 20.0137 7.94618C19.5112 6.73292 18.7746 5.63053 17.846 4.70194C16.9174 3.77336 15.815 3.03676 14.6017 2.53421C13.3885 2.03167 12.0881 1.77301 10.7749 1.77301ZM10.7749 19.773C8.65317 19.773 6.61834 18.9302 5.11805 17.4299C3.61776 15.9296 2.7749 13.8947 2.7749 11.773C2.7749 9.65128 3.61776 7.61645 5.11805 6.11616C6.61834 4.61587 8.65317 3.77301 10.7749 3.77301C12.8966 3.77301 14.9315 4.61587 16.4318 6.11616C17.932 7.61645 18.7749 9.65128 18.7749 11.773C18.7749 13.8947 17.932 15.9296 16.4318 17.4299C14.9315 18.9302 12.8966 19.773 10.7749 19.773Z",
    fill: "#E11D48"
  })));
};
export default WarningIconRed;
