import { jsx as __cssprop } from "@emotion/react";
var TotalUsersIcon = function TotalUsersIcon2() {
  return __cssprop("svg", {
    width: "119",
    height: "87",
    viewBox: "0 0 119 87",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_5335_23783)"
  }, __cssprop("path", {
    d: "M21.8983 86.9136H96.5498V75.6138C96.5498 75.6138 77.7291 61.4222 68.768 58.822C68.768 58.822 80.9104 45.7969 80.9104 26.6723C80.9104 7.54765 67.0438 0.913574 59.224 0.913574C50.0201 0.913574 37.222 7.57195 37.222 26.6966C37.222 45.8212 49.9472 58.8463 49.9472 58.8463C42.4918 61.4465 21.8983 75.6381 21.8983 75.6381V86.9379V86.9136Z",
    fill: "#64A6E7"
  }), __cssprop("path", {
    d: "M16.4584 82.1505H0.333313V75.6136C0.333313 75.6136 11.82 64.6055 17.7698 63.9493V54.4235C17.7698 54.4235 11.4315 51.1915 11.4315 37.1214C11.4315 16.1013 32.9478 18.8473 32.9478 18.8473C32.9478 18.8473 25.8324 34.9344 38.7519 51.5803L34.5749 54.302V61.6651L16.4584 73.6453V82.1262V82.1505Z",
    fill: "#059669"
  }), __cssprop("path", {
    d: "M102.208 82.1505H118.333V75.6136C118.333 75.6136 106.846 64.6055 100.896 63.9493V54.4235C100.896 54.4235 107.235 51.1915 107.235 37.1214C107.235 16.1013 85.7184 18.8473 85.7184 18.8473C85.7184 18.8473 95.045 37.1214 79.9144 51.5803L84.0914 54.302V61.6651L102.208 73.6453V82.1262V82.1505Z",
    fill: "#059669"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_5335_23783"
  }, __cssprop("rect", {
    width: "118",
    height: "86",
    fill: "white",
    transform: "translate(0.333313 0.913574)"
  }))));
};
export default TotalUsersIcon;
