import DoneIcon from "@mui/icons-material/Done";
import { MetamaskIcon, WalletConnect } from "assets/icons";
import { CloverWalletSVG, WarningRedSVG } from "assets/svg";
import PopoverHelper from "components/PopoverHelper";
import { baseTheme } from "theme/base";
import { ItemInfo, ItemName, ListContainer, WalletItemWrapper } from "./styles";
import { jsx as __cssprop } from "@emotion/react";
var colors = baseTheme.colors;
var WalletsListModal = function WalletsListModal2(_ref) {
  var setErrorMessage = _ref.setErrorMessage, setWalletID = _ref.setWalletID, walletID = _ref.walletID, errorMessage = _ref.errorMessage;
  var WALLETS = [{
    svg: __cssprop(MetamaskIcon, null),
    ID: "Metamask"
  }, {
    svg: __cssprop(WalletConnect, null),
    ID: "Wallet Connect"
  }, {
    svg: __cssprop(CloverWalletSVG, null),
    ID: "Clover wallet"
  }];
  return __cssprop(ListContainer, null, WALLETS.map(function(value) {
    return __cssprop(WalletItemWrapper, {
      isSelected: value.ID === walletID,
      onClick: function onClick() {
        setWalletID(value.ID);
        setErrorMessage && setErrorMessage("");
      },
      key: value.ID
    }, __cssprop(ItemInfo, null, value.svg, __cssprop(ItemName, null, value.ID)), value.ID === walletID && !errorMessage && __cssprop(DoneIcon, {
      color: "success"
    }), value.ID === walletID && errorMessage && __cssprop(PopoverHelper, {
      message: errorMessage,
      backgroundColor: colors.error600,
      icon: WarningRedSVG
    }));
  }));
};
export default WalletsListModal;
