import { jsx as __cssprop } from "@emotion/react";
var SignIcon = function SignIcon2() {
  return __cssprop("svg", {
    width: "44",
    height: "43",
    viewBox: "0 0 44 43",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_5491_22498)"
  }, __cssprop("path", {
    d: "M23.6986 17.7782L13.6135 35.5708L3 41.9513L3.21496 30.2109L14.4017 12.0811L23.6986 17.7782Z",
    fill: "#64A6E7"
  }), __cssprop("path", {
    d: "M15.8347 9.93362L25.1048 15.6308L29.1263 9.15269C29.1263 9.15269 30.622 5.75391 26.8423 3.26028C26.8423 3.26028 22.3014 0.438312 19.91 3.63299C18.4232 5.6208 15.8347 9.94249 15.8347 9.94249V9.93362Z",
    fill: "#64A6E7"
  }), __cssprop("path", {
    d: "M39.743 19.8281H28.2875C27.0459 19.8281 26.0394 20.8234 26.0394 22.0511C26.0394 23.2788 27.0459 24.2741 28.2875 24.2741H39.743C40.9846 24.2741 41.9911 23.2788 41.9911 22.0511C41.9911 20.8234 40.9846 19.8281 39.743 19.8281Z",
    fill: "#10B981"
  }), __cssprop("path", {
    d: "M39.743 27.7261H23.5674C22.3258 27.7261 21.3193 28.7213 21.3193 29.949C21.3193 31.1768 22.3258 32.172 23.5674 32.172H39.743C40.9846 32.172 41.9911 31.1768 41.9911 29.949C41.9911 28.7213 40.9846 27.7261 39.743 27.7261Z",
    fill: "#10B981"
  }), __cssprop("path", {
    d: "M39.752 35.624H18.8473C17.6058 35.624 16.5992 36.6193 16.5992 37.847C16.5992 39.0747 17.6058 40.07 18.8473 40.07H39.752C40.9935 40.07 42 39.0747 42 37.847C42 36.6193 40.9935 35.624 39.752 35.624Z",
    fill: "#10B981"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_5491_22498"
  }, __cssprop("rect", {
    width: "40",
    height: "39.8537",
    fill: "white",
    transform: "translate(2 2.09766)"
  }))));
};
export default SignIcon;
