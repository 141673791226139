import { jsx as __cssprop } from "@emotion/react";
var DiscordRoundIcon = function DiscordRoundIcon2(_ref) {
  var _ref$w = _ref.w, w = _ref$w === void 0 ? "34" : _ref$w, _ref$h = _ref.h, h = _ref$h === void 0 ? "36" : _ref$h;
  return __cssprop("svg", {
    width: w,
    height: h,
    viewBox: "0 0 34 36",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("ellipse", {
    cx: "16.9584",
    cy: "17.9598",
    rx: "15.9311",
    ry: "15.4625",
    fill: "white"
  }), __cssprop("path", {
    d: "M20.6902 16.7031C19.5904 16.7031 18.7168 17.6707 18.7168 18.8578C18.7168 20.045 19.6007 21.0026 20.6902 21.0026C21.7797 21.0026 22.6533 20.035 22.6636 18.8578C22.6739 17.6707 21.8002 16.713 20.6902 16.713V16.7031Z",
    fill: "#6171B9"
  }), __cssprop("path", {
    d: "M13.8855 16.7031C12.7857 16.7031 11.9121 17.6707 11.9121 18.8578C11.9121 20.045 12.796 21.0026 13.8855 21.0026C14.975 21.0026 15.8486 20.035 15.8589 18.8578C15.8692 17.6707 14.9955 16.713 13.8855 16.713V16.7031Z",
    fill: "#6171B9"
  }), __cssprop("path", {
    d: "M17 1.5C7.6058 1.5 0 8.89208 0 18C0 27.1179 7.61608 34.5 17 34.5C26.3942 34.5 34 27.1079 34 18C34 8.8821 26.3942 1.5 17 1.5ZM27.9256 23.4368C26.2503 24.6439 24.3694 25.5716 22.3755 26.1602C21.9232 25.5716 21.5326 24.9432 21.1832 24.2947C21.8307 24.0553 22.4577 23.766 23.0538 23.4268C22.8996 23.3171 22.7455 23.1974 22.5913 23.0777C19.0762 24.6938 15.006 24.6938 11.4909 23.0777C11.347 23.1974 11.1929 23.3071 11.0387 23.4268C11.6348 23.766 12.2618 24.0553 12.9093 24.2947C12.5701 24.9531 12.1693 25.5716 11.717 26.1702C9.72309 25.5816 7.8422 24.6638 6.16687 23.4468C5.7763 19.4764 6.55744 15.4462 9.41475 11.3162C10.8537 10.6678 12.3748 10.2089 13.9474 9.94952C14.1632 10.3286 14.3688 10.7077 14.5435 11.1067C16.2086 10.8673 17.8942 10.8673 19.5592 11.1067C19.7134 10.7376 19.9395 10.2887 20.1348 9.94952C21.6971 10.2089 23.2183 10.6578 24.6572 11.3062C27.1342 14.8476 28.3676 18.8479 27.9154 23.4468L27.9256 23.4368Z",
    fill: "#6171B9"
  }));
};
export default DiscordRoundIcon;
