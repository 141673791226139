import { jsx as __cssprop } from "@emotion/react";
var CircleDeleteIcon = function CircleDeleteIcon2() {
  return __cssprop("svg", {
    width: "14",
    height: "14",
    viewBox: "0 0 14 14",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("circle", {
    cx: "7.35369",
    cy: "6.72594",
    r: "6.19255",
    fill: "white"
  }), __cssprop("path", {
    d: "M5.17188 4.54407L9.53564 8.90784",
    stroke: "#E16961",
    strokeWidth: "0.818207",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __cssprop("path", {
    d: "M9.53564 4.54407L5.17188 8.90784",
    stroke: "#E16961",
    strokeWidth: "0.818207",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
};
export default CircleDeleteIcon;
