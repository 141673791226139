import { jsx as __cssprop } from "@emotion/react";
var IdIcon = function IdIcon2() {
  return __cssprop("svg", {
    width: "58",
    height: "58",
    viewBox: "0 0 58 58",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_4947_22935)"
  }, __cssprop("path", {
    d: "M15.0625 7.21873L19.403 3H38.2859L42.2035 7.21873H15.0625Z",
    fill: "#10B981"
  }), __cssprop("path", {
    d: "M51.9224 8.35914H6.46238C4.11778 8.35914 2.21094 10.2673 2.21094 12.63V43.8531C2.21094 46.2085 4.11036 48.124 6.46238 48.124H51.9224C54.267 48.124 56.1739 46.2159 56.1739 43.8531V12.6226C56.1739 10.2673 54.2745 8.35168 51.9224 8.35168V8.35914ZM18.2077 18.9954C20.8342 18.9954 22.9636 21.1346 22.9636 23.7732C22.9636 26.4117 20.8342 28.5509 18.2077 28.5509C15.5811 28.5509 13.4517 26.4117 13.4517 23.7732C13.4517 21.1346 15.5811 18.9954 18.2077 18.9954ZM18.2373 38.5536C11.9306 38.5536 10.7287 36.5859 10.7287 36.5859C10.7287 30.4143 13.9414 28.5509 13.9414 28.5509C13.9414 28.5509 15.2992 30.1907 18.2077 30.1907C21.1161 30.1907 22.5852 28.5509 22.5852 28.5509C25.7386 30.7944 25.6866 36.5859 25.6866 36.5859C25.6866 36.5859 24.544 38.5536 18.2373 38.5536ZM48.3759 36.9585C48.3759 38.166 47.4039 39.1425 46.2019 39.1425H31.3626C30.1607 39.1425 29.1887 38.166 29.1887 36.9585C29.1887 35.7511 30.1607 34.7746 31.3626 34.7746H46.2019C47.4039 34.7746 48.3759 35.7511 48.3759 36.9585ZM46.2019 31.1149H31.3626C30.1607 31.1149 29.1887 30.1385 29.1887 28.931C29.1887 27.7236 30.1607 26.7471 31.3626 26.7471H46.2019C47.4039 26.7471 48.3759 27.7236 48.3759 28.931C48.3759 30.1385 47.4039 31.1149 46.2019 31.1149ZM48.3759 20.911C48.3759 22.1185 47.4039 23.0949 46.2019 23.0949H31.3626C30.1607 23.0949 29.1887 22.1185 29.1887 20.911C29.1887 19.7035 30.1607 18.7271 31.3626 18.7271H46.2019C47.4039 18.7271 48.3759 19.7035 48.3759 20.911Z",
    fill: "#64A6E7"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_4947_22935"
  }, __cssprop("rect", {
    width: "53.9629",
    height: "45.1166",
    fill: "white",
    transform: "translate(2.21094 3)"
  }))));
};
export default IdIcon;
