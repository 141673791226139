import { jsx as __cssprop } from "@emotion/react";
var VerifiedCheckIcon = function VerifiedCheckIcon2(_ref) {
  var _ref$color = _ref.color, color = _ref$color === void 0 ? "#10B981" : _ref$color, className = _ref.className;
  return __cssprop("svg", {
    width: "27",
    height: "25",
    viewBox: "0 0 27 25",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    className
  }, __cssprop("g", {
    clipPath: "url(#clip0_6674_3393)"
  }, __cssprop("path", {
    d: "M0.658447 11.8682C0.722443 11.4286 0.765106 10.9851 0.85341 10.5511C1.63772 6.68951 3.77834 3.8246 7.33877 2.15378C11.1209 0.379274 14.857 0.66502 18.4487 2.80217C18.7701 2.99366 18.9452 3.26055 18.8941 3.63956C18.8158 4.22396 18.1917 4.50325 17.6668 4.1803C16.9758 3.75565 16.2559 3.39698 15.4865 3.13852C9.78746 1.22163 3.69153 4.70814 2.45131 10.5937C1.33065 15.9133 4.72736 21.2135 10.0291 22.4175C15.5733 23.6771 21.0174 20.1409 22.1257 14.558C22.3117 13.6214 22.3479 12.6758 22.2675 11.7253C22.2259 11.2327 22.5201 10.8482 22.9839 10.7976C23.4214 10.75 23.7935 11.0824 23.8411 11.5626C24.3918 17.1133 20.7674 22.3669 15.3694 23.8432C9.10335 25.5572 2.66562 21.8366 1.03101 15.5412C0.868292 14.9142 0.809754 14.2603 0.702103 13.6189C0.690197 13.5464 0.67333 13.475 0.658447 13.4031C0.658447 12.8916 0.658447 12.3796 0.658447 11.8682Z",
    fill: color
  }), __cssprop("path", {
    d: "M13.5939 13.4269C13.7001 13.2974 13.7571 13.215 13.8271 13.1451C17.3959 9.57424 20.9668 6.00489 24.5341 2.43305C24.7608 2.20584 25.0014 2.04362 25.3403 2.09869C25.9341 2.19543 26.2094 2.87656 25.8448 3.35379C25.7853 3.43217 25.7118 3.50063 25.6419 3.57058C21.8622 7.35077 18.0825 11.131 14.3028 14.9107C13.7923 15.4211 13.4029 15.4191 12.8885 14.9052C11.3833 13.4001 9.87969 11.8939 8.37208 10.3918C8.17563 10.1958 8.03772 9.98054 8.05756 9.69628C8.07889 9.38275 8.23466 9.1476 8.52339 9.02011C8.82104 8.88865 9.10381 8.93329 9.35632 9.14016C9.42627 9.19771 9.48878 9.26418 9.55277 9.32818C10.8307 10.6056 12.1086 11.8825 13.3846 13.1615C13.4481 13.2254 13.4972 13.3038 13.5939 13.4274V13.4269Z",
    fill: color
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_6674_3393"
  }, __cssprop("rect", {
    width: "25.3501",
    height: "23.271",
    fill: "white",
    transform: "translate(0.658447 0.990234)"
  }))));
};
export default VerifiedCheckIcon;
