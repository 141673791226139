import { jsx as __cssprop } from "@emotion/react";
var LinkedInRoundIcon = function LinkedInRoundIcon2() {
  return __cssprop("svg", {
    width: "32",
    height: "33",
    viewBox: "0 0 32 33",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __cssprop("g", {
    clipPath: "url(#clip0_10198_13686)"
  }, __cssprop("path", {
    d: "M16 32.7051C24.8366 32.7051 32 25.5416 32 16.7051C32 7.86852 24.8366 0.705078 16 0.705078C7.16344 0.705078 0 7.86852 0 16.7051C0 25.5416 7.16344 32.7051 16 32.7051Z",
    fill: "#4467AD"
  }), __cssprop("path", {
    d: "M17.2373 14.5208C17.5004 14.2245 17.7161 13.9259 17.9863 13.6722C18.816 12.89 19.7973 12.4965 20.9446 12.506C21.5751 12.5108 22.1961 12.5558 22.8029 12.7312C24.192 13.1318 24.9979 14.0894 25.3843 15.4453C25.6734 16.4622 25.7256 17.5075 25.728 18.5552C25.7327 20.7644 25.7208 22.976 25.728 25.1851C25.728 25.3914 25.6711 25.4459 25.4672 25.4459C24.3294 25.4364 23.1893 25.4364 22.0515 25.4459C21.85 25.4459 21.8074 25.3866 21.8074 25.197C21.8145 23.0945 21.8145 20.992 21.8074 18.8871C21.8074 18.3608 21.7718 17.8322 21.6248 17.3202C21.3523 16.3768 20.6767 15.8957 19.6883 15.9478C18.3371 16.0189 17.6355 16.6874 17.4648 18.0598C17.4246 18.3869 17.4032 18.7164 17.4056 19.0459C17.4056 21.0915 17.4056 23.1371 17.4103 25.1828C17.4103 25.3866 17.3606 25.4459 17.152 25.4459C16.0047 25.4364 14.8574 25.4364 13.7102 25.4459C13.5253 25.4459 13.4731 25.3985 13.4731 25.2112C13.4779 21.1626 13.4779 17.1117 13.4731 13.0631C13.4731 12.8616 13.5395 12.8165 13.7291 12.8165C14.8171 12.8237 15.9075 12.826 16.9955 12.8165C17.197 12.8165 17.2468 12.8805 17.2444 13.0702C17.2326 13.5537 17.2397 14.0373 17.2397 14.5232L17.2373 14.5208Z",
    fill: "#FEFEFE"
  }), __cssprop("path", {
    d: "M10.9989 19.1481C10.9989 21.151 10.9965 23.154 11.0036 25.157C11.0036 25.3798 10.9491 25.4462 10.7191 25.4438C9.58137 25.4319 8.44359 25.4343 7.30344 25.4438C7.12092 25.4438 7.06641 25.3987 7.06641 25.2115C7.07115 21.1558 7.07115 17.1001 7.06641 13.042C7.06641 12.8737 7.1067 12.8145 7.28448 12.8145C8.43885 12.8216 9.59322 12.8239 10.7476 12.8145C10.9704 12.8145 10.9989 12.8974 10.9989 13.0894C10.9941 15.109 10.9965 17.1285 10.9965 19.1481H10.9989Z",
    fill: "#FEFEFE"
  }), __cssprop("path", {
    d: "M11.3139 8.79691C11.3139 10.0556 10.2946 11.0796 9.03834 11.0796C7.79863 11.0796 6.76989 10.0556 6.76515 8.81587C6.76041 7.56431 7.78915 6.5332 9.04308 6.5332C10.2875 6.5332 11.3115 7.55483 11.3139 8.79454V8.79691Z",
    fill: "#FEFEFE"
  })), __cssprop("defs", null, __cssprop("clipPath", {
    id: "clip0_10198_13686"
  }, __cssprop("rect", {
    width: "32",
    height: "32",
    fill: "white",
    transform: "translate(0 0.705078)"
  }))));
};
export default LinkedInRoundIcon;
