import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { colors } from "../../../theme/base/colors";
import { jsx as __cssprop } from "@emotion/react";
var NavbarDisplay = function NavbarDisplay2(_ref) {
  var onToggle = _ref.onToggle, isOpen = _ref.isOpen;
  return __cssprop(IconButton, {
    size: "small",
    edge: "start",
    "aria-label": "menu",
    onClick: function onClick() {
      return onToggle(!isOpen);
    }
  }, __cssprop(MenuIcon, {
    sx: {
      color: colors.white
    }
  }));
};
export default NavbarDisplay;
