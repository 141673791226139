function _typeof(obj) {
  "@babel/helpers - typeof";
  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(obj2) {
    return typeof obj2;
  } : function(obj2) {
    return obj2 && "function" == typeof Symbol && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
  }, _typeof(obj);
}
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function(sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), true).forEach(function(key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function(key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, { value, enumerable: true, configurable: true, writable: true });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return _typeof(key) === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== "object" || input === null)
    return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== void 0) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
      return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
import { driver } from "driver.js";
import { onButtonClick, tutorialsCommonProps } from "./tutorialsCommonProps";
var usePlaygroundTutorial = function usePlaygroundTutorial2() {
  var steps = [{
    element: "#playground_box_id",
    popover: {
      title: "Let's start!",
      description: "Get started with the playground tour!",
      side: "over",
      align: "center"
    }
  }, {
    element: "#access_control_id",
    popover: {
      title: "Access Control",
      description: "Here you can toggle on and off access controls from a list."
    }
  }, {
    element: "#geo_fencing_button",
    popover: {
      title: "Configure Access Control",
      description: "Configure/customize each access control.",
      onNextClick: function onNextClick() {
        onButtonClick("geo_fencing_button");
        playgroundTutorial.moveNext();
      }
    }
  }, {
    element: "#geo_fencing_dropdown",
    popover: {
      title: "Custom / Template",
      description: "Choose from a list of templated access controls or customize your own manually",
      onNextClick: function onNextClick() {
        onButtonClick("geo_fencing_button");
        playgroundTutorial.moveNext();
      },
      onPrevClick: function onPrevClick() {
        onButtonClick("geo_fencing_button");
        playgroundTutorial.movePrevious();
      }
    }
  }, {
    element: "#geo_fencing_checkbox_id",
    popover: {
      title: "Toggle On / Off",
      description: "Toggle On/Off each access control.",
      onPrevClick: function onPrevClick() {
        onButtonClick("geo_fencing_button");
        playgroundTutorial.movePrevious();
      }
    }
  }, {
    element: "#show_preview_button_id",
    popover: {
      title: "Show Preview",
      description: "Preview how this access control will work from a user perspective.",
      side: "top",
      align: "center"
    }
  }, {
    element: "#preview_box_id",
    popover: {
      title: "Preview View",
      description: "View the preview here as if you were a user trying to gain access to the gated content.",
      onNextClick: function onNextClick() {
        localStorage.setItem("hasSeenPlaygroundTutorial", "true");
        playgroundTutorial.destroy();
      }
    }
  }];
  var hasSeenPlaygroundTutorial = localStorage.getItem("hasSeenPlaygroundTutorial");
  var onCloseClick = function onCloseClick2() {
    localStorage.setItem("hasSeenPlaygroundTutorial", "true");
    playgroundTutorial.destroy();
  };
  var playgroundTutorial = driver(_objectSpread(_objectSpread({}, tutorialsCommonProps), {}, {
    steps,
    onCloseClick
  }));
  return {
    playgroundTutorial,
    hasSeenPlaygroundTutorial
  };
};
export default usePlaygroundTutorial;
