import { FormAnimLottie } from "assets/lotties";
import Lottie from "lottie-react";
import { jsx as __cssprop } from "@emotion/react";
var FormIcon = function FormIcon2() {
  return __cssprop(Lottie, {
    animationData: FormAnimLottie,
    loop: true
  });
};
export default FormIcon;
