var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import styled from "styled-components";
import { colors } from "theme/base/colors";
export var VerificationContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex",
  "height": "100%",
  "minHeight": "100%",
  "flex": "1 1 0%",
  "alignItems": "center",
  "justifyContent": "center"
});
export var CommonContainer = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n    ", ";\n"])), {
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "borderRadius": "0.5rem",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))",
  "--tw-shadow": "0px 1px 2px rgba(0, 0, 0, 0.05)",
  "--tw-shadow-colored": "0px 1px 2px var(--tw-shadow-color)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
}, function(_ref) {
  var testInstance = _ref.testInstance, isIframe = _ref.isIframe;
  if (isIframe)
    return {
      "height": "650px",
      "width": "480px",
      "padding": "20px"
    };
  return testInstance ? {
    "height": "500px",
    "width": "420px",
    "padding": "20px",
    "@media (min-width: 1536px)": {
      "height": "525px"
    }
  } : {
    "height": "560px",
    "width": "435px",
    "padding": "20px",
    "@media (min-width: 1536px)": {
      "width": "480px"
    }
  };
}, function(_ref2) {
  var bgColor = _ref2.bgColor;
  return "background-color: ".concat(bgColor ? bgColor : colors.white900);
});
