import { Stack, styled } from "@mui/material";
import { DiscordIcon, LinkedinIcon, TwitterIcon } from "assets/icons/socials";
import ConnectButtonModal from "components/ConnectModal";
import { APPLICANTS_MODES, LINK_DISCORD, LINK_LINKEDIN, LINK_TWITTER } from "utils/constants";
import { jsx as __cssprop } from "@emotion/react";
var StyledLink = styled("a")({
  "&:hover": {
    "@keyframes glow": {
      from: {
        transform: "scale(1)"
      },
      to: {
        transform: "scale(1.2)"
      }
    },
    animation: "glow 0.3s forwards"
  }
});
var SocialMedias = function SocialMedias2(_ref) {
  var isMobile = _ref.isMobile, isDrawer = _ref.isDrawer;
  return __cssprop(Stack, {
    direction: isMobile ? "column" : "row",
    justifyContent: "space-between",
    marginTop: "20px"
  }, isDrawer ? null : __cssprop(ConnectButtonModal, {
    label: "Try it free",
    fullWidth: true,
    typeSelected: APPLICANTS_MODES.BUSINESS,
    isDisabled: false,
    width: "100%"
  }), __cssprop(Stack, {
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: isMobile ? 0 : 2,
    marginTop: isMobile ? "20px" : "0"
  }, __cssprop(StyledLink, {
    href: LINK_DISCORD,
    rel: "noreferrer",
    target: "_blank"
  }, __cssprop(DiscordIcon, null)), __cssprop(StyledLink, {
    href: LINK_TWITTER,
    rel: "noreferrer",
    target: "_blank"
  }, __cssprop(TwitterIcon, null)), __cssprop(StyledLink, {
    href: LINK_LINKEDIN,
    rel: "noreferrer",
    target: "_blank"
  }, __cssprop(LinkedinIcon, null))));
};
export default SocialMedias;
