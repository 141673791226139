var _checksDescriptions;
function _typeof(obj) {
  "@babel/helpers - typeof";
  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(obj2) {
    return typeof obj2;
  } : function(obj2) {
    return obj2 && "function" == typeof Symbol && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
  }, _typeof(obj);
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, { value, enumerable: true, configurable: true, writable: true });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return _typeof(key) === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== "object" || input === null)
    return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== void 0) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
      return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
import { CopyCodeIcon, DoneIcon, SelectionIcon, SubmitIcon, WalletIcon } from "assets/svg/landingIntegrateSection";
import { CreditScoreAttributes, Platforms } from "components/Checks/types";
import { colors } from "theme/base/colors";
export var APP_VERSION = 201;
export var MAX_WIDTH = "1920px";
export var CLAIM_SCREENS;
(function(CLAIM_SCREENS2) {
  CLAIM_SCREENS2[CLAIM_SCREENS2["START_SCREEN"] = 0] = "START_SCREEN";
  CLAIM_SCREENS2[CLAIM_SCREENS2["SELECT_WALLET_SCREEN"] = 1] = "SELECT_WALLET_SCREEN";
  CLAIM_SCREENS2[CLAIM_SCREENS2["AUTH_CLAIM_SCREEN"] = 2] = "AUTH_CLAIM_SCREEN";
  CLAIM_SCREENS2[CLAIM_SCREENS2["CLAIM_SCREEN"] = 3] = "CLAIM_SCREEN";
  CLAIM_SCREENS2[CLAIM_SCREENS2["CLAIM_SUCCESS_SCREEN"] = 4] = "CLAIM_SUCCESS_SCREEN";
})(CLAIM_SCREENS || (CLAIM_SCREENS = {}));
export var VERIFICATION_SCREENS;
(function(VERIFICATION_SCREENS2) {
  VERIFICATION_SCREENS2[VERIFICATION_SCREENS2["START_SCREEN"] = 0] = "START_SCREEN";
  VERIFICATION_SCREENS2[VERIFICATION_SCREENS2["OPTIONS_SCREEN"] = 1] = "OPTIONS_SCREEN";
  VERIFICATION_SCREENS2[VERIFICATION_SCREENS2["PERSONAL_DATA_SCREEN"] = 2] = "PERSONAL_DATA_SCREEN";
  VERIFICATION_SCREENS2[VERIFICATION_SCREENS2["ADDRESS_SCREEN"] = 3] = "ADDRESS_SCREEN";
  VERIFICATION_SCREENS2[VERIFICATION_SCREENS2["ONFIDO_SCREEN"] = 4] = "ONFIDO_SCREEN";
  VERIFICATION_SCREENS2[VERIFICATION_SCREENS2["POLYGON"] = 5] = "POLYGON";
})(VERIFICATION_SCREENS || (VERIFICATION_SCREENS = {}));
export var CONTEXT_CODE_EXPLICATION = "\nThis context manages the state of verification in your application.\nInclude GatekeeperContextProvider at a higher level in your component hierarchy\n";
export var INTEGRATION_CODE_EXPLICATION = "\nThe Access Control Modal can be placed in any part of your application.\nIt handles the verification flow and updates the context accordingly. \n";
export var APPLICANTS_MODES = {
  INDIVIDUAL: "Individual",
  BUSINESS: "Business"
};
export var CREDIT_SCORE_PLATFORMS = [Platforms.SPECTRAL, Platforms.CRED_PROTOCOL];
export var CREDIT_SCORE_ATTRIBUTES = [CreditScoreAttributes.CREDIT_SCORE, CreditScoreAttributes.RISK_LEVEL];
export var SPECTRAL_RISK_LABELS = ["VERY_LOW_RISK", "LOW_RISK", "MEDIUM_RISK", "HIGH_RISK", "VERY_HIGH_RISK"];
export var CRED_PROTOCOL_RISK_LABELS = ["EXCELLENT", "VERY_GOOD", "FAIR", "GOOD", "LOW"];
export var PATH = {
  initial: "/",
  home: "/home",
  claims: "/claim",
  kyc: "/kyc",
  verify: "/verify",
  polygon: "/polygon",
  dashBoard: "/dashboard",
  integrate: "/integrate",
  roles: "/create-role",
  playground: "/playground",
  privacyPolicy: "/privacy-policy",
  demo: "/demo",
  termsOfService: "/terms-of-service",
  kycEcosystem: "/kyc-verification"
};
export var RESTORE_WALLET = {
  address: "",
  provider: void 0,
  signer: void 0
};
export var RESTORE_SESSION = {
  user: null,
  token: "",
  isExpired: false
};
export var RESTORE_STATE = {
  isVerified: false,
  isLoggedIn: false,
  isConnected: false,
  appIndex: -1,
  appVersion: APP_VERSION
};
export var SUCCESS_STATE = {
  isVerified: true,
  isLoggedIn: true,
  isConnected: true,
  appIndex: -1,
  appVersion: APP_VERSION
};
export var INITIAL_PERSONAL_DETAIL = {
  firstName: "",
  lastName: "",
  line1: "",
  line2: "",
  dob: "",
  phone_number: "",
  city: "",
  postcode: "",
  country: "",
  state: ""
};
export var INPUTS_PERSONAL_DETAIL = [{
  id: "firstName",
  label: "First name",
  size: 3
}, {
  id: "lastName",
  label: "Last name",
  size: 3
}, {
  id: "dob",
  label: "Date of birth",
  size: 3,
  type: "datePicker"
}, {
  id: "line1",
  label: "Address line",
  size: 3
}, {
  id: "line2",
  label: "Address line 2",
  size: 3
}, {
  id: "state",
  label: "State/Region",
  size: 3
}, {
  id: "country",
  label: "Country",
  size: 3,
  type: "country"
}, {
  id: "postcode",
  label: "Postal/Zip code",
  size: 3
}, {
  id: "phone_number",
  label: "Phone number",
  size: 3,
  type: "phone"
}];
export var FOOTER_LABELS = ["Integrate Now", "Supported Countries", "Privacy policy", "Terms of services"];
export var LANDING_USER = ["Connect your wallet", "Enter your information", "Scan a photo ID", "Take a selfie", "Access your favorite Dapps"];
export var LANDING_BUSSINES = ["Connect your wallet", "Submit app information", "Selects user checks", "Copy code snippet", "Done!"];
export var LANDING_INFO = {
  business: {
    title: "Integrate now",
    desc: "Register your app with us, copy a few lines of code, and voila, your app is protected"
  },
  individual: {
    title: "Individual",
    desc: "Quickly KYC with us and have seamless access to the Dapps you are craving to use."
  }
};
export var SPEED_ANIMATION = 30;
export var animateSlow = "duration-600 ease-in-out";
export var animateMed = "duration-400 ease-in-out";
export var animateFast = "duration-200 ease-in-out";
export var slideReset = "visible opacity-1 translate-x-0 translate-y0";
export var slideUp = "!invisible !h-0 opacity-0 overflow:hidden translate-y-5";
export var slideLeft = "!invisible !h-0 opacity-0 overflow:hidden translate-x-5";
export var LANDING_INTEGRATE_STEPS = [{
  index: 1,
  Icon: WalletIcon,
  label: "Connect your wallet"
}, {
  index: 2,
  Icon: SubmitIcon,
  label: "Submit app information"
}, {
  index: 3,
  Icon: SelectionIcon,
  label: "Select access controls"
}, {
  index: 4,
  Icon: CopyCodeIcon,
  label: "Copy code snippet"
}, {
  index: 5,
  Icon: DoneIcon,
  label: "Done!"
}];
export var LINK_LINKEDIN = "https://www.linkedin.com/company/layer-3";
export var LINK_DISCORD = "https://discord.com/invite/k4FUYGCGvW";
export var LINK_TWITTER = "https://twitter.com/GateKeeperId";
export var LINK_MEDIUM = "https://medium.com/@gatekeeper_42264";
export var LINK_SITE = "https://gatekeeper.software/";
export var GATEKEYS_INFO = "These Soulbound NFTs are issued to your on-chain address. By default, each NFT will be minted to the MATIC chain";
export var CUSTOMIZE_VC_DEFAULT = {
  title: "Credential title",
  subTitle: "This is where your credential description will go. Describe who can claim the credential, where can the users leverage the credential. Maybe a bit of info about your project?",
  backgroundColor: "#E2E8F0",
  textColor: "#000000",
  primaryColor: "#94A3B8",
  buttonTextColor: "#F1F5F9"
};
export var NETWORKS = [{
  chainName: "Ethereum",
  chainID: 1
}, {
  chainName: "BSC",
  chainID: 56
}, {
  chainName: "Avalanche",
  chainID: 43114
}, {
  chainName: "Arbitrum",
  chainID: 42161
}, {
  chainName: "Polygon",
  chainID: 137
}, {
  chainName: "Optimism",
  chainID: 10
}, {
  chainName: "Cronos",
  chainID: 25
}, {
  chainName: "Fantom",
  chainID: 250
}, {
  chainName: "Metis",
  chainID: 1088
}];
export var HEX_VALUES_REGEX = "([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";
export var DEFAULT_PANEL_COLORS = {
  primaryColor: colors.success600,
  textColor: colors.white,
  buttonTextColor: colors.white,
  backgroundColor: colors.dashboardBg
};
export var HttpMethods;
(function(HttpMethods2) {
  HttpMethods2["GET"] = "get";
  HttpMethods2["POST"] = "post";
})(HttpMethods || (HttpMethods = {}));
export var CHECK_TYPES;
(function(CHECK_TYPES2) {
  CHECK_TYPES2["KYC"] = "KYC";
  CHECK_TYPES2["OFAC"] = "OFAC";
  CHECK_TYPES2["geoId"] = "geoId";
  CHECK_TYPES2["NFT"] = "NFT";
  CHECK_TYPES2["Polygon"] = "Polygon";
  CHECK_TYPES2["creditScore"] = "creditScore";
  CHECK_TYPES2["API"] = "API";
})(CHECK_TYPES || (CHECK_TYPES = {}));
export var CHECK_TYPES_DISPLAY_NAME;
(function(CHECK_TYPES_DISPLAY_NAME2) {
  CHECK_TYPES_DISPLAY_NAME2["KYC"] = "\u{1F4CB}  KYC";
  CHECK_TYPES_DISPLAY_NAME2["OFAC"] = "\u{1F9D1}\u200D\u2696\uFE0F  OFAC";
  CHECK_TYPES_DISPLAY_NAME2["geoId"] = "\u{1F30E}  Geofencing";
  CHECK_TYPES_DISPLAY_NAME2["NFT"] = "\u{1F5BC}\uFE0F  NFT";
  CHECK_TYPES_DISPLAY_NAME2["Polygon"] = "\u{1F194}  Polygon";
  CHECK_TYPES_DISPLAY_NAME2["creditScore"] = "\u{1F4AF}  Credit Score";
  CHECK_TYPES_DISPLAY_NAME2["API"] = "\u{1F468}\u200D\u{1F4BB}  Custom API";
})(CHECK_TYPES_DISPLAY_NAME || (CHECK_TYPES_DISPLAY_NAME = {}));
export var CHECK_TYPES_ARRAY = [CHECK_TYPES.KYC, CHECK_TYPES.NFT, CHECK_TYPES.OFAC, CHECK_TYPES.geoId, CHECK_TYPES.Polygon, CHECK_TYPES.creditScore, CHECK_TYPES.API];
export var FRONTEND_URL = "https://black-smoke-0ba344710.2.azurestaticapps.net";
export var SOCIAL;
(function(SOCIAL2) {
  SOCIAL2["Linkedin"] = "Linkedin";
  SOCIAL2["Facebook"] = "Facebook";
  SOCIAL2["Twitter"] = "Twitter";
  SOCIAL2["Discord"] = "Discord";
})(SOCIAL || (SOCIAL = {}));
export var WALLET_ADDRESS_PARAM = "UserWalletAddress";
export var SOCIAL_MEDIAS = [SOCIAL.Linkedin, SOCIAL.Facebook, SOCIAL.Twitter, SOCIAL.Discord];
export var SUGGESTIONS_TYPE;
(function(SUGGESTIONS_TYPE2) {
  SUGGESTIONS_TYPE2[SUGGESTIONS_TYPE2["ORBIS"] = 0] = "ORBIS";
  SUGGESTIONS_TYPE2[SUGGESTIONS_TYPE2["LENS"] = 1] = "LENS";
})(SUGGESTIONS_TYPE || (SUGGESTIONS_TYPE = {}));
export var checksDescriptions = (_checksDescriptions = {}, _defineProperty(_checksDescriptions, CHECK_TYPES.KYC, "Require users to complete and pass a KYC (Know Your Customer) application. This will allow for enhanced regulatory compliance of your protocol."), _defineProperty(_checksDescriptions, CHECK_TYPES.NFT, "Restrict access based on ownership of a specified NFT. Users are required to be active holders to pass this check."), _defineProperty(_checksDescriptions, CHECK_TYPES.geoId, "Geo Location checks allow or block access to users based on the country their IP address is from."), _defineProperty(_checksDescriptions, CHECK_TYPES.OFAC, "Protect your protocol and community against OFAC/US Treasury Department sanctioned wallets. If a wallet is on their list, it will be denied access."), _defineProperty(_checksDescriptions, CHECK_TYPES.Polygon, "Setup verifiable credential verification via the Polygon ID system."), _defineProperty(_checksDescriptions, CHECK_TYPES.creditScore, "Use on-chain credit score based on user's transaction history. Multiple platforms to choose from."), _defineProperty(_checksDescriptions, CHECK_TYPES.API, "Use any on-chain data for verification and gating purpose."), _checksDescriptions);
export var KYC_AGGREGATORS;
(function(KYC_AGGREGATORS2) {
  KYC_AGGREGATORS2["GateKeeper"] = "GateKeeper";
  KYC_AGGREGATORS2["Fractal"] = "Fractal";
  KYC_AGGREGATORS2["PureFi"] = "PureFi";
})(KYC_AGGREGATORS || (KYC_AGGREGATORS = {}));
export var DESCRIPTION_ONE = {
  title: "Access Control List (ACL)",
  descriptions: ["Gatekeep your content with a series of user verifications known as\n  \u201Cchecks\u201D", "Available checks: KYC/AML, NFTs, Tokens, Geo Fencing, custom Verifiable Credentials, ZK Proofs, user engagement data points, and more."],
  index: 0
};
export var DESCRIPTION_TWO = {
  title: "Verifiable Credential Issuance",
  descriptions: ["No code VC issuance as a servicer", "Slick VC schema builder", "Off and on chain VC verification"],
  index: 1
};
export var DESCRIPTION_THREE = {
  title: "Roles Wizard",
  descriptions: ["Use AND/OR statements to simplify complex roles", "Create & manage an infinite number of roles", "Combine multiple checks to create a role"],
  index: 2
};
export var DESCRIPTION_FOUR = {
  title: "DID Aggregator (Coming soon)",
  descriptions: ["Multiple DID methods: Ceramic, PolygonID, ION, and more.", "Interoperable VCs across DID methods"],
  index: 3
};
export var RESOLUTIONS;
(function(RESOLUTIONS2) {
  RESOLUTIONS2["full"] = "1920px";
  RESOLUTIONS2["notebook"] = "1280px";
  RESOLUTIONS2["mobile"] = "600px";
})(RESOLUTIONS || (RESOLUTIONS = {}));
export var TAILWIND_RESOLUTIONS;
(function(TAILWIND_RESOLUTIONS2) {
  TAILWIND_RESOLUTIONS2["mx"] = "3500px";
  TAILWIND_RESOLUTIONS2["_2xl"] = "1919px";
  TAILWIND_RESOLUTIONS2["xl"] = "1535px";
  TAILWIND_RESOLUTIONS2["lg"] = "1279px";
  TAILWIND_RESOLUTIONS2["md"] = "1023px";
  TAILWIND_RESOLUTIONS2["sm"] = "767px";
  TAILWIND_RESOLUTIONS2["xs"] = "639px";
})(TAILWIND_RESOLUTIONS || (TAILWIND_RESOLUTIONS = {}));
export var DEFAULT_API_EXPRESSION = '// "response" represents the data returned from the API.\n// Complete the expression below to return a boolean value.\n// Example: Object.keys(response).length >=1 && response[0].name === "John";\n  Object.keys(response).length >=1 && \n';
export var POLYGON_AUTH_ERRORS = {
  ROLE_REQUIERE_POLYGON_DID: "This role requires a Polygon DID",
  NOT_ELEGIBLE_FOR_ROLE: "You are not eligible for this role",
  ROLE_HAS_NO_VC_ASSOCIATED: "This role has no VC associated, or you have already claimed it",
  FAKE_CLAIM: "FakeClaimId"
};
export var integrationCodeExample = function integrationCodeExample2(roles, customization) {
  return '\nimport AccessControlModal from "@layer3/gatekeeper-sdk";\n\nconst GateKeeperModal = () => {\n  const address = "";\n  const polygonID = ""; // Fill in if only VC verification needed\n  const checkCallback = () => {}; // Customize result of verification\n\n  return (\n    <AccessControlModal\n      account={address}\n      roles={['.concat(roles, ']}\n      polygonId={polygonID}\n      checkCallback={checkCallback}\n      customization={{\n        primaryColor: "').concat(customization.primaryColor, '",\n        backgroundColor: "').concat(customization.bgColor, '",\n        buttonTextColor: "').concat(customization.buttonTextColor, '",\n        textColor: "').concat(customization.textColor, '",\n      }}\n    />\n  );\n};\n\nexport default GateKeeperModal;\n');
};
export var contextCodeExample = function contextCodeExample2() {
  return '\nimport { GatekeeperContextProvider } from "@layer3/gatekeeper-sdk";\n\nroot.render(\n  <GatekeeperContextProvider>\n    <AppContextProvider>\n      <Router>\n        <App />\n      </Router>\n    </AppContextProvider>\n  </GatekeeperContextProvider>\n);\n';
};
