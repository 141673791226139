import { jsx as __cssprop } from "@emotion/react";
var CheckboxIcon = function CheckboxIcon2(_ref) {
  var _ref$isActive = _ref.isActive, isActive = _ref$isActive === void 0 ? true : _ref$isActive;
  if (isActive)
    return __cssprop("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: "16",
      height: "16",
      viewBox: "0 0 16 16",
      fill: "none"
    }, __cssprop("rect", {
      width: "16",
      height: "16",
      rx: "2",
      fill: "#10B981"
    }), __cssprop("path", {
      d: "M6.99987 9.586L11.5959 4.9895L12.3034 5.6965L6.99987 11L3.81787 7.818L4.52487 7.111L6.99987 9.586Z",
      fill: "white"
    }));
  return __cssprop("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "none"
  }, __cssprop("rect", {
    x: "0.75",
    y: "0.75",
    width: "14.5",
    height: "14.5",
    rx: "1.25",
    stroke: "#AEB0B1",
    strokeWidth: "1.5"
  }));
};
export default CheckboxIcon;
