var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}
import styled from "styled-components";
export var IconBox = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), {
  "display": "flex",
  "cursor": "pointer",
  "alignItems": "center"
});
export var Message = styled.p(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  background-color: ", ";\n"])), {
  "display": "block",
  "padding": "0.5rem",
  "fontSize": "16px",
  "lineHeight": "24px",
  "fontWeight": "400",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
}, function(_ref) {
  var bgColor = _ref.bgColor;
  return bgColor;
});
